<template>
<div>
	<v-chip v-if="show_account==true"
					class="mb-3"
					color="teal darken-2"
					label
					text-color="white"> {{select_key }} </v-chip>
	<v-chip v-if="show_account==true"
					class="mb-3 ml-3"
					color="teal darken-2"
					label
					text-color="white"> {{ account_name(select_key,item.datefalse) }} </v-chip>

	<v-card-title class="body-1">Valeurs des contrats <v-spacer></v-spacer>
		<span class="justify-end success--text"
					v-html="' <b>'+monetary_aspect(total_claimable_balances_in_euro)+'</b>  €' ">
		</span>
	</v-card-title>
	<v-divider class="mb-3 mt-n3 success"></v-divider>
	<v-row v-if='claimable_balances !=undefined'>
		<template v-for="(item, index) in claimable_balances">
			<v-col cols=12
						 xl=4
						 lg=4
						 md=4
						 sm=6
						 :key="'c'+index"
						 v-if="test_affiche(item)">
				<v-card :color="afternow(item.date) ? 'green darken-1' : 'teal darken-4'"
								dark
								@click="click_claim( item)">
					<v-card-title class="pb-0">
						<span class="primary px-2 mr-2"
									v-html="monetary_aspect( item.amount )" /> {{item.asset_code}}
						<v-spacer></v-spacer>
						<span v-html=" monetary_aspect(item.fceuro )"> </span>
					</v-card-title>
					<v-card-title class="pt-2 body-1"
												v-html="'Terme: '+affiche_date(item.date)" />

					<div v-if="afternow(item.date)"
							 class="mt-n8"
							 style="float:right">


						<stellarclaimbalanceclaim @close="close"
																			:asset_code='item.asset_code'
																			:asset_issuer='item.asset_issuer'
																			:account_sign="select_key"
																			label="+"
																			:id="item.id"
																			:amount="item.amount"
																			:last_modified_time="item.date.substr(0,10)"
																			:claimants="item.date.substr(0,10)" />



					</div>
				</v-card>
			</v-col>
		</template>
	</v-row>
</div>
</template>


<script>
import stellarclaimbalanceclaim from '../components/stellar_claim_balance_claim.vue'

export default {
	name: 'stellar_list_claim_balance',
	components: {
		stellarclaimbalanceclaim
	},
	props: {
		select_key: {
			type: String,
			default: "00000"
		},
		filter: {
			type: String,
			default: ""
		},
		show_account: {
			type: Boolean,
			default: false
		},
	},
	data: () => ({
		account_response: [],
		pool_response: [],
		balance: [],

	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {

	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {

		claimable_balances() {
			return this.$store.state.claimable_balances
		},
		total_claimable_balances_in_euro() {
			return this.$store.state.total_claimable_balances_in_euro
		},


	},
	methods: {
		afternow(itemdate) {
			if (Date.parse(itemdate) < new Date()) return true;
			else return false;
		},
		test_affiche(item) {
			let retour = true;
			let filter = " " + this.filter + " ";
			if (item.asset_type == 'native') item.asset_code = "XML";
			filter.includes(" " + item.asset_code + " ") ? retour = false : retour = true;
			return retour;
		},
		click_claim(item) {
			this.$emit("click_claim", item)
		},
		close(etat) {
			this.$emit("close_claim", etat)
		}

	},
	watch: {

	}
}
</script>

<style scoped>
</style>

<template>
<v-dialog v-model="dialog"
					fullscreen
					hide-overlay
					transition="dialog-bottom-transition">
	<template v-slot:activator="{ on, attrs }">
		<v-btn class="px-5"
					 color="primary"
					 :block="block"
					 dark
					 v-bind="attrs"
					 v-on="on"
					 :class="css"
					 :small="small">
			{{label}}
		</v-btn>
	</template>
	<v-card>
		<v-toolbar dark
							 color="primary">
			<v-btn icon
						 dark
						 @click="dialog = false">
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<b class="text-h6"> {{label}} </b>

		</v-toolbar>

		<v-card-title class="teal lighten-5">Vos liquidités &nbsp; <v-spacer></v-spacer>
			<b class="justify-end success--text"
				 v-html="monetary_aspect($store.state.solde_fceuro)+' €'" />
		</v-card-title>

		<v-row class="mx-1 mt-2">
			<v-col cols=12>
				<v-card elevation=0>
					<div class="ma-3 pt-6">
						<v-text-field class="my-5"
													v-model="send_amount"
													:label="'Envoi de '+asset_code_fceuro"></v-text-field>
						<v-text-field class="my-5"
													v-model="destination_minimum"
													:label="'Optionnel: Minimum de réception de '+ asset_code"></v-text-field>
					</div>
				</v-card>

				<v-card elevation="0"
								v-if='asset_code !=undefined'>
					<v-card-text>
						<b>Achat via Automated Market Maker (AMM))</b>
						<br />
						Envoyer <b class="red--text"
							 v-html="monetary_aspect(send_amount)"> </b> <code>{{asset_code_fceuro}}</code>
						<br />
						Reception de <b class="red--text"
							 v-html="monetary_aspect(destination_minimum)"> </b> <code>{{asset_code}}</code> minimum
						<br /><br />
						Commission de transaction de 0.03%
					</v-card-text>


				</v-card>

				<validbutton :etat_transaction="etat_transaction"
										 :error_why="error_why"
										 :progress="progress"
										 @valid="send_transaction" />
			</v-col>
		</v-row>
	</v-card>
</v-dialog>
</template>


<script>
var StellarSdk = require('stellar-sdk');
import validbutton from '@/components/valid_button.vue'

export default {
	name: 'stellar_buy_amm',
	components: {
		validbutton

	},
	props: {

		css: String,
		small: String,

		label: {
			type: String,
			default: ""
		},

		asset_code: String,
		asset_issuer: String,

		block: {
			type: Boolean,
			default: false
		},

	},
	data: () => ({
		label_secret: "Saisir votre clé secréte",
		secret_key: "",
		etat_transaction: undefined,
		error_why: undefined,
		transaction: undefined,
		progress: false,
		send_amount: '1',
		destination_minimum: '0.01',
		dialog: false,
		otp: "",
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		blockchain() {
			return this.$store.state.blockchain
		},
		asset_issuer_fceuro() {
			return this.$store.getters.master_account;
		},

		asset_code_fceuro() {
			return this.$store.state.asset_code_fceuro
		},
		pin() {
			return this.$store.state.pin
		},
		privatekey() {
			return this.$store.state.privatekey
		},
		balances() {
			return this.$store.state.balances
		},

	},
	methods: {
		onFinish() {
			if (this.pin == this.hash_key(this.otp)) this.secret_key = this.decrypt_key(this.otp);
		},



		send_transaction: function (key) {
			this.secret_key = key;
			this.progress = true;
			this.etat_transaction = "";

			try {

				// ----------------------------------------------------------------------------------------- PARAMETRAGE

				let origine_keys = StellarSdk.Keypair.fromSecret(this.secret_key);
				let destination_keys = StellarSdk.Keypair.fromSecret(this.secret_key);

				const sendAsset = new StellarSdk.Asset(this.asset_code_fceuro, this.asset_issuer_fceuro)
				const destAsset = new StellarSdk.Asset(this.asset_code, this.asset_issuer)

				const sendAmount = this.send_amount.toString();
				const destMin = this.destination_minimum.toString();

				// ----------------------------------------------------------------------------------------- OPERATION

				let operations = [];

				// ----------------------------------------------------------------------------------------- VERIFICATION DU TRUST

				let trust_exist = false;

				this.balances.forEach((item, i) => {
					if (item.asset_code == this.asset_code) trust_exist = true;
				});

				if (!trust_exist)
					operations.push(
						StellarSdk.Operation.changeTrust({
							asset: asset
						})
					)

				// ----------------------------------------------------------------------------------------- OPERATION PRINCIPALE

				operations.push(
					StellarSdk.Operation.pathPaymentStrictSend({
						sendAsset: sendAsset,
						sendAmount: sendAmount,
						destination: destination_keys.publicKey(),
						destAsset: destAsset,
						destMin: destMin
					})
				)

				// ----------------------------------------------------------------------------------------- SECURITE

				this.secret_key = "";

				// ----------------------------------------------------------------------------------------- PREPARATION DE LA TRANSACTION

				let params = {
					"source": origine_keys,
					"operations": operations,
					"memo": "Fraction trust token"
				}

				// ----------------------------------------------------------------------------------------- EXECUTION DE LA TRANSACTION

				this.$store.dispatch('stellar_transaction_sign_source', params).then(response => {
					if (response != undefined) this.transaction = response;
					this.progress = false;
					if (response.toString().substr(0, 5) == "Error") {
						this.progress = false;
						this.etat_transaction = "Echec de la transaction";
						if (response != undefined) this.error_why = response.toString().substr(6)
					} else {
						this.etat_transaction = "success";
						this.dialog = false;
					}
				})

			} catch (error) {
				this.etat_transaction = "Rejet de la transaction par le Wallet: " + error.toString().replace("TypeError: ", '');
				if (error.toString().replace("TypeError: ", '') == "Error: invalid version byte. expected 144, got undefined")
					this.etat_transaction = "Rejet par le Wallet: code secret vide ou code pin faux ou code de double vérification faux";
				this.error_data = error;
				//console.log(error)
				this.progress = false;
			}
		},

	},
	watch: {

		dialog: function () {
			if (!this.dialog) {
				this.$emit('close', this.etat_transaction)
				this.etat_transaction = undefined

				this.error_why = ""
				this.transaction = undefined
			}
		},
	}
}
</script>

<style scoped>
</style>

<template>
<div>
	<v-row no-gutters>
		<v-col cols=12>
			<v-alert class="pa-1"
							 icon="mdi-shield-check"
							 color="teal lighten-4"
							 @click="close_claimbalance('success')">

				<span classe="text--blue">Tiers de confiance</span>
			</v-alert>
			<listbalance :show_total="false"
									 ref="balance"
									 :label="label_token"
									 color="green darken-4"
									 filter="XLM"
									 :select_key="publickey"
									 @click_asset="click_asset" />

		</v-col>
	</v-row>

	<v-row no-gutters>
		<v-col cols=12>
			<listclaimbalancetrust ref="pool"
														 :select_key="publickey" />
		</v-col>
	</v-row>



	<v-row no-gutters>
		<v-col cols=12>
			<listbalance ref="balance"
									 label="Tokens de séquestre"
									 :select_key="sequestrekey"
									 @click_asset="click_asset_sequestre" />
		</v-col>
	</v-row>


	<v-bottom-sheet v-model="sheet">
		<v-container style="max-width:1280px"
								 class="mx-auto  white">
			<v-row no-gutters>
				<v-col cols=12
							 class="pa-3">
					Transfert : &nbsp; <b> {{asset_code}}</b>
				</v-col>
				<v-col cols=12
							 xl=6
							 lg=6
							 md=6
							 sm=6
							 class="pa-3">

					<stellartrust css=""
												:block="true"
												@close="close_transfert"
												:asset_balance="balance_asset"
												:asset_limit="limit_asset"
												label="Paiement avec tiers de confiance"
												:asset_code="asset_code"
												:asset_issuer="asset_issuer" />
				</v-col>
			</v-row>
		</v-container>
	</v-bottom-sheet>




	<v-bottom-sheet v-model="sheetsequestre">
		<v-container style="max-width:1280px"
								 class="mx-auto  white">
			<v-row no-gutters>
				<v-col cols=12
							 class="pa-3">
					Transfert de : &nbsp; <b> {{asset_code}}</b>
				</v-col>
				<v-col cols=12
							 xl=6
							 lg=6
							 md=6
							 sm=6
							 class="pa-3">

					<stellarpayment v-if="selected_item.way== 'other_for_me'  && selected_item.balance==1  "
													css=""
													:block="true"
													@close="close_transfert"
													label="Retour à l'expéditeur"
													:asset="selected_item" />


					<stellarpayment v-if="selected_item.way== 'me_for_other'  && selected_item.balance==1  "
													css=""
													:block="true"
													@close="close_transfert"
													:asset="selected_item"
													label="Valider la transaction" />


					<stellarrecuptsequestre v-if="selected_item.balance==2  "
																	css=""
																	:block="true"
																	@close="close_transfert"
																	:asset="selected_item"
																	label="Récupérer le séquestre" />

				</v-col>
			</v-row>
		</v-container>
	</v-bottom-sheet>




</div>
</template>



<script>
import listbalance from '../components/stellar_list_balance.vue'
import stellartrust from "@/components/stellar_trust.vue";
import listclaimbalancetrust from '../components/stellar_list_claim_balance_trust.vue'
import stellarpayment from "@/components/stellar_payment2.vue";
import stellarrecuptsequestre from "@/components/stellar_recup_sequestre.vue";

export default {
	name: 'trust',
	components: {
		listbalance,
		listclaimbalancetrust,
		stellartrust,
		stellarpayment,
		stellarrecuptsequestre
	},
	props: {},
	data: () => ({

		sheet: false,
		sheetsequestre: false,
		selected_item: [],

		unit_price: 0,
		amount: 1,


		label1: "Paiement direct, immédiat ou à terme",
		label2: "Paiement avec conversion de token",
		label_token: 'Tokens disponibles pour le transfert',
		asset_code: undefined,
		asset_issuer: undefined,
		limit_asset: undefined,
		balance_asset: undefined,
		asset_limit: undefined,
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		publickey() {
			return this.$store.state.publickey
		},
		sequestrekey() {
			return this.$store.getters.sequestre_account
		},

	},
	methods: {
		close_transfert(etat) {
			if (etat == "success") this.$emit('changepage', "dashboard")
		},
		close_claimbalance(etat) {
			if (etat == "success") this.$emit('changepage', "dashboard")

		},
		click_asset_sequestre(item) {
			this.asset_code = item.asset_code;
			this.asset_issuer = item.asset_issuer;
			this.limit_asset = item.limit;
			this.unit_price = Math.ceil(parseFloat(item.unitfceuro) * 100) / 100;
			this.sheetsequestre = true;
			this.balance_asset = item.balance;
			this.selected_item = item;
			//console.log(item)

		},

		click_asset(item) {
			this.asset_code = item.asset_code;
			this.asset_issuer = item.asset_issuer;
			this.limit_asset = item.limit;
			this.unit_price = Math.ceil(parseFloat(item.unitfceuro) * 100) / 100;
			this.sheet = true;
			this.balance_asset = item.balance;
		},


	},
	watch: {}
}
</script>

<style scoped>
</style>

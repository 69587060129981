<template>
<div>
	<v-snackbar v-model="snackbar"
							:timeout="timeout">
		{{ text }}

		<template v-slot:action="{ attrs }">
			<v-btn color="blue"
						 text
						 v-bind="attrs"
						 @click="snackbar = false">
				Close
			</v-btn>
		</template>
	</v-snackbar>


	<v-stepper v-model="e1"
						 class="ma-0">
		<v-stepper-header>
			<v-stepper-step :complete="e1 > 1"
											step="1">

			</v-stepper-step>

			<v-divider></v-divider>

			<v-stepper-step :complete="e1 > 2"
											step="2">

			</v-stepper-step>

			<v-divider></v-divider>

			<v-stepper-step :complete="e1 > 3"
											step="3">

			</v-stepper-step>

			<v-divider></v-divider>

			<v-stepper-step :complete="e1 > 4"
											step="4">

			</v-stepper-step>

			<v-divider></v-divider>

			<v-stepper-step :complete="e1>5"
											step="5">

			</v-stepper-step>

			<v-divider></v-divider>
			<v-stepper-step :complete="e1>6"
											v-if="publickey==undefined"
											step="6">

			</v-stepper-step>

			<v-divider v-if="publickey==undefined"></v-divider>

			<v-stepper-step :step="publickey==undefined ? 7 : 6">

			</v-stepper-step>

		</v-stepper-header>

		<v-stepper-items>
			<v-stepper-content step="1">

				<v-row>
					<v-col cols="12">

						<b class="primary--text">Contact</b>
					</v-col>
					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="emailcontact"
													label="Email"
													:error="emailcontact==''"
													counter
													maxlength="199"></v-text-field>
					</v-col>
					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="telephone"
													label="Téléphone"
													:error="telephone==''"
													counter
													maxlength="199"></v-text-field>
					</v-col>


				</v-row>



				<v-btn small
							 color="success"
							 @click="e1 =e1+1">
					Continue
				</v-btn>

			</v-stepper-content>

			<v-stepper-content step="2">
				<v-row>
					<v-col cols="12">
						<b class="primary--text">Personne physique</b>
						<br /> Particulier ou représentant si société (doit être dans la liste des bénéficiaires effectifs)
					</v-col>


					<v-col cols="12"
								 sm="6">
						<v-select v-model="civilite"
											:error="civilite==''"
											:items="civilite_items"
											label="Civilité"> </v-select>
					</v-col>
					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="name"
													label="Nom"
													:error="name==''"
													counter
													maxlength="199"></v-text-field>
					</v-col>
					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="firstname"
													label="Prénoms ( séparé par une virgule)"
													counter
													:error="firstname==''"
													maxlength="199"></v-text-field>
					</v-col>



					<v-col cols="12"
								 sm="6">

						<template>
							<div>
								<v-menu ref="menudatenaissance"
												v-model="menudatenaissance"
												:close-on-content-click="false"
												transition="scale-transition"
												offset-y
												min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="datenaissance"
																	label="Date de naissance"
																	:error="datenaissance==''"
																	readonly
																	v-bind="attrs"
																	v-on="on"></v-text-field>
									</template>
									<v-date-picker v-model="datenaissance"
																 :active-picker.sync="activePickerdatenaissance"
																 :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
																 min="1950-01-01"
																 @change="savedatenaissance"></v-date-picker>
								</v-menu>
							</div>
						</template>

					</v-col>


					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="departementnaissance"
													label="Département de naissance"
													counter
													:error="departementnaissance==''"
													maxlength="9"></v-text-field>
					</v-col>
					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="villenaissance"
													label="Ville de naissance"
													:error="villenaissance==''"
													counter
													maxlength="99"></v-text-field>
					</v-col>
					<v-col cols="12"
								 sm="6">
						<v-autocomplete v-model="paysnaissance"
														:items="id_pays"
														:error="paysnaissance==''"
														label="Pays de naissance"> </v-autocomplete>
					</v-col>

					<v-col cols="12"
								 sm="6">
						<v-autocomplete v-model="nationalite"
														:items="id_pays"
														:error="nationalite==''"
														label="Pays de nationalité"> </v-autocomplete>
					</v-col>
					<v-col cols="12"
								 sm="6">

						<v-autocomplete v-model="profession"
														:items="id_metier"
														label="Profession"> </v-autocomplete>
					</v-col>
					<v-col cols="12"
								 sm="6">
						<v-select v-model="situationfamille"
											:items="situationfamille_items"
											:error="situationfamille==''"
											label="Situation de famille"> </v-select>
					</v-col>
				</v-row>

				<v-btn small
							 color="success"
							 @click="e1 =e1+1">
					Continue
				</v-btn>
				<v-btn small
							 text
							 @click="e1 =e1-1">
					Retour
				</v-btn>
			</v-stepper-content>

			<v-stepper-content step="3">
				<v-row>
					<v-col cols="12">

						<b class="primary--text">Résidence Fiscale de la personne ou du représentant si société</b>
					</v-col>

					<v-col cols="12"
								 sm="6">
						<v-autocomplete v-model="residencefiscale"
														:items="id_pays"
														:error="residencefiscale==''"
														label="Résidence Fiscale"> </v-autocomplete>
					</v-col>


					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="adresse"
													label="Adresse"
													counter
													:error="adresse==''"
													maxlength="199"></v-text-field>
					</v-col>

					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="codepostal"
													label="Code postal"
													counter
													:error="codepostal==''"
													maxlength="199"></v-text-field>
					</v-col>

					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="ville"
													label="Ville, et pays si hors france"
													counter
													:error="ville==''"
													maxlength="199"></v-text-field>
					</v-col>



					<v-col cols="12">

						<b class="primary--text">Si Société</b>
					</v-col>


					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="siren"
													label="Siren"
													counter
													maxlength="199"></v-text-field>
					</v-col>

					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="raison_sociale"
													label="Raison sociale"
													counter
													maxlength="199"></v-text-field>
					</v-col>

				</v-row>

				<v-btn small
							 color="success"
							 @click="e1 =e1+1">
					Continue
				</v-btn>
				<v-btn small
							 text
							 @click="e1 =e1-1">
					Retour
				</v-btn>
			</v-stepper-content>


			<v-stepper-content step="4">
				<v-row>
					<v-col cols="12">

						<b class="primary--text">Banque</b>
					</v-col>
					<span class="ml-3">
						Le compte bancaire doit IMPERATIVEMENT être au nom de la personne physique qui ouvre le compte ou au nom de la société inscrit sur le KBIS.
					</span>

					<v-col cols="12"
								 sm="6">
						<v-text-field v-on:blur="verif_iban()"
													v-model="iban"
													label="Iban"
													:error="iban==''"
													counter
													maxlength="199"></v-text-field>
					</v-col>

					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="bic"
													label="Bic"
													counter
													:error="bic==''"
													maxlength="199"></v-text-field>
					</v-col>

					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="nomiban"
													label="Nom du titulaire du compte"
													counter
													:error="nomiban==''"
													maxlength="199"></v-text-field>
					</v-col>

				</v-row>

				<v-btn small
							 color="success"
							 @click="e1 =e1+1">
					Continue
				</v-btn>
				<v-btn small
							 text
							 @click="e1 =e1-1">
					Retour
				</v-btn>
			</v-stepper-content>
			<v-stepper-content step="5">
				<v-row>
					<v-col cols="12">

						<b class="primary--text">Justificatif d'identité 1</b>
					</v-col>
					<v-col cols="12"
								 sm="6">
						<v-select v-model="typeid1"
											:items="id_items"
											label="Justificatif identité 1"> </v-select>
					</v-col>
					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="numeroid1"
													label="Numéro justificatif identité 1"
													counter
													maxlength="199"></v-text-field>
					</v-col>




					<v-col cols="12"
								 sm="6">

						<template>

							<v-menu ref="menudatevalide1"
											v-model="menudatevalide1"
											:close-on-content-click="false"
											transition="scale-transition"
											offset-y
											min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field v-model="datevalide1"
																label="Date de fin de validité"
																readonly
																v-bind="attrs"
																v-on="on"></v-text-field>
								</template>
								<v-date-picker v-model="datevalide1"
															 :active-picker.sync="activePickerdatevalide1"
															 :min="datemindatevalide1"
															 @change="savedatevalide1"></v-date-picker>
							</v-menu>

						</template>
					</v-col>
					<v-col cols="12"
								 sm="6">
						<v-file-input show-size
													:rules="uploadrules"
													counter
													accept="image/png, image/jpeg , application/pdf"
													:placeholder="'Justificatif de  '+name+' '+firstname"
													@blur="blur('file_ci1')"
													@change="upload"> </v-file-input>
					</v-col>
					<v-col cols="12">

						<b class="primary--text">Justificatif d'identité 2</b>
					</v-col>
					<v-col cols="12"
								 sm="6">
						<v-select v-model="typeid2"
											:items="id_items"
											label="Justificatif identité 2"> </v-select>
					</v-col>
					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="numeroid2"
													label="Numéro justificatif identité 2"
													counter
													maxlength="199"></v-text-field>
					</v-col>

					<v-col cols="12"
								 sm="6">
						<template>
							<v-menu ref="menudatevalide2"
											v-model="menudatevalide2"
											:close-on-content-click="false"
											transition="scale-transition"
											offset-y
											min-width="auto">
								<template v-slot:activator="{ on, attrs2 }">
									<v-text-field v-model="datevalide2"
																label="Date de fin de validité"
																readonly
																v-bind="attrs2"
																v-on="on"></v-text-field>
								</template>
								<v-date-picker v-model="datevalide2"
															 :min="datemindatevalide2"
															 :active-picker.sync="activePickerdatevalide2"
															 @change="savedatevalide2"></v-date-picker>
							</v-menu>
						</template>

					</v-col>


					<v-col cols="12"
								 sm="6">

						<v-file-input show-size
													:rules="uploadrules"
													counter
													accept="image/png, image/jpeg , application/pdf"
													:placeholder="'Justificatif de  '+name+' '+firstname"
													@blur="blur('file_ci2')"
													@change="upload"> </v-file-input>

					</v-col>

					<v-col cols="12">

						<b class="primary--text">Justificatif de domicile et iban</b>
					</v-col>


					<v-col cols="12"
								 sm="6">

						<v-file-input show-size
													:rules="uploadrules"
													counter
													accept="image/png, image/jpeg , application/pdf"
													:placeholder="'Justificatif de domicile du '+adresse+' '+codepostal +' '+ville"
													@blur="blur('file_dom')"
													@change="upload"> </v-file-input>

					</v-col>

					<v-col cols="12"
								 sm="6">

						<v-file-input show-size
													:rules="uploadrules"
													counter
													accept="image/png, image/jpeg , application/pdf"
													:placeholder="'RIB du compte  '+iban"
													@blur="blur('file_iban')"
													@change="upload"> </v-file-input>

					</v-col>
					<span class="ma-4">
						<h5>POINTS A VERIFIER IMPERATIVEMENT</h5>
						<p style="font-size:80%">
							<br /> - Les fichiers ne doivent pas dépasser 2Mo
							<br /> - Le numéro IBAN saisi doit correspondre à l'IBAN de la pièce justificative téléchargée.
							<br /> - Vérifier la date d'expiration des justificatifs d'identités
							<br /> - Les documents doivent être lisible
							<br /> - Un seul document recto-verso par justificatifs doit être téléchargé.
							<br /> - Pour les sociétés: les justificatifs d'identité doivent être celles du mandataire social ou dirigeant
							<br /> - Le nom et le prénom (<b class="text-red">{{name}} {{firstname}}</b>) doit correspondent au nom et prénom des justificatifs d'identité et du justificatif de domicile.
							<br />
							<br />
							<b>Attention</b> : vérifiez bien les informations, une fois dans la blockchain elles ne seront plus modifiables.
							<br /> Ces informations seront bien évidement cryptées et non consultable par le public.
						</p>
					</span>

				</v-row>

				<v-btn small
							 color="success"
							 @click="publickey != undefined  ? e1 =e1+2 :  e1 =e1+1">
					Continue
				</v-btn>
				<v-btn small
							 text
							 @click="e1 =e1-1">
					Retour
				</v-btn>
			</v-stepper-content>
			<v-stepper-content step="6">
				<v-row>
					<v-col cols="12">
						<b class="primary--text">Compte Stellar</b>
						<br />
						<br />
						<b>Clé publique</b>
						<br />
						<span class="pb-3"
									v-if="source_keys != undefined"
									style="font-size:75%"> {{source_keys.publicKey()}}</span>
						<br />
						<b>Clé secréte</b>

						<br />
						<span v-if="source_keys != undefined"
									style="font-size:75%"> {{source_keys.secret()}}</span>
						<br />


					</v-col>
					<v-col cols="6"
								 v-if="source_keys != undefined"
								 sm="6">
						<qrcode-vue :value="source_keys.publicKey()"></qrcode-vue>
						Clé publique

					</v-col>
					<v-col cols="6"
								 v-if="source_keys != undefined"
								 sm="6">
						<qrcode-vue :value="source_keys.secret()"></qrcode-vue>
						Clé secréte
					</v-col>
					<v-col cols="12"
								 v-if="source_keys != undefined">
						<b class="red--text">Attention:</b> nous ne conservons pas votre clé secréte, vous en êtes seul responsable.
						<br />Cette clé est indispensable pour signer les transactions de récupération, de vente, d'achat ou de transfert de tokens.
						<br /> <br />Il est de <b class="red--text">VOTRE RESPONSABILITE</b> de la conserver en lieu sûr.
						<v-switch v-model="switchconfirm"
											class="mx-4"
											inset
											label="Switch pour confirmer  "></v-switch>

					</v-col>
				</v-row>
				<v-row>

					<v-col cols="12"
								 v-if="source_keys != undefined"
								 sm="6">



					</v-col>
					<v-col cols="12"
								 v-if="false"
								 sm="6">

					</v-col>
				</v-row>

				<v-btn class="success my-4 mr-6">
					<A style="text-decoration:none;"
						 class="  white--text "
						 href="javascript:window.print()">
						<v-icon dark> mdi-printer </v-icon>
					</A>
				</v-btn>
				<v-btn small
							 v-if="switchconfirm"
							 color="success"
							 @click="e1 =e1+1">
					Continue
				</v-btn>
				<v-btn small
							 text
							 @click="e1 =e1-1">
					Retour
				</v-btn>

			</v-stepper-content>
			<v-stepper-content step="7">
				<v-row>
					<v-col cols="12">

						<b class="primary--text">Vérification</b>
					</v-col>

					<table class="pa-3">

						<tr>
							<td><span class="caption">Civilité</span></td>
						</tr>
						<tr>
							<td><b class="red--text"> {{civilite==2 ? 'M.' : 'Mme'}}</b> </td>
						</tr>
						<tr>
							<td><span class="caption">Nom</span></td>
						</tr>
						<tr>
							<td><b class="red--text"> {{name}}</b></td>
						</tr>
						<tr>
							<td><span class="caption">Prénom</span></td>
						</tr>
						<tr>
							<td><b class="red--text"> {{firstname}}</b></td>
						</tr>
						<tr>
							<td><span class="caption">Date de naissance</span></td>
						</tr>
						<tr>
							<td><b class="red--text"> {{datenaissance}}</b></td>
						</tr>
						<tr>
							<td><span class="caption">Département de naissance</span></td>
						</tr>
						<tr>
							<td><b class="red--text"> {{departementnaissance}}</b></td>
						</tr>
						<tr>
							<td><span class="caption">Ville de naissance</span></td>
						</tr>
						<tr>
							<td><b class="red--text"> {{villenaissance}}</b></td>
						</tr>
						<tr>
							<td><span class="caption">Nationalité</span></td>
						</tr>
						<tr>
							<td><b class="red--text"> {{nationalite}}</b></td>
						</tr>
						<tr>
							<td><span class="caption">Situation de famille</span></td>
						</tr>
						<tr>
							<td><b v-if="situationfamille > 0"
									 class="red--text"> {{situationfamille_items[situationfamille-1].text}}</b></td>
						</tr>
						<tr>
							<td><span class="caption">Pays de naissance</span></td>
						</tr>
						<tr>
							<td><b class="red--text"> {{paysnaissance}}</b></td>
						</tr>
						<tr>
							<td><span class="caption">Résidence fiscale</span></td>
						</tr>
						<tr>
							<td><b class="red--text"> {{residencefiscale}}</b></td>
						</tr>
						<tr>
							<td><span class="caption">Adresse</span></td>
						</tr>
						<tr>
							<td><b class="red--text"> {{adresse}}</b></td>
						</tr>
						<tr>
							<td><span class="caption">Code postal</span></td>
						</tr>
						<tr>
							<td><b class="red--text"> {{codepostal}}</b></td>
						</tr>
						<tr>
							<td><span class="caption">Ville</span></td>
						</tr>
						<tr>
							<td><b class="red--text"> {{ville}}</b></td>
						</tr>
						<tr>
							<td><span class="caption">Iban</span></td>
						</tr>
						<tr>
							<td><b class="red--text"> {{iban}}</b></td>
						</tr>
						<tr>
							<td><span class="caption">Bic</span></td>
						</tr>
						<tr>
							<td><b class="red--text"> {{bic}}</b></td>
						</tr>
						<tr>
							<td><span class="caption">Nom titulaire du compte</span></td>
						</tr>
						<tr>
							<td><b class="red--text"> {{nomiban}}</b></td>
						</tr>
						<tr>
							<td><span class="caption">Email</span></td>
						</tr>
						<tr>
							<td><b class="red--text"> {{emailcontact}}</b></td>
						</tr>
						<tr>
							<td><span class="caption">Téléphone</span></td>
						</tr>
						<tr>
							<td><b class="red--text"> {{telephone}}</b></td>
						</tr>
						<tr>
							<td><span class="caption">Type du justificatif d'identité 1</span></td>
						</tr>
						<tr>
							<td><b v-if="typeid1 > 0"
									 class="red--text">{{domicile_items[typeid1-1].text}} </b></td>
						</tr>
						<tr>
							<td><span class="caption">Date du justificatif d'identité 1</span></td>
						</tr>
						<tr>
							<td><b class="red--text"> {{datevalide1}}</b></td>
						</tr>
						<tr>
							<td><span class="caption">Numéro du justificatif d'identité 1</span></td>
						</tr>
						<tr>
							<td><b class="red--text"> {{numeroid1}}</b></td>
						</tr>
						<tr>
							<td><span class="caption">Type du justificatif d'identité 2</span></td>
						</tr>
						<tr>
							<td><b v-if="typeid2 > 0"
									 class="red--text"> {{domicile_items[typeid2-1].text}}</b></td>
						</tr>
						<tr>
							<td><span class="caption">Date du justificatif d'identité 2</span></td>
						</tr>
						<tr>
							<td><b class="red--text"> {{datevalide2}}</b></td>
						</tr>
						<tr>
							<td><span class="caption">Numéro du justificatif d'identité 2</span></td>
						</tr>
						<tr>
							<td><b class="red--text"> {{numeroid2}}</b></td>
						</tr>


					</table>

				</v-row>
				<v-btn small
							 v-if="switchconfirm"
							 color="success"
							 @click="validationfinale()">{{switchconfirm ? 'Valider vos KYC/AML' : 'switch avant validation'}}

				</v-btn>
				<v-btn small
							 text
							 @click="publickey != undefined ? e1 =e1-2 :  e1 =e1-1">


					Retour
				</v-btn>
			</v-stepper-content>
		</v-stepper-items>
	</v-stepper>
</div>
</template>


<script>
var StellarSdk = require('stellar-sdk');

import IBAN from "iban";
import QrcodeVue from 'qrcode.vue'


export default {
	name: 'ouverture',
	components: {
		QrcodeVue
	},
	props: {},
	data: () => ({
		rules: {
			required: value => !"" || 'Indispensable.',
		},
		e1: 1,
		uploadrules: [

			(value) =>
			!value ||
			value.size < 2000000 ||
			"Ne doit pas dépasser 2Mo",

		],
		headers: [{
				text: 'id ',
				align: 'start',
				value: 'id',
			},
			{
				text: 'Nom ',
				align: 'start',
				value: 'name',
			},
			{
				text: 'Prénom',
				value: 'firstname',
				align: 'start',

			},
			{
				text: 'Civilité',
				value: 'labelcivilite',
				align: 'start',
			},
			{
				text: 'Status',
				value: 'labelsituationfamille',
				align: 'start',
			},
			{
				text: 'Age',
				value: 'age',
				align: 'start',
			},
			{
				text: 'Profession',
				value: 'profession',
				align: 'start',
			},
		],


		situationfamille_items: [{
				text: "Célibataire",
				value: "1"
			},
			{
				text: "Marié(e)",
				value: "2"
			},
			{
				text: "Pacsé(e)",
				value: "3"
			},
			{
				text: "Veuf(e)",
				value: "4"
			},
			{
				text: "Divorcé(e)",
				value: "5"
			}
		],

		civilite_items: [{
				text: "M.",
				value: "1"
			},
			{
				text: "Mme",
				value: "2"
			}
		],
		domicile_items: [{
				value: "0",
				text: "Facture de téléphone (y compris de téléphone mobile)"
			},
			{
				value: "1",
				text: "Facture d'électricité ou de gaz"
			},
			{
				value: "2",
				text: "Quittance de loyer (d'un organisme social ou d'une agence immobilière) ou titre de propriété"
			},
			{
				value: "3",
				text: "Facture d'eau"
			},
			{
				value: "4",
				text: "Avis d'imposition ou certificat de non imposition"
			},
			{
				value: "5",
				text: "Justificatif de taxe d'habitation"
			},
			{
				value: "6",
				text: "Attestation ou facture d'assurance du logement"
			},
			{
				value: "7",
				text: "Relevé de la Caf mentionnant les aides liées au logement"
			}
		],

		id_items: [{
				value: "0",
				text: "Carte d'identité"
			},
			{
				value: "1",
				text: "Passeport"
			},
			{
				value: "2",
				text: "Permis de conduire"
			},
			{
				value: "3",
				text: "Acte de naissance"
			},
			{
				value: "4",
				text: "Livret de famille"
			},
			{
				value: "5",
				text: "Livret militaire"
			},
			{
				value: "6",
				text: "Carte d'électeur"
			},
			{
				value: "7",
				text: "Titre de séjour"
			},
			{
				value: "8",
				text: "Carte vitale"
			}
		],
		id_pays: [

			{
				value: "AF",
				text: "Afghanistan"
			},
			{
				value: "ZA",
				text: "Afrique du Sud"
			},
			{
				value: "AL",
				text: "Albanie"
			},
			{
				value: "DZ",
				text: "Algérie"
			},
			{
				value: "DE",
				text: "Allemagne"
			},
			{
				value: "AD",
				text: "Andorre"
			},
			{
				value: "AO",
				text: "Angola"
			},
			{
				value: "AI",
				text: "Anguilla"
			},
			{
				value: "AQ",
				text: "Antarctique"
			},
			{
				value: "AG",
				text: "Antigua-et-Barbuda"
			},
			{
				value: "AN",
				text: "Antilles néerlandaises"
			},
			{
				value: "SA",
				text: "Arabie saoudite"
			},
			{
				value: "AR",
				text: "Argentine"
			},
			{
				value: "AM",
				text: "Arménie"
			},
			{
				value: "AW",
				text: "Aruba"
			},
			{
				value: "AU",
				text: "Australie"
			},
			{
				value: "AT",
				text: "Autriche"
			},
			{
				value: "AZ",
				text: "Azerbaïdjan"
			},
			{
				value: "BS",
				text: "Bahamas"
			},
			{
				value: "BH",
				text: "Bahreïn"
			},
			{
				value: "BD",
				text: "Bangladesh"
			},
			{
				value: "BB",
				text: "Barbade"
			},
			{
				value: "BY",
				text: "Bélarus"
			},
			{
				value: "BE",
				text: "Belgique"
			},
			{
				value: "BZ",
				text: "Belize"
			},
			{
				value: "BJ",
				text: "Bénin"
			},
			{
				value: "BM",
				text: "Bermudes"
			},
			{
				value: "BT",
				text: "Bhoutan"
			},
			{
				value: "BO",
				text: "Bolivie"
			},
			{
				value: "BA",
				text: "Bosnie-Herzégovine"
			},
			{
				value: "BW",
				text: "Botswana"
			},
			{
				value: "BR",
				text: "Brésil"
			},
			{
				value: "BN",
				text: "Brunéi Darussalam"
			},
			{
				value: "BG",
				text: "Bulgarie"
			},
			{
				value: "BF",
				text: "Burkina Faso"
			},
			{
				value: "BI",
				text: "Burundi"
			},
			{
				value: "KH",
				text: "Cambodge"
			},
			{
				value: "CM",
				text: "Cameroun"
			},
			{
				value: "CA",
				text: "Canada"
			},
			{
				value: "CV",
				text: "Cap-Vert"
			},
			{
				value: "EA",
				text: "Ceuta et Melilla"
			},
			{
				value: "CL",
				text: "Chili"
			},
			{
				value: "CN",
				text: "Chine"
			},
			{
				value: "CY",
				text: "Chypre"
			},
			{
				value: "CO",
				text: "Colombie"
			},
			{
				value: "KM",
				text: "Comores"
			},
			{
				value: "CG",
				text: "Congo-Brazzaville"
			},
			{
				value: "KP",
				text: "Corée du Nord"
			},
			{
				value: "KR",
				text: "Corée du Sud"
			},
			{
				value: "CR",
				text: "Costa Rica"
			},
			{
				value: "CI",
				text: "Côte d’Ivoire"
			},
			{
				value: "HR",
				text: "Croatie"
			},
			{
				value: "CU",
				text: "Cuba"
			},
			{
				value: "DK",
				text: "Danemark"
			},
			{
				value: "DG",
				text: "Diego Garcia"
			},
			{
				value: "DJ",
				text: "Djibouti"
			},
			{
				value: "DM",
				text: "Dominique"
			},
			{
				value: "EG",
				text: "Égypte"
			},
			{
				value: "SV",
				text: "El Salvador"
			},
			{
				value: "AE",
				text: "Émirats arabes unis"
			},
			{
				value: "EC",
				text: "Équateur"
			},
			{
				value: "ER",
				text: "Érythrée"
			},
			{
				value: "ES",
				text: "Espagne"
			},
			{
				value: "EE",
				text: "Estonie"
			},
			{
				value: "VA",
				text: "État de la Cité du Vatican"
			},
			{
				value: "FM",
				text: "États fédérés de Micronésie"
			},
			{
				value: "US",
				text: "États-Unis"
			},
			{
				value: "ET",
				text: "Éthiopie"
			},
			{
				value: "FJ",
				text: "Fidji"
			},
			{
				value: "FI",
				text: "Finlande"
			},
			{
				value: "FR",
				text: "France"
			},
			{
				value: "GA",
				text: "Gabon"
			},
			{
				value: "GM",
				text: "Gambie"
			},
			{
				value: "GE",
				text: "Géorgie"
			},
			{
				value: "GS",
				text: "Géorgie du Sud et les îles Sandwich du Sud"
			},
			{
				value: "GH",
				text: "Ghana"
			},
			{
				value: "GI",
				text: "Gibraltar"
			},
			{
				value: "GR",
				text: "Grèce"
			},
			{
				value: "GD",
				text: "Grenade"
			},
			{
				value: "GL",
				text: "Groenland"
			},
			{
				value: "GP",
				text: "Guadeloupe"
			},
			{
				value: "GU",
				text: "Guam"
			},
			{
				value: "GT",
				text: "Guatemala"
			},
			{
				value: "GG",
				text: "Guernesey"
			},
			{
				value: "GN",
				text: "Guinée"
			},
			{
				value: "GQ",
				text: "Guinée équatoriale"
			},
			{
				value: "GW",
				text: "Guinée-Bissau"
			},
			{
				value: "GY",
				text: "Guyana"
			},
			{
				value: "GF",
				text: "Guyane française"
			},
			{
				value: "HT",
				text: "Haïti"
			},
			{
				value: "HN",
				text: "Honduras"
			},
			{
				value: "HU",
				text: "Hongrie"
			},
			{
				value: "BV",
				text: "Île Bouvet"
			},
			{
				value: "CX",
				text: "Île Christmas"
			},
			{
				value: "CP",
				text: "Île Clipperton"
			},
			{
				value: "AC",
				text: "Île de l'Ascension"
			},
			{
				value: "IM",
				text: "Île de Man"
			},
			{
				value: "NF",
				text: "Île Norfolk"
			},
			{
				value: "AX",
				text: "Îles Åland"
			},
			{
				value: "KY",
				text: "Îles Caïmans"
			},
			{
				value: "IC",
				text: "Îles Canaries"
			},
			{
				value: "CC",
				text: "Îles Cocos - Keeling"
			},
			{
				value: "CK",
				text: "Îles Cook"
			},
			{
				value: "FO",
				text: "Îles Féroé"
			},
			{
				value: "HM",
				text: "Îles Heard et MacDonald"
			},
			{
				value: "FK",
				text: "Îles Malouines"
			},
			{
				value: "MP",
				text: "Îles Mariannes du Nord"
			},
			{
				value: "MH",
				text: "Îles Marshall"
			},
			{
				value: "UM",
				text: "Îles Mineures Éloignées des États-Unis"
			},
			{
				value: "SB",
				text: "Îles Salomon"
			},
			{
				value: "TC",
				text: "Îles Turks et Caïques"
			},
			{
				value: "VG",
				text: "Îles Vierges britanniques"
			},
			{
				value: "VI",
				text: "Îles Vierges des États-Unis"
			},
			{
				value: "IN",
				text: "Inde"
			},
			{
				value: "ID",
				text: "Indonésie"
			},
			{
				value: "IQ",
				text: "Irak"
			},
			{
				value: "IR",
				text: "Iran"
			},
			{
				value: "IE",
				text: "Irlande"
			},
			{
				value: "IS",
				text: "Islande"
			},
			{
				value: "IL",
				text: "Israël"
			},
			{
				value: "IT",
				text: "Italie"
			},
			{
				value: "JM",
				text: "Jamaïque"
			},
			{
				value: "JP",
				text: "Japon"
			},
			{
				value: "JE",
				text: "Jersey"
			},
			{
				value: "JO",
				text: "Jordanie"
			},
			{
				value: "KZ",
				text: "Kazakhstan"
			},
			{
				value: "KE",
				text: "Kenya"
			},
			{
				value: "KG",
				text: "Kirghizistan"
			},
			{
				value: "KI",
				text: "Kiribati"
			},
			{
				value: "KW",
				text: "Koweït"
			},
			{
				value: "LA",
				text: "Laos"
			},
			{
				value: "LS",
				text: "Lesotho"
			},
			{
				value: "LV",
				text: "Lettonie"
			},
			{
				value: "LB",
				text: "Liban"
			},
			{
				value: "LR",
				text: "Libéria"
			},
			{
				value: "LY",
				text: "Libye"
			},
			{
				value: "LI",
				text: "Liechtenstein"
			},
			{
				value: "LT",
				text: "Lituanie"
			},
			{
				value: "LU",
				text: "Luxembourg"
			},
			{
				value: "MK",
				text: "Macédoine"
			},
			{
				value: "MG",
				text: "Madagascar"
			},
			{
				value: "MY",
				text: "Malaisie"
			},
			{
				value: "MW",
				text: "Malawi"
			},
			{
				value: "MV",
				text: "Maldives"
			},
			{
				value: "ML",
				text: "Mali"
			},
			{
				value: "MT",
				text: "Malte"
			},
			{
				value: "MA",
				text: "Maroc"
			},
			{
				value: "MQ",
				text: "Martinique"
			},
			{
				value: "MU",
				text: "Maurice"
			},
			{
				value: "MR",
				text: "Mauritanie"
			},
			{
				value: "YT",
				text: "Mayotte"
			},
			{
				value: "MX",
				text: "Mexique"
			},
			{
				value: "MD",
				text: "Moldavie"
			},
			{
				value: "MC",
				text: "Monaco"
			},
			{
				value: "MN",
				text: "Mongolie"
			},
			{
				value: "ME",
				text: "Monténégro"
			},
			{
				value: "MS",
				text: "Montserrat"
			},
			{
				value: "MZ",
				text: "Mozambique"
			},
			{
				value: "MM",
				text: "Myanmar"
			},
			{
				value: "NA",
				text: "Namibie"
			},
			{
				value: "NR",
				text: "Nauru"
			},
			{
				value: "NP",
				text: "Népal"
			},
			{
				value: "NI",
				text: "Nicaragua"
			},
			{
				value: "NE",
				text: "Niger"
			},
			{
				value: "NG",
				text: "Nigéria"
			},
			{
				value: "NU",
				text: "Niue"
			},
			{
				value: "NO",
				text: "Norvège"
			},
			{
				value: "NC",
				text: "Nouvelle-Calédonie"
			},
			{
				value: "NZ",
				text: "Nouvelle-Zélande"
			},
			{
				value: "OM",
				text: "Oman"
			},
			{
				value: "UG",
				text: "Ouganda"
			},
			{
				value: "UZ",
				text: "Ouzbékistan"
			},
			{
				value: "PK",
				text: "Pakistan"
			},
			{
				value: "PW",
				text: "Palaos"
			},
			{
				value: "PA",
				text: "Panama"
			},
			{
				value: "PG",
				text: "Papouasie-Nouvelle-Guinée"
			},
			{
				value: "PY",
				text: "Paraguay"
			},
			{
				value: "NL",
				text: "Pays-Bas"
			},
			{
				value: "PE",
				text: "Pérou"
			},
			{
				value: "PH",
				text: "Philippines"
			},
			{
				value: "PN",
				text: "Pitcairn"
			},
			{
				value: "PL",
				text: "Pologne"
			},
			{
				value: "PF",
				text: "Polynésie française"
			},
			{
				value: "PR",
				text: "Porto Rico"
			},
			{
				value: "PT",
				text: "Portugal"
			},
			{
				value: "QA",
				text: "Qatar"
			},
			{
				value: "HK",
				text: "R.A.S. chinoise de Hong Kong"
			},
			{
				value: "MO",
				text: "R.A.S. chinoise de Macao"
			},
			{
				value: "QO",
				text: "régions éloignées de l’Océanie"
			},
			{
				value: "CF",
				text: "République centrafricaine"
			},
			{
				value: "CD",
				text: "République démocratique du Congo"
			},
			{
				value: "DO",
				text: "République dominicaine"
			},
			{
				value: "CZ",
				text: "République tchèque"
			},
			{
				value: "RE",
				text: "Réunion"
			},
			{
				value: "RO",
				text: "Roumanie"
			},
			{
				value: "GB",
				text: "Royaume-Uni"
			},
			{
				value: "RU",
				text: "Russie"
			},
			{
				value: "RW",
				text: "Rwanda"
			},
			{
				value: "EH",
				text: "Sahara occidental"
			},
			{
				value: "BL",
				text: "Saint-Barthélémy"
			},
			{
				value: "KN",
				text: "Saint-Kitts-et-Nevis"
			},
			{
				value: "SM",
				text: "Saint-Marin"
			},
			{
				value: "MF",
				text: "Saint-Martin"
			},
			{
				value: "PM",
				text: "Saint-Pierre-et-Miquelon"
			},
			{
				value: "VC",
				text: "Saint-Vincent-et-les Grenadines"
			},
			{
				value: "SH",
				text: "Sainte-Hélène"
			},
			{
				value: "LC",
				text: "Sainte-Lucie"
			},
			{
				value: "WS",
				text: "Samoa"
			},
			{
				value: "AS",
				text: "Samoa américaines"
			},
			{
				value: "ST",
				text: "Sao Tomé-et-Principe"
			},
			{
				value: "SN",
				text: "Sénégal"
			},
			{
				value: "RS",
				text: "Serbie"
			},
			{
				value: "CS",
				text: "Serbie-et-Monténégro"
			},
			{
				value: "SC",
				text: "Seychelles"
			},
			{
				value: "SL",
				text: "Sierra Leone"
			},
			{
				value: "SG",
				text: "Singapour"
			},
			{
				value: "SK",
				text: "Slovaquie"
			},
			{
				value: "SI",
				text: "Slovénie"
			},
			{
				value: "SO",
				text: "Somalie"
			},
			{
				value: "SD",
				text: "Soudan"
			},
			{
				value: "LK",
				text: "Sri Lanka"
			},
			{
				value: "SE",
				text: "Suède"
			},
			{
				value: "CH",
				text: "Suisse"
			},
			{
				value: "SR",
				text: "Suriname"
			},
			{
				value: "SJ",
				text: "Svalbard et Île Jan Mayen"
			},
			{
				value: "SZ",
				text: "Swaziland"
			},
			{
				value: "SY",
				text: "Syrie"
			},
			{
				value: "TJ",
				text: "Tadjikistan"
			},
			{
				value: "TW",
				text: "Taïwan"
			},
			{
				value: "TZ",
				text: "Tanzanie"
			},
			{
				value: "TD",
				text: "Tchad"
			},
			{
				value: "TF",
				text: "Terres australes françaises"
			},
			{
				value: "IO",
				text: "Territoire britannique de l'océan Indien"
			},
			{
				value: "PS",
				text: "Territoire palestinien"
			},
			{
				value: "TH",
				text: "Thaïlande"
			},
			{
				value: "TL",
				text: "Timor oriental"
			},
			{
				value: "TG",
				text: "Togo"
			},
			{
				value: "TK",
				text: "Tokelau"
			},
			{
				value: "TO",
				text: "Tonga"
			},
			{
				value: "TT",
				text: "Trinité-et-Tobago"
			},
			{
				value: "TA",
				text: "Tristan da Cunha"
			},
			{
				value: "TN",
				text: "Tunisie"
			},
			{
				value: "TM",
				text: "Turkménistan"
			},
			{
				value: "TR",
				text: "Turquie"
			},
			{
				value: "TV",
				text: "Tuvalu"
			},
			{
				value: "UA",
				text: "Ukraine"
			},
			{
				value: "EU",
				text: "Union européenne"
			},
			{
				value: "UY",
				text: "Uruguay"
			},
			{
				value: "VU",
				text: "Vanuatu"
			},
			{
				value: "VE",
				text: "Venezuela"
			},
			{
				value: "VN",
				text: "Viêt Nam"
			},
			{
				value: "WF",
				text: "Wallis-et-Futuna"
			},
			{
				value: "YE",
				text: "Yémen"
			},
			{
				value: "ZM",
				text: "Zambie"
			},
			{
				value: "ZW",
				text: "Zimbabwe"
			}
		],

		id_metier: [{
				value: "10",
				text: "Agriculteurs (salariés de leur exploitation)"
			},
			{
				value: "21",
				text: "Artisans (salariés de leur entreprise)"
			},
			{
				value: "22",
				text: "Commerçants et assimilés (salariés de leur entreprise)"
			},
			{
				value: "23",
				text: "Chefs d'entreprise de 10 salariés ou plus (salariés de leur entreprise)"
			},
			{
				value: "31",
				text: "Professions libérales (exercées sous statut de salarié)"
			},
			{
				value: "33",
				text: "Cadres de la fonction publique"
			},
			{
				value: "34",
				text: "Professeurs, professions scientifiques"
			},
			{
				value: "35",
				text: "Professions de l'information, des arts et des spectacles"
			},
			{
				value: "37",
				text: "Cadres administratifs et commerciaux d'entreprises"
			},
			{
				value: "38",
				text: "Ingénieurs et cadres techniques d'entreprises"
			},
			{
				value: "42",
				text: "Professeurs des écoles, instituteurs et professions assimilées"
			},
			{
				value: "43",
				text: "Professions intermédiaires de la santé et du travail social"
			},
			{
				value: "44",
				text: "Clergé, religieux"
			},
			{
				value: "45",
				text: "Professions intermédiaires administratives de la fonction publique"
			},
			{
				value: "46",
				text: "Professions intermédiaires administratives et commerciales des entreprises"
			},
			{
				value: "47",
				text: "Techniciens (sauf techniciens tertiaires)"
			},
			{
				value: "48",
				text: "Contremaîtres, agents de maîtrise (maîtrise administrative exclue)"
			},
			{
				value: "52",
				text: "Employés civils et agents de service de la fonction publique"
			},
			{
				value: "53",
				text: "Policiers, militaires et agents de surveillance"
			},
			{
				value: "54",
				text: "Employés administratifs d'entreprise"
			},
			{
				value: "55",
				text: "Employés de commerce"
			},
			{
				value: "56",
				text: "Personnels des services directs aux particuliers"
			},
			{
				value: "62",
				text: "Ouvriers qualifiés de type industriel"
			},
			{
				value: "63",
				text: "Ouvriers qualifiés de type artisanal"
			},
			{
				value: "64",
				text: "Chauffeurs"
			},
			{
				value: "65",
				text: "Ouvriers qualifiés de la manutention, du magasinage et du transport"
			},
			{
				value: "67",
				text: "Ouvriers non qualifiés de type industriel"
			},
			{
				value: "68",
				text: "Ouvriers non qualifiés de type artisanal"
			},
			{
				value: "69",
				text: "Ouvriers agricoles et assimilés"
			},
		],


		activePickerdatenaissance: null,
		menudatenaissance: false,

		activePickerdatevalide1: null,
		menudatevalide1: false,

		activePickerdatevalide2: null,
		menudatevalide2: false,

		datemindatevalide1: undefined,
		datemindatevalide2: undefined,

		move_tab: true,
		tab: null,
		table_id: 0,

		id: "",
		private_key: "",

		save_associate: [],
		axios_private: [],
		data_list: [],
		search: '',


		raison_sociale: '',
		civilite: '',
		name: '',
		firstname: '',
		datenaissance: '',
		departementnaissance: '',
		villenaissance: '',
		nationalite: '',
		profession: '',
		situationfamille: '',
		residencefiscale: '',
		paysnaissance: '',
		iban: '',
		bic: '',
		nomiban: '',
		siren: '',
		emailcontact: '',
		typeid1: '',
		typeid2: '',
		numeroid1: '',
		datevalide1: null,
		numeroid2: '',
		datevalide2: null,
		telephone: '',
		adresse: '',
		codepostal: '',
		ville: '',
		crypt: '',
		origin_upload: '',
		snackbar: false,
		text: 'IBAN FALSE !!! ',
		timeout: 2000,
		step: 1,

		nofield: "",
		source_keys: undefined,
		switchconfirm: false,
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		let datenow = new Date();
		this.datemindatevalide1 = datenow.toISOString().slice(0, 10);
		this.datemindatevalide2 = datenow.toISOString().slice(0, 10);

		this.source_keys = StellarSdk.Keypair.random();


	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {

		publickey() {
			if (this.$store.state.publickey != undefined) this.switchconfirm = true;
			return this.$store.state.publickey
		},
	},
	methods: {

		verif_iban() {
			let valid = IBAN.isValid(this.iban);
			this.text = "Erreur dans l'IBAN"
			if (!valid) this.snackbar = true
			//console.log("verif iban :" + valid)

		},
		validationfinale() {
			this.text = "C'est sauvegardé";
			this.nofield = "";
			this.snackbar = true;
			this.e1 = 8;
			this.save_table_associate();

		},

		savedatenaissance(date) {
			this.$refs.menudatenaissance.save(date)
		},
		savedatevalide1(date) {
			this.$refs.menudatevalide1.save(date)
		},
		savedatevalide2(date) {
			this.$refs.menudatevalide2.save(date)
		},
		save_table_associate() {


			this.opt = [];
			this.opt["api"] = "save_client_fraction"
			this.opt["debug"] = true
			this.opt["id"] = "0"
			this.opt["civilite"] = this.civilite
			this.opt["name"] = this.name
			this.opt["firstname"] = this.firstname
			this.opt["datenaissance"] = this.datenaissance
			this.opt["departementnaissance"] = this.departementnaissance
			this.opt["villenaissance"] = this.villenaissance
			this.opt["nationalite"] = this.nationalite
			this.opt["profession"] = this.profession
			this.opt["situationfamille"] = this.situationfamille
			this.opt["residencefiscale"] = this.residencefiscale

			this.opt["raison_sociale"] = this.raison_sociale
			this.opt["paysnaissance"] = this.paysnaissance
			this.opt["iban"] = this.iban
			this.opt["bic"] = this.bic
			this.opt["nomiban"] = this.nomiban
			this.opt["siren"] = this.siren
			this.opt["emailcontact"] = this.emailcontact
			this.opt["typeid1"] = this.typeid1
			this.opt["typeid2"] = this.typeid2
			this.opt["numeroid1"] = this.numeroid1
			this.opt["numeroid2"] = this.numeroid2
			this.opt["telephone"] = this.telephone
			this.opt["adresse"] = this.adresse
			this.opt["codepostal"] = this.codepostal
			this.opt["ville"] = this.ville
			this.opt["datevalide1"] = this.datevalide1
			this.opt["datevalide2"] = this.datevalide2
			this.opt["step"] = this.e1 - 1

			if (this.publickey != undefined) this.opt["internal_key"] = this.publickey
			if (this.publickey == undefined) this.opt["internal_key"] = this.source_keys.publicKey()

			this.$store.dispatch('post', this.opt).then(response => {
				if (response != undefined) this.save_associate = response.data
			})
		},

		blur: function (origin) {

			this.origin_upload = origin;
		},
		upload: function (file) {

			//		this.internal_key = "?W.fzQzeMQKo5HgD;K@z8-.En"

			this.opt = [];
			this.opt["api"] = "post_upload"
			this.opt["debug"] = false
			this.opt["file"] = file
			this.opt["key"] = this.source_keys.publicKey()
			this.opt["origin"] = this.origin_upload


			this.$store.dispatch('upload', this.opt).then(response => {
				if (response != undefined) {

					//console.log(response);

					//			this.save_associate = response.data
				}
			})

		},
		extention: function (filename) {
			return filename.split('.').pop();
		},

	},
	watch: {
		e1() {


			if (this.e1 == 2) {

				if (this.emailcontact == "") this.nofield = this.nofield + "Email ";
				if (this.telephone == "") this.nofield = this.nofield + "Téléphone ";
			}

			if (this.e1 == 3) {
				if (this.emailcontact == "") this.nofield = this.nofield + "Email ";
				if (this.telephone == "") this.nofield = this.nofield + "Téléphone ";
				if (this.civilite == "") this.nofield = this.nofield + "Civilité ";
				if (this.firstname == "") this.nofield = this.nofield + "Prénom ";
				if (this.name == "") this.nofield = this.nofield + "Nom ";
				if (this.datenaissance == "") this.nofield = this.nofield + "Date de naissance ";
				if (this.departementnaissance == "") this.nofield = this.nofield + "Département de naissance ";
				if (this.villenaissance == "") this.nofield = this.nofield + "Ville naissance ";
				if (this.paysnaissance == "") this.nofield = this.nofield + "Pays de naissance ";
				if (this.nationalite == "") this.nofield = this.nofield + "Nationalité ";
				if (this.situationfamille == "") this.nofield = this.nofield + "Situation de famille ";
			}

			if (this.e1 == 4) {
				if (this.residencefiscale == "") this.nofield = this.nofield + "Résidence fiscale ";
				if (this.adresse == "") this.nofield = this.nofield + "Adresse  ";
				if (this.codepostal == "") this.nofield = this.nofield + "Code postal  ";
				if (this.ville == "") this.nofield = this.nofield + "Ville  ";
			}

			if (this.e1 == 5) {
				if (this.iban == "") this.nofield = this.nofield + "Iban ";
				if (this.bic == "") this.nofield = this.nofield + "Bic ";
				if (this.nomiban == "") this.nofield = this.nofield + "Nom titulaire du compte ";
			}

			if (this.e1 == 6) {
				if (this.numeroid1 == "") this.nofield = this.nofield + "Numéro du justificatif d'identité 1 ";
				if (this.numeroid2 == "") this.nofield = this.nofield + "Numéro du justificatif d'identité 2 ";
				if (this.typeid1 == "") this.nofield = this.nofield + "Type du justificatif d'identité 1 ";
				if (this.typeid2 == "") this.nofield = this.nofield + "Type du justificatif d'identité 2 ";
				if (this.datevalide1 == "") this.nofield = this.nofield + "Date du justificatif d'identité 1 ";
				if (this.datevalide2 == "") this.nofield = this.nofield + "Date du justificatif d'identité 2 ";
			}

			if (this.nofield != "") {
				this.e1 = this.e1 - 1;
				this.text = "Champs obligatoire :" + this.nofield;
				this.nofield = "";
				this.snackbar = true
			}
			this.save_table_associate();
		},

		menu(val) {
			val && setTimeout(() => (this.activePicker = 'YEAR'))
		},
		menudatenaissance(val) {
			val && setTimeout(() => (this.activePickerdatenaissance = 'YEAR'))
		},
		menudatevalide1(val) {
			val && setTimeout(() => (this.activePickerdatevalide1 = 'YEAR'))
		},
		menudatevalide2(val) {
			val && setTimeout(() => (this.activePickerdatevalide2 = 'YEAR'))
		},
		save_associate: function () {
			if (this.e1 == 8) {

				this.$emit('changepage', "dashboard")
			}
		},

	}
}
</script>

<style scoped>
</style>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const axios = require("axios");
var StellarSdk = require('stellar-sdk');


export default new Vuex.Store({
  state: {
    publickey: undefined,
    privatekey: "",
    accessgranted: true,
    cours_XML: 0,
    cours_XML_inverse: 0,
    blockchain: "public",
    total_assets_in_euro: 0,
    solde_fceuro: 0,
    total_assets_master_account_in_euro: 0,
    total_assets_distributor_account_in_euro: 0,
    total_assets_portfolio_account_in_euro: 0,
    balances: [],
    balances_master_account: [],
    balances_sequestre: [],
    balances_distributor_account: [],
    balances_portfolio_account: [],
    balances_operation_offer: [],
    liquidity_pool_distributor: [],
    operation_list: [],
    liquidity_pool: [],
    total_liquidity_pool_in_euro: 0,
    total_liquidity_pool_distributor_in_euro: 0,
    total_claimable_balances_in_euro: 0,
    claimable_balances: [],
    claimable_balances_trust: [],
    data_master_account: [],
    operations_sequestre: [],
    balances_operation_offer_distributor: [],
    stellarapi: "https://horizon.stellar.org/",
    teststellarapi: "https://horizon-testnet.stellar.org/",
    total_wallet: 0,
    // https: "http://localhost:82/",
    https: "https://api.fraction.re/",
    list_sci: undefined,

    fraction_master_test_key: "GA6RDH4X7D6DVLPBYPMDMCWIDVPBTX64OBDKT62ZEIRPCWXUSAWX7AIL",
    fraction_portfolio_test_key: "GANRM6SFLAZNQVH6EDBUK4XOB3X4WJ57HA6SMWTHMJY42XJVZRX7SWMH",
    fraction_distributor_test_key: "GCZ7SBJK4ZJL4W5PV7PKLLEP3BVE6DRHW5HKKRJFJPEM2DAOQ2BCY25F",
    claim_test_key: "GBYMGH5QFGZ5QOGB34STDTE4OKE2742K4VZZO3HIII6EJ56O2IKBEIGS",
    trust_test_key: "GCIR3KAKCSAZVNYJ7DJINS5K56AKZLAA7DZGC2XLW6YVDWIAUFAK42NE",
    sequestre_test_key: "GCDGBQBFDR7Q6EQF6VRV6OY2T3MWHEVP64NA4KFWT4S5CMS4O2PWRBVU",

    fraction_master_public_key: "GCSMNUEEBQIWW6YX5A3XR2L7WNXACSGNCKRJSOPOKACIQTCRME6ZENYF",
    fraction_distributor_public_key: "GDPLN3XF6OU3N2YNOAOSTDHHASRPVA3WT7J6K4MIITWMUY3AS2KGQWYH",
    fraction_portfolio_public_key: "GC3ARDNWCAAZFCMR2VETG64G6ZSQPGF56YQJ4WNPZO3KBH7KP6SO3WNT",
    claim_public_key: "GCV4RWPKH5EL5HHFAZKKHBTUWLBQJWNWWPPK643NEVFWZC7RZBYWLJYE",
    trust_public_key: "",
    sequestre_public_key: "",
    asset_code_fceuro: "fcEURO",
    double_identification: "",
    pin: undefined,
    demo_publickey: "GCWJ6BNFNU3OZZR6H4QYJTYJRII7XHXUJ6WSX5FEAGI4GJZVRXKDY4NM",
    demo_privatekey: "SDSF7I27L4ZAQ3AYVA7LOI22AR3BZXG6QIJQHH5LGOR45ZIZEOBG3Y5O",

  },
  mutations: {
    update_pin(state, result) {
      state.pin = result;
    },
    update_operation_list(state, result) {
      state.operation_list = result;
    },
    update_privatekey(state, privatekey) {
      state.privatekey = privatekey;
    },
    update_publickey(state, publickey) {
      state.publickey = publickey;
    },
    update_access(state, result) {
      state.accessgranted = result;
      if (!result) {
        state.publickey = undefined;
        state.privatekey = "";
        state.pin = undefined;
      }
    },
    update_blockchain(state) {
      state.blockchain == 'test' ? state.blockchain = 'public' : state.blockchain = 'test';
    },
    update_cours_XML(state, result) {
      state.cours_XML = result;
    },
    update_balances(state, result) {
      state.balances = result;
    },
    update_balances_distributor_account(state, result) {
      state.balances_distributor_account = result;
    },
    update_balances_portfolio_account(state, result) {
      state.balances_portfolio_account = result;
    },
    update_balances_master_account(state, result) {
      state.balances_master_account = result;
    },
    update_data_master_account(state, result) {
      state.data_master_account = result;
    },

    update_total_assets_in_euro(state, result) {
      state.total_assets_in_euro = result;
    },

    update_balances_sequestre(state, result) {
      state.balances_sequestre = result;
    },


    update_operations_sequestre(state, result) {
      state.operations_sequestre = result;
    },

    update_liquidity_pool_distributor(state, result) {
      state.liquidity_pool_distributor = result;
    },

    update_total_assets_master_account_in_euro(state, result) {
      state.total_assets_master_account_in_euro = result;
    },
    update_total_assets_portfolio_account_in_euro(state, result) {
      state.total_assets_portfolio_account_in_euro = result;
    },
    update_total_assets_distributor_account_in_euro(state, result) {
      state.total_assets_distributor_account_in_euro = result;
    },

    update_liquidity_pool(state, result) {
      state.liquidity_pool = result;
    },
    update_total_liquidity_pool_in_euro(state, result) {
      state.total_liquidity_pool_in_euro = result;
    },
    update_total_liquidity_pool_distributor_in_euro(state, result) {
      state.total_liquidity_pool_distributor_in_euro = result;
    },

    update_claimable_balances(state, result) {
      state.claimable_balances = result;
    },
    update_claimable_balances_trust(state, result) {
      state.claimable_balances_trust = result;
    },
    update_total_claimable_balances_in_euro(state, result) {
      state.total_claimable_balances_in_euro = result;
    },
    update_total_wallet(state, result) {
      state.total_wallet = result;
    },
    update_solde_fceuro(state, result) {
      state.solde_fceuro = result;
    },

    update_balances_operation_offer(state, result) {
      state.balances_operation_offer = result;
    },
    update_balances_operation_offer_distributor(state, result) {
      state.balances_operation_offer_distributor = result;
      result.forEach((item, i) => {
        //  console.log(item)
        if (item.buying.asset_code == state.asset_code_fceuro) state.cours_XML_inverse = (1 / item.price);
        if (item.buying.asset_type == "native") state.cours_XML = (1 / item.price);
      })
    },
    update_totp(state, result) {
      state.double_identification = result;
    },
  },

  getters: {
    blockchain: state => {
      return state.blockchain;
    },
    https: state => {
      return state.https;
    },
    horizon: state => {
      return state.blockchain == "test" ? state.teststellarapi : state.stellarapi;
    },
    net_pass: state => {
      return state.blockchain == "test" ? StellarSdk.Networks.TESTNET : StellarSdk.Networks.PUBLIC;
    },

    master_account: state => {
      return state.blockchain == "test" ? state.fraction_master_test_key : state.fraction_master_public_key;
    },
    distributor_account: state => {
      return state.blockchain == "test" ? state.fraction_distributor_test_key : state.fraction_distributor_public_key;
    },
    claim_account: state => {
      return state.blockchain == "test" ? state.claim_test_key : state.claim_public_key;
    },

    trust_account: state => {
      return state.blockchain == "test" ? state.trust_test_key : state.trust_public_key;
    },
    sequestre_account: state => {
      return state.blockchain == "test" ? state.sequestre_test_key : state.sequestre_public_key;
    },
    balance_coin: state => (account, coin) => {
      if (account == "my") {
        let temp = state.balances.find(balance => coin == balance.asset_code)
        if (temp != undefined) return temp.balance;
      }
    },


  },
  actions: {


    stellar_transaction_sign_source(context, params) {

      try {

        let source = params.source
        let operations = params.operations
        let memo = params.memo
        //console.log(source)
        //console.log(operations)
        //console.log(memo)
        var server = new StellarSdk.Server(this.getters.horizon);
        var net_pass = this.getters.net_pass;

        //console.log("server")


        return server
          .loadAccount(source.publicKey())
          .then(function(sourceAccount) {
            var transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
                fee: StellarSdk.BASE_FEE,
                networkPassphrase: net_pass,
              })
              .addMemo(StellarSdk.Memo.text(memo))
            //console.log(operations)
            transaction.operations = operations;
            let tx = transaction.setTimeout(180).build();
            tx.sign(source);
            return server.submitTransaction(tx);
          })
          .then(response => {
            //console.log("success " + memo)
            return Promise.resolve(response);
          })
          .catch(error => {
            //console.log("erreur " + memo)
            // console.log(error)

            //    if (error.response != undefined)console.log(error.response.data.extras.result_codes.operations)

            return Promise.resolve("Error:" + error.response.data.extras.result_codes.operations);
          });



      } catch (error) {
        //console.log("echec software");

        //console.log(error)
      }


    },
    get(context, url) {
      return axios
        .get(url)
        .then(response => {
          return Promise.resolve(response)
        })
        .catch(error => {

          //console.log("Une erreur est survenue");
          //console.log("error headers " + " " + error.response.headers);
          //console.log(error.response.headers);
          //console.log("error status " + " " + error.response.status);
          //console.log("error data " + " " + error.response.data);

          if (error.response.status == 401) this.state.session_token = "";

          return Promise.resolve(error);
        });

    },
    /*  ------------------------------------------------------------------------------------------------------
    -------------------------------------------------------------------- AXIOS ---------------------------
    ------------------------------------------------------------------------------------------------------*/


    upload: function(context, opt) {

      // ------------ definition de l'api et des parametres
      let data = new FormData();
      let api = this.getters.https + opt["api"] + ".php";
      opt["token"] = this.getters.token;

      // ------------ defini les parametres du post
      for (let [key, value] of Object.entries(opt)) {
        if (key != "api") data.append(key, value);
      }

      // ------------ affichage du debug
      if (opt["debug"]) {
        let log = "";
        let logget = "";
        for (var pair of data.entries()) {
          log += "$_POST['" + pair[0] + "']= '" + pair[1] + "' ;\n";
          logget += "&" + pair[0] + "=" + pair[1];
        }
        //console.log("APPEL API  : " + api);
        //console.log(log + "\n" + api + "?" + logget + "\n");
      }


      return axios
        .post(api, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          if (opt["debug"]) {
            //console.log("Retour axios de " + opt["api"] + " : " + response.data);
            //console.log(response.data);
          }
          return Promise.resolve(response);
        })
        .catch(error => {
          if (opt["debug"]) {
            //console.log("Une erreur est survenue");
            //console.log("error headers " + " " + error.response.headers);
            //console.log(error.response.headers);
            //console.log("error status ");
            //console.log(error.response.status);
            //console.log("error data ");
            //console.log(error.response.data);

            if (error.response.status == 401) this.state.session_token = "";
          }
          return Promise.resolve(error);
        });


    },




    post(context, opt) {
      //console.log("APPEL post " + opt["api"]);
      //console.log(opt);
      // ------------ definition de l'api et des parametres
      let data = new FormData();
      let api = this.getters.https + opt["api"] + ".php";
      opt["token"] = this.getters.token;

      // ------------ defini les parametres du post
      for (let [key, value] of Object.entries(opt)) {
        if (key != "api") data.append(key, value);
      }

      //  opt["debug"] = false;

      // ------------ affichage du debug
      if (opt["debug"]) {
        let log = "";
        let logget = "";
        for (var pair of data.entries()) {
          log += "$_POST['" + pair[0] + "']= '" + pair[1] + "' ;\n";
          logget += "&" + pair[0] + "=" + pair[1];
        }
        //console.log("APPEL API  : " + api);
        //console.log(log + "\n" + api + "?" + logget + "\n");
      }

      // ------------- l'envoi au serveur et la gestion de la reponse

      return axios
        .post(api, data)
        .then(response => {
          if (opt["debug"]) {
            //console.log("Retour axios de " + opt["api"] + " : " + response.data);
            //console.log(response.data);
          }
          return Promise.resolve(response);
        })
        .catch(error => {
          if (opt["debug"]) {
            //console.log("Une erreur est survenue");
            //console.log("error headers " + " " + error.response.headers);
            //console.log(error.response.headers);
            //console.log("error status " + " " + error.response.status);
            //console.log("error data " + " " + error.response.data);

            if (error.response.status == 401) this.state.session_token = "";
          }
          return Promise.resolve(error);
        });
    }


  },
  modules: {}
})

<template>
<v-dialog v-model="dialog"
					fullscreen
					hide-overlay
					transition="dialog-bottom-transition">
	<template v-slot:activator="{ on, attrs }">
		<v-btn class="px-5"
					 color="primary"
					 dark
					 v-bind="attrs"
					 v-on="on"
					 :class="css"
					 :block="block"
					 :small="small">
			{{label}}
		</v-btn>
	</template>
	<v-card>
		<v-toolbar dark
							 color="primary">
			<v-btn icon
						 dark
						 @click="dialog = false">
				<v-icon>mdi-close</v-icon>
			</v-btn> <b class="text-h6"> Acheter {{asset_code}} </b>
			<v-spacer></v-spacer>
			<span v-html="monetary_aspect(unit_price)+' €'" />
		</v-toolbar>

		<v-card-title class="teal lighten-5">Vos liquidités &nbsp; <v-spacer></v-spacer>
			<b class="justify-end success--text"
				 v-html="monetary_aspect(solde_fceuro)+' €'" />
		</v-card-title>



		<v-row class="mx-5 mt-2">
			<v-col cols=12>
				<v-card elevation=0>

					<div class="ma-3 pb-1">

						<v-text-field class="my-5"
													@blur="convert_euro"
													v-model="quantity"
													:label="'Quantité en '+ asset_code"></v-text-field>
						<v-text-field class="my-5"
													v-show="false"
													disabled
													v-model="price"
													label="Prix unitaire"></v-text-field>

						<v-text-field class="my-5"
													v-show="false"
													@blur="convert_berlcoin"
													v-model="field_euro"
													label="Ou montant total"></v-text-field>
					</div>
				</v-card>

				Durée du contrat et récompense
				<v-radio-group v-model="contrat">
					<v-radio v-for="(item, index) in term"
									 :key="index"
									 v-show="item.staking>0"
									 :value="item.an"
									 :label="item.label+' : '+item.staking+'%'"></v-radio>

				</v-radio-group>

				<v-card elevation=0>

					<v-card-text class="  text-h6">
						<div v-if="id_transaction > 0">
							<b class="py-3 outlined"> Modification de l'ordre d'achat numéro : {{id_transaction}} </b>
							<br /><br />
						</div>
						Acheter <b class="red--text"
							 v-html="monetary_aspect(quantity)" /> <code>{{asset_code}}</code>
						<br /> à <b class="red--text"
							 v-html="monetary_aspect(price)" /> <code>{{asset_code_fceuro}} </code> chacun

						<br />
						Total : <b class="red--text"
							 v-html="monetary_aspect(quantity * price)" /> <code>{{asset_code_fceuro}} </code>

						<p class="caption mt-5 sucess--text">
							Après cette transaction vous aurez un crédit de : <b class="red--text">{{credit}}</b> <code>{{asset_code}}</code>
							<br /> Vous recevrez une recompense de : <b class="red--text">{{reward}}</b> <code>{{asset_code}}</code>
							<br /> Soit un crédit total de : <b class="red--text">{{total}}</b> <code>{{asset_code}}</code>
							<br /> Au cours de référence du jour un crédit total de <b class="red--text">{{totaleuro}}</b> <code>{{asset_code_fceuro}} </code>
							<br /> Soit un gain de : <b class="success--text">{{win}}</b> <code>{{asset_code_fceuro}} </code>
						</p>
					</v-card-text>
				</v-card>

				<validbutton :etat_transaction="etat_transaction"
										 :error_why="error_why"
										 :progress="progress"
										 @valid="send_transaction" />


			</v-col>

		</v-row>
	</v-card>
</v-dialog>
</template>


<script>
var StellarSdk = require('stellar-sdk');
import validbutton from '@/components/valid_button.vue'


export default {
	name: 'stellar_buy_term',
	components: {
		validbutton
	},
	props: {
		css: String,
		small: String,

		label: {
			type: String,
			default: "buy"
		},

		asset_code: String,
		asset_issuer: String,

		asset_limit: String,


		id_transaction: {
			type: String,
			default: "0"
		},

		unit_price: {
			type: String,
			default: "0"
		},

		amount: {
			type: String,
			default: "0"
		},
		automatic: {
			type: Boolean,
			default: false
		},
		block: {
			type: Boolean,
			default: false
		},
		term: []
	},
	data: () => ({
		label_secret: "Saisir votre clé secréte",


		from: '',
		dialog: false,
		quantity: undefined,
		to: undefined,
		secret_key: '',
		etat_transaction: undefined,
		error_why: undefined,
		progress: false,
		price: '0',
		ref_transaction: 0,
		transaction: undefined,
		credit: 0,
		reward: 0,
		total: 0,
		totaleuro: 0,
		win: 0,
		field_euro: 0,
		contrat: 5,
		otp: "",
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {

		if (this.id_transaction != undefined) this.ref_transaction = this.id_transaction;
		if (this.unit_price != undefined) this.price = this.unit_price;
		if (this.amount != undefined) this.quantity = this.amount;





	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {

		asset_issuer_fceuro() {
			return this.$store.getters.master_account;
		},
		privatekey() {
			return this.$store.state.privatekey
		},
		pin() {
			return this.$store.state.pin
		},
		blockchain() {
			return this.$store.state.blockchain
		},
		solde_fceuro() {
			return this.$store.state.solde_fceuro
		},
		asset_code_fceuro() {
			return this.$store.state.asset_code_fceuro
		},
	},
	methods: {
		onFinish() {
			if (this.pin == this.hash_key(this.otp)) this.secret_key = this.decrypt_key(this.otp);
		},
		convert_euro: function () {
			//  this.field_euro = Number(this.quantity)* Number(this.berlcoin_garanty);
			this.field_euro = Number(this.quantity) * Number(this.price);
			this.verif_nb_berlcoin();
		},
		convert_berlcoin: function () {
			this.quantity = Number(this.field_euro) / Number(this.price);
			this.verif_nb_berlcoin();
		},
		verif_nb_berlcoin: function () {
			this.quantity = (Math.round(this.quantity * 100000) / 100000).toFixed(5);
			this.field_euro = (Math.round(this.field_euro * 100) / 100).toFixed(2);
			let taux = 1;

			//console.log(this.term)
			for (let i = 0; i < this.term.length; i++)

				if (this.contrat == this.term[i].an) taux = this.term[i].staking;


			this.credit = this.quantity;
			this.reward = (Number(this.quantity) * taux / 100).toFixed(2);
			this.total = (Number(this.reward) + Number(this.quantity)).toFixed(2);
			this.totaleuro = (Number(this.total) * Number(this.price)).toFixed(2);
			this.win = (Number(this.reward) * Number(this.price)).toFixed(2);

		},
		activate: function () {
			this.dialog = true;
		},
		send_transaction: function (key) {
			this.secret_key = key;
			//console.log("send_transaction");
			this.progress = true;
			this.etat_transaction = "";
			try {
				//console.log("start");
				var asset_issuer_fceuro_key = StellarSdk.Keypair.fromPublicKey(
					this.asset_issuer_fceuro,
				);
				//console.log(asset_issuer_fceuro_key);
				var euro = new StellarSdk.Asset(this.asset_code_fceuro, asset_issuer_fceuro_key.publicKey());
				//console.log(euro);
				// ---------- PARAMETRE DE LA TRANSACTION

				var source = StellarSdk.Keypair.fromSecret(this.secret_key);
				//console.log(source);
				// ---------- PARAMETRE DE LA TRANSACTION

				const nombre = (parseInt(this.quantity * this.price * 100000) / 100000).toString();
				//console.log(nombre)
				// ---------- SECURITE

				this.secret_key = "";
				//console.log(nombre);
				// --------- INITIAlISATION

				let claimkey = this.$store.getters.claim_account;
				//console.log(claimkey);
				let terme = Math.ceil((Date.now() / 1000)); // .now() is in ms
				let bCanClaim = StellarSdk.Claimant.predicateNot(StellarSdk.Claimant.predicateBeforeAbsoluteTime(terme.toString()));

				let operations = [];
				operations.push(StellarSdk.Operation.createClaimableBalance({
					claimants: [new StellarSdk.Claimant(claimkey, bCanClaim)],
					asset: euro,
					amount: nombre,
				}))
				//console.log(operations);
				//console.log("Nombre d'operation = " + operations.length);
				//	if (operations.length == 0)console.log("Pas d'operation")

				let memo = (parseInt(this.contrat) * 12).toString() + "TC" + this.asset_code

				//console.log(memo);
				let params = {
					"source": source,
					"operations": operations,
					"memo": memo
				}

				this.$store.dispatch('stellar_transaction_sign_source', params).then(response => {
					if (response != undefined) this.transaction = response;
					this.progress = false;
					if (response.toString().substr(0, 5) == "Error") {
						this.progress = false;
						this.etat_transaction = "Echec de la transaction";
						if (response != undefined) this.error_why = response.toString().substr(6)
					} else {
						this.etat_transaction = "success";
						this.dialog = false;
					}
				})

			} catch (error) {
				this.etat_transaction = "Rejet de la transaction par le Wallet: " + error.toString().replace("TypeError: ", '');
				if (error.toString().replace("TypeError: ", '') == "Error: invalid version byte. expected 144, got undefined")
					this.etat_transaction = "Rejet par le Wallet: code secret vide ou code pin faux ou code de double vérification faux";
				this.error_data = error;
				//console.log(error)
				this.progress = false;
			}
		}
	},
	watch: {
		unit_price: function () {
			if (this.unit_price != undefined) this.price = this.unit_price;
		},
		amount: function () {
			if (this.amount != undefined) this.quantity = this.amount;
		},
		field_euro: function () {
			this.field_euro = this.field_euro.replace(',', '.');

		},
		quantity: function () {
			this.quantity = this.quantity.replace(',', '.');

		},
		dialog: function () {
			if (!this.dialog) {
				this.$emit('close', this.etat_transaction)
				this.etat_transaction = undefined
				this.error_data = undefined
				this.error_why = ""
				this.transaction = undefined
			}
		},

		contrat() {
			this.verif_nb_berlcoin()
		},
	}
}
</script>

<style scoped>
</style>

<template>
<div>
	<v-alert class="pa-1"
					 icon="mdi-clipboard-text"
					 color="teal lighten-4"
					 @click="close('success')">Staking </v-alert>



	<v-card-title class="teal lighten-5">Vos liquidités <v-spacer></v-spacer>
		<b class="justify-end success--text"
			 v-html="monetary_aspect(solde_fceuro)+' €'" />
	</v-card-title>

	<v-row no-gutters>
		<v-col cols=12>
			<listbalance :unit_only="true"
									 :short="true"
									 color="green darken-4"
									 filter="XLM fcEURO"
									 :show_nft=false
									 ref="balance"
									 :label="label_token_master"
									 :select_key="distributor_account"
									 @click_asset="click_asset" />
		</v-col>
	</v-row>



	<v-bottom-sheet v-model="sheet">
		<v-container style="max-width:1280px"
								 class="mx-auto  white">

			<v-row no-gutters>
				<v-col cols=12
							 class="pa-3">
					Acheter ou placer à terme : &nbsp; <b> {{asset_code}}</b>
				</v-col>
				<v-col cols=12
							 xl=6
							 lg=6
							 md=6
							 sm=6
							 class="pa-3">



					<b class="red--text"
						 v-if='term_contrat.length==0'> Aucun contrat à terme disponible </b>
					<stellarbuyterm css=" "
													v-if='term_contrat.length > 0'
													:term="term_contrat"
													:block="true"
													label="Ordre d'achat à terme"
													:asset_code="asset_code"
													:asset_issuer="asset_issuer"
													ref="buy"
													:asset_limit="limit_asset"
													@close="close"
													:id_transaction="id_transaction.toString()"
													:unit_price="unit_price.toString()"
													:amount="amount.toString()" />

					<br />
					<stellarplaceterm css=" "
														v-if='term_contrat.length > 0 && asset_balance > 0'
														:term="term_contrat"
														:block="true"
														label="Placement à terme"
														:asset_code="asset_code"
														:asset_balance="asset_balance"
														:asset_issuer="asset_issuer"
														ref="buy"
														:asset_limit="limit_asset"
														@close="close"
														:id_transaction="id_transaction.toString()"
														:unit_price="unit_price.toString()"
														:amount="amount.toString()" />
				</v-col>

				<v-col cols=12
							 xl=6
							 v-if='term_contrat.length > 0'
							 lg=6
							 md=6
							 sm=6
							 class="pa-3"> {{ (info)}}
				</v-col>

			</v-row>
		</v-container>
	</v-bottom-sheet>

</div>
</template>


<script>
import listbalance from '../components/stellar_list_balance.vue'
import stellarbuyterm from "@/components/stellar_buy_term.vue";
import stellarplaceterm from "@/components/stellar_place_term.vue";

export default {
	name: 'achat',
	components: {
		listbalance,
		stellarbuyterm,
		stellarplaceterm
	},
	props: {},
	data: () => ({
		sheet: false,
		tiles: [{
				img: 'keep.png',
				title: 'Keep'
			},
			{
				img: 'inbox.png',
				title: 'Inbox'
			},
			{
				img: 'hangouts.png',
				title: 'Hangouts'
			},
			{
				img: 'messenger.png',
				title: 'Messenger'
			},
			{
				img: 'google.png',
				title: 'Google+'
			},
		],
		info: "",
		term_contrat: [],
		balance_asset: undefined,
		asset_code: undefined,
		asset_issuer: undefined,
		limit_asset: undefined,
		asset_balance: undefined,
		unit_price: 0,
		amount: 1,
		label_token_master: 'Liste des tokens disponibles',
		id_transaction: "0",
		destination_asset: undefined
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {

	},
	mounted: function () {

	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		publickey() {
			return this.$store.state.publickey
		},
		total_wallet() {
			return this.$store.state.total_wallet
		},
		solde_fceuro() {
			return this.$store.state.solde_fceuro
		},
		distributor_account() {
			return this.$store.getters.distributor_account
		},
		data_master_account() {
			return this.$store.state.data_master_account
		},
	},
	methods: {
		close(etat) {
			this.sheet = false;
			if (etat == "success") this.$emit('changepage', "dashboard")
		},
		click_asset(item) {
			this.id_transaction = 0
			this.asset_code = item.asset_code;
			this.asset_issuer = item.asset_issuer;
			this.limit_asset = item.limit;
			this.unit_price = Math.ceil(parseFloat(item.unitfceuro) * 100) / 100;
			this.asset_balance = this.$store.getters.balance_coin("my", item.asset_code);
			//  this.$refs.buy.activate();
			this.sheet = true
			this.balance_asset = item.balance;
			this.destination_asset = {
				"balance_asset": this.asset_balance,
				"asset_code": item.asset_code,
				"asset_issuer": item.asset_issuer,
				"asset_limit": item.limit,
				"unit_price": this.unit_price
			}

			this.info = "";
			this.term_contrat = [];
			let temp = this.data_master_account[item.asset_code + '_term_contract'];
			if (temp != undefined) {
				temp = this.b64(temp);
				const words = temp.split('#');
				this.term_contrat.push({
					"an": 1,
					"label": "1 an",
					"staking": words[0]
				});
				this.term_contrat.push({
					"an": 2,
					"label": "2 ans",
					"staking": words[1]
				});
				this.term_contrat.push({
					"an": 3,
					"label": "3 ans",
					"staking": words[2]
				});
				this.term_contrat.push({
					"an": 4,
					"label": "4 ans",
					"staking": words[3]
				});
				this.term_contrat.push({
					"an": 5,
					"label": "5 ans",
					"staking": words[4]
				});
				this.term_contrat.push({
					"an": 6,
					"label": "6 ans",
					"staking": words[5]
				});
				this.term_contrat.push({
					"an": 7,
					"label": "7 ans",
					"staking": words[6]
				});
				this.term_contrat.push({
					"an": 8,
					"label": "8 ans",
					"staking": words[7]
				});
				this.term_contrat.push({
					"an": 9,
					"label": "9 ans",
					"staking": words[8]
				});
				this.term_contrat.push({
					"an": 10,
					"label": "10 ans",
					"staking": words[9]
				});
				this.info = '';
				if (words[0] != 0) this.info += ", 1 an  " + words[0] + "%";
				if (words[1] != 0) this.info += ", 2 ans  " + words[1] + "%";
				if (words[2] != 0) this.info += ", 3 ans  " + words[2] + "%";
				if (words[3] != 0) this.info += ", 4 ans  " + words[3] + "%";
				if (words[4] != 0) this.info += ", 5 ans  " + words[4] + "%";
				if (words[5] != 0) this.info += ", 6 ans  " + words[5] + "%";
				if (words[6] != 0) this.info += ", 7 ans  " + words[6] + "%";
				if (words[7] != 0) this.info += ", 8 ans  " + words[7] + "%";
				if (words[8] != 0) this.info += ", 9 ans  " + words[8] + "%";
				if (words[9] != 0) this.info += ", 10 ans  " + words[9] + "%";
				this.info = this.info.substr(2);

			}
		},

		click_order(item) {

			this.id_transaction = item.id
			this.asset_code = item.buying.asset_code;
			this.asset_issuer = item.buying.asset_issuer;
			this.limit_asset = item.limit;
			this.unit_price = item.price_r.d;
			this.amount = (parseFloat(item.amount) / parseFloat(item.price_r.d));

			this.$refs.buy.activate();
		}
	},
	watch: {}
}
</script>

<style scoped>
</style>

<template>
<div>
	<h1 class=" mx-auto v-heading h3 text-h3 text-sm-h3 mb-4"> Wallet </h1>

	<p class="subtitle-1 mx-auto">L'app officielle de l'écosystème Fraction.re</p>
	<div class="text-center mt-3">
		<v-btn v-for="file in files"
					 :key="file.title"
					 :color="file.color"
					 @click="change_page(file.go)"
					 class="ma-5"
					 fab
					 dark
					 small>
			<v-icon dark>
				{{file.icon}}
			</v-icon>
		</v-btn>
	</div>


</div>
</template>


<script>
export default {
	name: 'welcome',
	components: {},
	props: {},
	data: () => ({
		files: [{
				color: 'orange',
				icon: 'mdi-eye-circle-outline',
				title: 'Dashboard',
				subtitle: 'Souscrire et recevoir',
				go: 'dashboard'
			},
			{
				color: 'red',
				icon: 'mdi-clipboard-text',
				title: 'Contrat à terme',
				subtitle: 'Souscrire et recevoir',
				go: 'contrat'
			},
			{
				color: 'blue',
				icon: 'mdi-bank-transfer',
				title: 'Achat',
				subtitle: 'Acheter des tokens',
				go: 'achat'
			},
			{
				color: 'amber',
				icon: 'mdi-finance',
				title: 'Vente ',
				subtitle: 'Vendre des tokens',
				go: 'vente'
			},
			{
				color: 'green',
				icon: 'mdi-transfer',
				title: 'Transférer & payer',
				subtitle: 'Transfert et paiement',
				go: 'transfert'
			},

			{
				color: 'purple',
				icon: 'mdi-chart-line-variant',
				title: 'Pool de liquidité',
				subtitle: 'Gérer vos pools de liquidité',
				go: 'pool'
			},

			{
				color: 'brown',
				icon: "mdi-format-list-numbered-rtl",
				title: 'Historique',
				subtitle: 'Les transactions sur la blockchain',
				go: 'explorer'
			},
			{
				color: 'black',
				icon: 'mdi-account',
				title: 'Compte',
				subtitle: 'Clés et déconnection',
				go: 'account'
			},
		],
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {
		change_page(page, params = []) {
			this.$emit('changepage', page, params)
		},
	},
	watch: {}
}
</script>

<style scoped>
</style>

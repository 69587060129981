<template>
<div>

	<h4 class="ma-6">Signer le contrat dans la blockchain</h4>

	<div v-if="publickey == $route.params.key">
		<div class="mx-auto mb-8"
				 style="text-align:center;">



			<a target="_blank"
				 :href="'https://contrats.fraction.re/'+$route.params.id+$route.params.key+'.pdf'">
				<v-btn class="mx-2"
							 fab
							 dark
							 large
							 color="purple">
					<v-icon dark>
						mdi-file
					</v-icon>
				</v-btn>
			</a>
			Contrat: <b>{{$route.params.code}} </b>


		</div>
		<validbutton :etat_transaction="etat_transaction"
								 :error_why="error_why"
								 :progress="progress"
								 @valid="send_transaction" />


	</div>
	<br />
	<i class="font-size:70%">Cette signature coûte un Lumen (XLM)</i>

	<div v-if="publickey != $route.params.key"><br /> Votre Wallet n'est pas connecté avec la même clé publique que le contrat. <br />Déconnectez vous et reconnectez-vous avec la clé :
		<span style="font-size:80%"> {{$route.params.key.substr(0,35)}}...</span>
		<br /><br /> <br />
		<hr class="mb-6" />
		<div class="mx-auto"
				 style="text-align:center;">
			<v-btn class="mx-auto"
						 @click="logout()"
						 fab
						 dark
						 large
						 color="red">
				<v-icon dark>
					mdi-power
				</v-icon>
			</v-btn>
		</div>
	</div>







</div>
</template>


<script>
import validbutton from '@/components/valid_button.vue'
var StellarSdk = require('stellar-sdk');

export default {
	name: 'kyc',
	components: {

		validbutton
	},
	props: {},
	data: () => ({
		secret_key: '',
		etat_transaction: undefined,
		error_data: undefined,
		error_why: undefined,
		transaction: undefined,
		progress: false,
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {

		publickey() {
			return this.$store.state.publickey
		},
	},
	methods: {
		logout() {
			window.localStorage.clear('start');
			this.$store.commit("update_access", false);
		},
		send_transaction: function (key) {
			this.secret_key = key;
			this.progress = true;
			this.etat_transaction = "";
			try {

				let operations = [];
				let destination_keys = StellarSdk.Keypair.fromPublicKey(this.$store.getters.distributor_account);

				operations.push(
					StellarSdk.Operation.payment({
						destination: destination_keys.publicKey(),
						asset: StellarSdk.Asset.native(),
						amount: "1",
					})
				)

				let params = {
					"source": StellarSdk.Keypair.fromSecret(this.secret_key),
					"operations": operations,
					"memo": "Sign " + this.$route.params.code
				}

				this.secret_key = "";

				//console.log("id", id)
				this.$store.dispatch('stellar_transaction_sign_source', params).then(response => {
					if (response != undefined) this.transaction = response;
					this.progress = false;
					if (response.toString().substr(0, 5) == "Error") {
						this.progress = false;
						this.etat_transaction = "Echec de la transaction";
						if (response != undefined) this.error_why = response.toString().substr(6)
					} else {
						this.etat_transaction = "success";
						this.dialog = false;
					}
				})

			} catch (error) {
				this.etat_transaction = "Rejet de la transaction par le Wallet: " + error.toString().replace("TypeError: ", '');
				if (error.toString().replace("TypeError: ", '') == "Error: invalid version byte. expected 144, got undefined")
					this.etat_transaction = "Rejet par le Wallet: code secret vide ou code pin faux ou code de double vérification faux";
				this.error_data = error;
				//console.log(error)
				this.progress = false;
			}

		},
	},
	watch: {

	}
}
</script>

<style scoped>
</style>

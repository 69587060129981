<template>
<div>

	<v-card-title class="body-1"
								v-html="label">
		<v-spacer></v-spacer>

	</v-card-title>
	<v-divider class="mb-3 mt-n3 success"></v-divider>

	<v-row v-if='balances !=undefined'>
		<template v-for="(item, index) in balances">
			<v-col cols=12
						 xl=4
						 lg=4
						 md=4
						 sm=6
						 :key="'c'+index"
						 v-if=" item.buying.asset_code =='fcEURO'">
				<v-card :color="color"
								dark
								@click="click_asset( item)">
					<v-card-title class="">

						<b v-html="monetary_aspect(item.amount  )"
							 class="primary px-3" />
						<span v-html="item.selling.asset_type == 'native' ? 'XLM' :item.selling.asset_code" />


						<v-spacer></v-spacer>
						<span v-html=" monetary_aspect(item.price  )"> </span>
					</v-card-title>
					<v-card-subtitle>

						by {{ item.selling.asset_type == 'native' ? 'Stellar Foundation' : account_name(item.selling.asset_issuer,false)}}

						<b style="float:right"
							 v-html="' Total :   '+ monetary_aspect(item.amount*item.price  )+''" />
					</v-card-subtitle>
					<v-card-text class="text-h6">
					</v-card-text>
				</v-card>
			</v-col>



		</template>
	</v-row>
</div>
</template>



<script>
export default {
	name: 'stellar_list_sell_order',
	components: {},
	props: {
		label: {
			type: String,
			default: "Ordres de vente en attente"
		},
		color: {
			type: String,
			default: "green darken-3"
		},
	},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		balances() {
			return this.$store.state.balances_operation_offer;
		}
	},
	methods: {
		click_asset(item) {
			this.$emit("click_asset", item)
		}
	},
	watch: {}
}
</script>

<style scoped>
</style>

<template>
<div>
	<v-chip v-if="show_account==true"
					class="mb-3"
					color="teal darken-2"
					label
					text-color="white"> {{select_key }} </v-chip>
	<v-chip v-if="show_account==true"
					class="mb-3 ml-3"
					color="teal darken-2"
					label
					text-color="white"> {{ account_name(select_key,false) }} </v-chip>

	<v-card-title class="body-1">
		{{label}}
		<v-spacer></v-spacer>
		<span v-if="(this.publickey == this.select_key && show_total==true)"
					class="justify-end success--text"
					v-html="' <b>'+monetary_aspect(total_assets_in_euro)+'</b>  €' ">
		</span>
	</v-card-title>
	<v-divider class="mb-3 mt-n3 success"></v-divider>
	<v-row v-if='balances !=undefined'>
		<template v-for="(item, index) in balances">
			<v-col cols=12
						 xl=4
						 lg=4
						 md=4
						 sm=6
						 :key="'c'+index"
						 v-if="test_affiche(item)">
				<v-card :color="color"
								dark
								@click="click_asset( item)">
					<v-card-title class="short== true ? 'pa-2' : '' ">
						<span class="">
							<b v-if='!unit_only'
								 v-html="monetary_aspect(item.balance )"
								 class="primary px-3" />
							{{item.asset_type == 'native' ? 'XLM' : ( item.asset_type == 'liquidity_pool_shares' ? 'Pool' : item.asset_code )  }} </span>
						<v-spacer></v-spacer>
						<span v-html=" unit_only ?  monetary_aspect(item.unitfceuro ) :  monetary_aspect(item.fceuro )"> </span>
					</v-card-title>
					<v-card-subtitle v-if="short==false">
						<span class="body-2 green mr-2 px-3"
									v-if="short==false">
							{{ (item.asset_type == 'native' || parseInt(item.limit) > 1000000) ? '∞' : parseInt(item.limit)}}
						</span>
						by {{ item.asset_type == 'native' ? 'Stellar Foundation' : account_name(item.asset_issuer,false)}}

						<b style="float:right"
							 v-html="select_key != $store.getters.sequestre_account  ? monetary_aspect(item.unitfceuro ) : (item.asset_trust== undefined ? '' : monetary_aspect(item.amount_trust) + ' '+ item.asset_trust)  " />
					</v-card-subtitle>
					<v-card-text class="text-h6">
					</v-card-text>
				</v-card>
			</v-col>
		</template>
	</v-row>
</div>
</template>


<script>
export default {
	name: 'stellar_list_balance',
	components: {},
	props: {
		select_key: String,

		filter: {
			type: String,
			default: ""
		},
		show_account: {
			type: Boolean,
			default: false
		},
		show_nft: {
			type: Boolean,
			default: true
		},
		show_total: {
			type: Boolean,
			default: true
		},
		unit_only: {
			type: Boolean,
			default: false
		},
		color: {
			type: String,
			default: "green darken-1"
		},
		short: {
			type: Boolean,
			default: false
		},
		label: {
			type: String,
			default: 'Valeurs des tokens'
		}
	},
	data: () => ({
		asset_index: [],
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {

		balances() {
			//console.log(this.select_key)
			if (this.select_key == this.publickey) return this.$store.state.balances
			if (this.select_key == this.$store.getters.master_account) return this.$store.state.balances_master_account;
			if (this.select_key == this.$store.getters.distributor_account) return this.$store.state.balances_distributor_account;
			if (this.select_key == this.$store.getters.sequestre_account) return this.$store.state.balances_sequestre;

		},
		total_assets_in_euro() {
			return this.$store.state.total_assets_in_euro
		},
		publickey() {
			return this.$store.state.publickey
		},

	},
	methods: {

		test_affiche(item) {
			let retour = true;
			let filter = " " + this.filter + " ";
			if (item.asset_type == 'native') item.asset_code = "XLM";
			filter.includes(" " + item.asset_code + " ") ? retour = false : retour = true;
			if (item.asset_type == 'liquidity_pool_shares') retour = false;
			if ((this.show_nft == false) && (item.limit == 1)) retour = false;
			return retour;
		},
		click_asset(item) {
			this.$emit("click_asset", item)
		}
	},
	watch: {
		select_key() {

		}
	}
}
</script>

<style scoped>
</style>

<template>
<v-dialog v-model="dialog"
					fullscreen
					hide-overlay
					transition="dialog-bottom-transition">
	<template v-slot:activator="{ on, attrs }">
		<v-btn class="px-5"
					 color="primary"
					 :block="block"
					 dark
					 v-bind="attrs"
					 :class="css"
					 v-on="on">
			{{label}}
		</v-btn>
	</template>
	<v-card>
		<v-toolbar dark
							 color="primary">
			<v-btn icon
						 dark
						 @click="dialog = false">
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<b class="text-h6"> {{label}} </b>
		</v-toolbar>

		<v-row class="mx-1 mt-2">
			<v-col cols=12>
				<v-card color="green darken-4"
								dark>
					<v-card-title>
						<span class="text-h6  t font-weight-light"> <b class="primary px-4 mt-n6 text-h5"
								 v-html="monetary_aspect(asset.balance)" /> {{ asset.asset_code}} </span>
						<v-spacer></v-spacer> <span class="body-1 green mt-n8 mr-n3 px-3"> {{ ( parseInt(asset.limit) > 1000000) ? '∞' : parseInt(asset.limit)}}</span>
					</v-card-title>
					<v-card-subtitle>
						by {{ account_name(asset.asset_issuer,false)}}
					</v-card-subtitle>
				</v-card>
			</v-col>

			<v-col cols=12>
				<v-card elevation=0>

					<v-card-text class="text-subtitle-1">
						{{label}} &nbsp;
						<span>{{asset.asset_code}} by
							{{account_name(asset.asset_issuer,false)}}

						</span>
					</v-card-text>
				</v-card>

				<validbutton :etat_transaction="etat_transaction"
										 :error_why="error_why"
										 :progress="progress"
										 @valid="send_transaction" />


			</v-col>
		</v-row>

	</v-card>
</v-dialog>
</template>


<script>
var StellarSdk = require('stellar-sdk');
import validbutton from '@/components/valid_button.vue'


export default {
	name: 'stellar_transfert',
	components: {
		validbutton
	},
	props: {
		css: String,
		label: String,
		asset: [],
		block: {
			type: Boolean,
			default: false
		},

	},
	data: () => ({

		dialog: false,
		label_secret: "Saisir votre clé secréte",
		secret_key: '',
		etat_transaction: undefined,
		error_software: undefined,
		error_why: undefined,
		transaction: undefined,
		progress: false,
		otp: "",
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		blockchain() {
			return this.$store.state.blockchain
		},
		pin() {
			return this.$store.state.pin
		},
		privatekey() {
			return this.$store.state.privatekey
		},
		balances() {
			return this.$store.state.balances
		},
	},
	methods: {
		onFinish() {
			if (this.pin == this.hash_key(this.otp)) this.secret_key = this.decrypt_key(this.otp);
		},

		send_transaction: function (key) {
			this.secret_key = key;
			this.progress = true;
			this.etat_transaction = "";
			try {

				let asset = new StellarSdk.Asset(this.asset.asset_code, this.asset.asset_issuer);
				let asset_trust = new StellarSdk.Asset(this.asset.asset_trust, this.asset.asset_trust_issuer);
				let origine_keys = StellarSdk.Keypair.fromSecret(this.secret_key);
				let destination_keys = StellarSdk.Keypair.fromPublicKey(this.$store.getters.sequestre_account);
				let balance = this.asset.balance.toString();
				let bCanClaim = StellarSdk.Claimant.predicateNot(StellarSdk.Claimant.predicateBeforeAbsoluteTime(Math.ceil(Date.now() / 1000).toString()));

				let operations = [];

				operations.push(
					StellarSdk.Operation.createClaimableBalance({
						claimants: [new StellarSdk.Claimant(destination_keys.publicKey(), bCanClaim)],
						asset: asset,
						amount: balance,
					})
				)

				operations.push(
					StellarSdk.Operation.payment({
						destination: destination_keys.publicKey(),
						asset: StellarSdk.Asset.native(),
						amount: "1",
					})
				)

				operations.push(
					StellarSdk.Operation.changeTrust({
						asset: asset,
						limit: "0",
					})
				)

				let add_trust = true;

				this.balances.forEach((item, i) => {
					if ((item.asset_code == this.asset.asset_trust) && (item.asset_issuer == this.asset.asset_trust_issuer))
						add_trust = false;
				});

				if (add_trust)
					operations.push(
						StellarSdk.Operation.changeTrust({
							asset: asset_trust,
							limit: "922337203685",
						})
					)

				let params = {
					"source": origine_keys,
					"operations": operations,
					"memo": "Fraction.re Recover Escrow"
				}
				//console.log(params)
				this.$store.dispatch('stellar_transaction_sign_source', params).then(response => {
					if (response != undefined) this.transaction = response;
					this.progress = false;
					if (response.toString().substr(0, 5) == "Error") {
						this.progress = false;
						this.etat_transaction = "Echec de la transaction";
						if (response != undefined) this.error_why = response.toString().substr(6)
					} else {
						this.etat_transaction = "success";
						this.dialog = false;
					}
				})

			} catch (error) {
				this.etat_transaction = "Rejet de la transaction par le Wallet: " + error.toString().replace("TypeError: ", '');
				if (error.toString().replace("TypeError: ", '') == "Error: invalid version byte. expected 144, got undefined")
					this.etat_transaction = "Rejet par le Wallet: code secret vide ou code pin faux ou code de double vérification faux";
				this.error_data = error;
				//console.log(error)
				this.progress = false;
			}
		}
	},
	watch: {

		dialog: function () {
			if (!this.dialog) {
				this.$emit('close', this.etat_transaction)
				this.etat_transaction = undefined
				this.error_why = ""
				this.transaction = undefined
			}
		}

	}
}
</script>

<style scoped>
</style>

<template>
<v-dialog v-model="dialog"
					fullscreen
					hide-overlay
					transition="dialog-bottom-transition">
	<template v-slot:activator="{ on, attrs }">
		<v-btn small
					 class="px-5"
					 color="primary"
					 dark
					 v-bind="attrs"
					 :class="css"
					 v-on="on">
			{{label}}
		</v-btn>
	</template>
	<v-card>
		<v-toolbar dark
							 color="primary">
			<v-btn icon
						 dark
						 @click="dialog = false">
				<v-icon>mdi-close</v-icon>
			</v-btn>

			<b class="text-h6"> Réclamer </b>

			<v-spacer></v-spacer>


		</v-toolbar>

		<v-row class="mt-5 mx-5">
			<v-col cols=12>
				<v-card color="green darken-4"
								dark>
					<v-card-title>
						<span class="text-h6  t font-weight-light"
									v-html="monetary_aspect(amount)+ ' '+asset_code">

						</span>
						<v-spacer> </v-spacer>


					</v-card-title>
					<v-card-subtitle>
						by {{ account_name(asset_issuer,false)}}
					</v-card-subtitle>
				</v-card>
			</v-col>

		</v-row>


		<v-row class="mx-5">
			<v-col cols=12>
				<v-card class="mx-auto"
								v-if="false">
					<v-card-title class="primary">
						<span class="white--text text-h6 font-weight-light">Claim balance </span>
					</v-card-title>

					<v-card-text class="pa-5 ">
						<br /><b>Id</b> : {{id}}
						<br /><b>Montant</b> : {{amount}}

						<br /><b>Date d'appel</b> : {{claimants}}
					</v-card-text>
				</v-card>

				<v-card class="mx-auto mt-5"
								elevation=0>

					<v-card-text class="pa-5 text-h5">


					</v-card-text>
				</v-card>

				<validbutton :etat_transaction="etat_transaction"
										 :error_why="error_why"
										 :progress="progress"
										 @valid="send_transaction" />


			</v-col>
		</v-row>
	</v-card>
</v-dialog>
</template>


<script>
var StellarSdk = require('stellar-sdk');
import validbutton from '@/components/valid_button.vue'


export default {
	name: 'stellar_claim_balance_claim',
	components: {
		validbutton
	},
	props: {
		css: String,
		label: String,
		asset_code: String,
		asset_issuer: String,
		id: String,
		account_sign: String,
		amount: String,
		last_modified_time: String,
		claimants: String,
	},
	data: () => ({
		label_secret: "Saisir votre clé secréte",
		dialog: false,
		secret_key: '',
		etat_transaction: undefined,
		error_data: undefined,
		error_extras: undefined,
		error_why: undefined,
		transaction: undefined,
		progress: false,
		envelope_xdr: undefined,
		result_xdr: undefined,
		result_meta_xdr: undefined,
		otp: "",
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {

	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		blockchain() {
			return this.$store.state.blockchain
		},
		pin() {
			return this.$store.state.pin
		},
		privatekey() {
			return this.$store.state.privatekey
		},
		balances() {
			return this.$store.state.balances
		},
	},
	methods: {

		onFinish() {
			if (this.pin == this.hash_key(this.otp)) this.secret_key = this.decrypt_key(this.otp);

		},
		send_transaction: function (key) {
			this.secret_key = key;
			//console.log("send_transaction");
			//console.log(this.id);
			this.progress = true;
			try {
				var origine_keys = StellarSdk.Keypair.fromSecret(this.secret_key);

				let operations = []
				let trust_exist = false;

				this.balances.forEach((item, i) => {
					if (item.asset_code == this.asset_code) trust_exist = true;
				});

				let asset = new StellarSdk.Asset(this.asset_code, this.$store.getters.sequestre_account);

				if (!trust_exist)
					operations.push(
						StellarSdk.Operation.changeTrust({
							asset: asset
						})
					)

				operations.push(StellarSdk.Operation.claimClaimableBalance({
					balanceId: this.id
				}));

				let params = {
					"source": origine_keys,
					"operations": operations,
					"memo": "Claim balance by Fraction.re"
				}

				this.$store.dispatch('stellar_transaction_sign_source', params).then(response => {
					if (response != undefined) this.transaction = response;
					this.progress = false;
					if (response.toString().substr(0, 5) == "Error") {
						this.progress = false;
						this.etat_transaction = "Echec de la transaction";
						if (response != undefined) this.error_why = response.toString().substr(6)
					} else {
						this.etat_transaction = "success";
						this.dialog = false;
					}
				})

			} catch (error) {
				this.etat_transaction = "Rejet de la transaction par le Wallet: " + error.toString().replace("TypeError: ", '');
				if (error.toString().replace("TypeError: ", '') == "Error: invalid version byte. expected 144, got undefined")
					this.etat_transaction = "Rejet par le Wallet: code secret vide ou code pin faux ou code de double vérification faux";
				this.error_data = error;
				//console.log(error)
				this.progress = false;
			}
		}
	},
	watch: {

		dialog: function () {
			if (!this.dialog) {
				this.$emit('close', this.etat_transaction)
				this.etat_transaction = undefined
				this.error_data = undefined
				this.error_extras = undefined
				this.error_why = undefined
				this.transaction = undefined
			}
		}
	}
}
</script>

<style scoped>
</style>

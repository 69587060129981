<template>
<div>
	<v-alert class="pa-1"
					 icon="mdi-chart-line-variant"
					 color="teal lighten-4"
					 @click="close('success')">Liquidity Pool </v-alert>
	<v-row no-gutters>
		<v-col cols=12>

			<listpool ref="pool"
								:select_key="distributor_account"
								@click_pool="click_pool_distributor" />


			<listpool ref="pool"
								:select_key="publickey"
								@click_pool="click_pool" />



		</v-col>
	</v-row>

	<v-bottom-sheet v-model="sheet">
		<v-container style="max-width:1280px"
								 class="mx-auto  white">
			<v-row no-gutters>
				<v-col cols=12
							 class="pa-3">
					<span v-if="one_pool_selected==true"> Pool de liquidité : &nbsp;
						<b> {{selected_pool.assets[0].asset_code}}</b> / <b> {{selected_pool.assets[1].asset_code}}</b>
					</span>
				</v-col>
				<v-col cols=12
							 xl=6
							 lg=6
							 md=6
							 sm=6
							 class="pa-3">
					<stellarliquiditypooldeposit :block="true"
																			 @close="close"
																			 css="my-3 mr-3 pa-6"
																			 label="Dépot   "
																			 :pool="selected_pool" />
				</v-col>
				<v-col cols=12
							 xl=6
							 lg=6
							 md=6
							 sm=6
							 class="pa-3">
					<stellarliquiditypoolwithdraw :block="true"
																				@close="close"
																				css="my-3 pa-6"
																				label="Retrait   "
																				:pool="selected_pool" />
				</v-col>
			</v-row>
		</v-container>
	</v-bottom-sheet>

	<v-bottom-sheet v-model="sheet2">
		<v-container style="max-width:1280px"
								 class="mx-auto  white">
			<v-row no-gutters>
				<v-col cols=12
							 class="pa-3">
					<span v-if="one_pool_selected==true"> Pool de liquidité : &nbsp;
						<b> {{selected_pool.assets[0].asset_code}}</b> / <b> {{selected_pool.assets[1].asset_code}}</b>
					</span>
				</v-col>
				<v-col cols=12
							 xl=6
							 lg=6
							 md=6
							 sm=6
							 class="pa-3">
					<stellarmanageliquidypooltrustline :block="true"
																						 @close="close"
																						 css="my-3 mr-3 pa-6"
																						 label="Intégrer ce Liquidity Pool   "
																						 :pool="selected_pool" />
				</v-col>

			</v-row>
		</v-container>
	</v-bottom-sheet>



</div>
</template>
<script>
import stellarliquiditypooldeposit from "@/components/stellar_liquidity_pool_deposit.vue";
import stellarliquiditypoolwithdraw from "@/components/stellar_liquidity_pool_withdraw.vue";
import stellarmanageliquidypooltrustline from "@/components/stellar_manage_liquidy_pool_trustline.vue";

import listpool from '../components/stellar_list_liquidity_pool.vue'
export default {
	name: 'pool',
	components: {
		listpool,
		stellarliquiditypooldeposit,
		stellarliquiditypoolwithdraw,
		stellarmanageliquidypooltrustline
	},
	props: {},
	data: () => ({
		sheet: false,
		sheet2: false,
		selected_pool: [],
		one_pool_selected: false
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		publickey() {
			return this.$store.state.publickey
		},
		distributor_account() {
			return this.$store.getters.distributor_account
		},
	},
	methods: {
		close(etat) {
			if (etat == "success") this.$emit('changepage', "dashboard")
		},
		click_pool(item) {
			this.sheet = true;
			this.selected_pool = item;
			this.one_pool_selected = true;
		},
		click_pool_distributor(item) {
			this.sheet2 = true;
			this.selected_pool = item;
			this.one_pool_selected = true;
		},
	},
	watch: {}
}
</script>

<style scoped>
</style>

<template>
<v-dialog v-model="dialog"
					fullscreen
					hide-overlay
					transition="dialog-bottom-transition">
	<template v-slot:activator="{ on, attrs }">
		<v-btn class="px-5"
					 color="primary"
					 dark
					 v-bind="attrs"
					 v-on="on"
					 :class="css"
					 :block="block"
					 :small="small">
			{{label}}
		</v-btn>
	</template>
	<v-card>
		<v-toolbar dark
							 color="primary">
			<v-btn icon
						 dark
						 @click="dialog = false">
				<v-icon>mdi-close</v-icon>
			</v-btn> <b class="text-h6"> Acheter {{asset_code}} </b>
			<v-spacer></v-spacer>
			<span v-html="monetary_aspect(unit_price)+' €'" />
		</v-toolbar>
		<v-card-title class="teal lighten-5">Vos liquidités &nbsp; <v-spacer></v-spacer>
			<b class="justify-end success--text"
				 v-html="monetary_aspect(solde_fceuro)+' €'" />
		</v-card-title>
		<v-row class="mx-1 mt-2">
			<v-col cols=12>
				<v-card elevation=0>
					<div class="ma-1 pb-0">
						<v-text-field class="my-5"
													v-model="quantity"
													label="Quantité"></v-text-field>
						<v-text-field class="my-5"
													v-model="price"
													label="Prix unitaire"></v-text-field>
					</div>
				</v-card>
				<v-card elevation=0>
					<v-card-text class="subtitle-1">
						<div v-if="id_transaction > 0">
							<b class="py-1 outlined"> Modification de l'ordre numéro : {{id_transaction}} </b>
							<br /><br />
						</div>
						Acheter <b class="red--text"
							 v-html="monetary_aspect(quantity)" /> <code>{{asset_code}}</code>
						<br /> à <b class="red--text"
							 v-html="monetary_aspect(price)" /> <code>{{asset_code_fceuro}} </code> chacun
						<br />
						<br />
						Total : <b class="red--text"
							 v-html="monetary_aspect(quantity * price)" /> <code>{{asset_code_fceuro}} </code>
					</v-card-text>
				</v-card>

				<validbutton :etat_transaction="etat_transaction"
										 :error_why="error_why"
										 :progress="progress"
										 @valid="send_transaction" />
			</v-col>
		</v-row>
	</v-card>
</v-dialog>
</template>


<script>
var StellarSdk = require('stellar-sdk');
import validbutton from '@/components/valid_button.vue'


export default {
	name: 'stellar_buy',
	components: {
		validbutton
	},
	props: {
		css: String,
		small: String,

		label: {
			type: String,
			default: "buy"
		},

		asset_code: String,
		asset_issuer: String,

		asset_limit: String,


		id_transaction: {
			type: String,
			default: "0"
		},

		unit_price: {
			type: String,
			default: "0"
		},

		amount: {
			type: String,
			default: "1"
		},
		automatic: {
			type: Boolean,
			default: false
		},
		block: {
			type: Boolean,
			default: false
		}

	},
	data: () => ({
		label_secret: "Saisir votre clé secréte",

		asset_issuer_fceuro: "",
		from: '',
		dialog: false,
		quantity: undefined,
		to: undefined,
		secret_key: '',
		etat_transaction: undefined,
		error_why: undefined,
		progress: false,
		price: '0',
		ref_transaction: 0,
		transaction: undefined,
		otp: "",
		otp2: "",
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {

		if (this.id_transaction != "0") {
			this.price = "0";
			this.quantity = "0";
		} else {
			if (this.id_transaction != undefined) this.ref_transaction = this.id_transaction;
			if (this.unit_price != undefined) this.price = this.unit_price;
			if (this.amount != undefined) this.quantity = this.amount;
		}
		this.asset_issuer_fceuro = this.$store.getters.master_account;

	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {

		blockchain() {
			return this.$store.state.blockchain
		},
		solde_fceuro() {
			return this.$store.state.solde_fceuro
		},
		asset_code_fceuro() {
			return this.$store.state.asset_code_fceuro
		},
		privatekey() {
			return this.$store.state.privatekey
		},

	},
	methods: {

		activate: function () {
			this.dialog = true;
		},
		send_transaction: function (key) {
			this.secret_key = key;
			this.progress = true;
			this.etat_transaction = "";
			try {


				if (this.asset_code == undefined) this.asset_code = "XLM"
				// ---------- CREATION DES TOKENS A ECHANGER
				if (this.asset_code != "XLM")
					var asset_issuer_key = StellarSdk.Keypair.fromPublicKey(
						this.asset_issuer,
					);

				var asset_issuer_fceuro_key = StellarSdk.Keypair.fromPublicKey(
					this.asset_issuer_fceuro,
				);


				if (this.asset_code != "XLM")
					var buying = new StellarSdk.Asset(this.asset_code, asset_issuer_key.publicKey());
				else
					buying = StellarSdk.Asset.native();

				var selling = new StellarSdk.Asset(this.asset_code_fceuro, asset_issuer_fceuro_key.publicKey());

				// ---------- PARAMETRE DE LA TRANSACTION

				var origine_keys = StellarSdk.Keypair.fromSecret(this.secret_key);


				// ---------- PARAMETRE DE LA TRANSACTION

				const nombre = this.quantity.toString();
				const prix_unitaire = this.price.toString();
				const id = this.id_transaction;

				//  const nombre = "10";
				//        const prix_unitaire = "110";

				// ---------- SECURITE

				this.secret_key = "";

				// --------- INITIAlISATION


				const trust = {
					asset: buying
				}


				let operations = [];

				// ---------- VERIFICATION DE LA PRESENCE DE fcEURO DANS LA BALANCE

				let add_trust_asset = true

				this.$store.state.balances.forEach((item, i) => {
					if ((item.asset_code == this.asset_code) && (item.asset_issuer == this.asset_issuer)) add_trust_asset = false;
				});


				if (add_trust_asset)
					operations.push(
						StellarSdk.Operation.changeTrust({
							asset: buying
						})
					)
				//console.log(nombre)

				if ((id > 0) || (nombre > 0))
					operations.push(
						StellarSdk.Operation.manageBuyOffer({
							"selling": selling,
							"buying": buying,
							"buyAmount": nombre.toString(),
							"price": prix_unitaire,
							"offerId": id,
						})
					)



				let params = {
					"source": origine_keys,
					"operations": operations,
					"memo": "Buy Order by Fraction.re"
				}

				//console.log("id", id)
				this.$store.dispatch('stellar_transaction_sign_source', params).then(response => {
					if (response != undefined) this.transaction = response;
					this.progress = false;
					if (response.toString().substr(0, 5) == "Error") {
						this.progress = false;
						this.etat_transaction = "Echec de la transaction";
						if (response != undefined) this.error_why = response.toString().substr(6)
					} else {
						this.etat_transaction = "success";
						this.dialog = false;
					}
				})

			} catch (error) {
				this.etat_transaction = "Rejet de la transaction par le Wallet: " + error.toString().replace("TypeError: ", '');
				if (error.toString().replace("TypeError: ", '') == "Error: invalid version byte. expected 144, got undefined")
					this.etat_transaction = "Rejet par le Wallet: code secret vide ou code pin faux ou code de double vérification faux";
				this.error_data = error;
				//console.log(error)
				this.progress = false;
			}
		}
	},
	watch: {
		id_transaction: function () {
			if (this.id_transaction != "0") {
				this.price = "0.001";
				this.quantity = "0";
			}
		},
		unit_price: function () {
			if (this.id_transaction == "0")
				if (this.unit_price != undefined) this.price = this.unit_price;
		},
		amount: function () {
			if (this.id_transaction == "0")
				if (this.amount != undefined) this.quantity = this.amount;
		},

		dialog: function () {
			if (!this.dialog) {
				this.$emit('close', this.etat_transaction)
				this.etat_transaction = undefined
				this.error_data = undefined
				this.error_why = ""
				this.transaction = undefined
			}
		}

	}
}
</script>

<style scoped>
</style>

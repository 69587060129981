<template>
<div>
	<v-card-title class="teal lighten-5">Votre solde <v-spacer></v-spacer>
		<b class="justify-end success--text"
			 v-html="monetary_aspect(total_wallet)+' €'" />
	</v-card-title>

	<div class="text-center mt-3">
		<v-btn v-for="file in files"
					 :key="file.title"
					 :color="file.color"
					 @click="change_page(file.go)"
					 class="ma-2 mx-5"
					 fab
					 dark
					 small>
			<v-icon dark>
				{{file.icon}}
			</v-icon>
		</v-btn>
	</div>


	<v-row no-gutters>
		<v-col cols=12>
			<listbalance ref="balance"
									 :select_key="publickey"
									 @click_asset="click_asset" />
		</v-col>
	</v-row>


	<v-row no-gutters>
		<v-col cols=12>
			<listpool ref="pool"
								:select_key="publickey"
								@click_pool="click_pool" />
		</v-col>
	</v-row>

	<v-row no-gutters>
		<v-col cols=12>
			<listclaimbalance ref="pool"
												:select_key="publickey"
												@click_claim="click_claim"
												@close_claim="close_claim" />
		</v-col>
	</v-row>

	<v-row no-gutters>
		<v-col cols=12>
			<stellarlistbuyorder ref="buyorder"
													 :select_key="publickey"
													 @click_asset="click_buy_order" />
		</v-col>
	</v-row>

	<v-row no-gutters>
		<v-col cols=12>
			<stellarlistsellorder ref="sellorder"
														:select_key="publickey"
														@click_asset="click_sell_order" />
		</v-col>
	</v-row>

	<v-row no-gutters>
		<v-col cols=12>
			<listbalance ref="balance"
									 label="Tokens de séquestre"
									 :select_key="sequestrekey"
									 @click_asset="click_asset" />
		</v-col>
	</v-row>

</div>
</template>

<script>
import listbalance from '../components/stellar_list_balance.vue'
import listpool from '../components/stellar_list_liquidity_pool.vue'
import listclaimbalance from '../components/stellar_list_claim_balance.vue'
import stellarlistbuyorder from '../components/stellar_list_buy_order.vue'
import stellarlistsellorder from '../components/stellar_list_sell_order.vue'


export default {
	name: 'dashboard',
	components: {
		listbalance,
		listpool,
		listclaimbalance,
		stellarlistbuyorder,
		stellarlistsellorder
	},
	props: {},
	data: () => ({
		asset_index: [],
		files: [{
				color: 'red',
				icon: 'mdi-clipboard-text',
				title: 'Contrat à terme',
				subtitle: 'Souscrire et recevoir',
				go: 'contrat'
			},
			{
				color: 'blue',
				icon: 'mdi-bank-transfer',
				title: 'Achat',
				subtitle: 'Acheter des tokens',
				go: 'achat'
			},
			{
				color: 'amber',
				icon: 'mdi-finance',
				title: 'Vente ',
				subtitle: 'Vendre des tokens',
				go: 'vente'
			},
			{
				color: 'green',
				icon: 'mdi-transfer',
				title: 'Transférer ',
				subtitle: 'Transférer des tokens',
				go: 'transfert'
			},

			{
				color: 'purple',
				icon: 'mdi-chart-line-variant',
				title: 'Pool de liquidité',
				subtitle: 'Gérer vos pools de liquidité',
				go: 'pool'
			},

			{
				color: 'brown',
				icon: "mdi-format-list-numbered-rtl",
				title: 'Historisque',
				subtitle: 'Les transactions sur la blockchain',
				go: 'explorer'
			},
			{
				color: 'blue-grey',
				icon: 'mdi-shield-check',
				title: 'Tiers de confiance',
				subtitle: 'En dehors de la blockchain',
				go: 'trust'
			},
			{
				color: 'black',
				icon: 'mdi-account',
				title: 'Compte',
				subtitle: 'Clés et déconnection',
				go: 'account'
			},
		],
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		publickey() {
			return this.$store.state.publickey
		},
		total_wallet() {
			return this.$store.state.total_wallet
		},
		sequestrekey() {
			return this.$store.getters.sequestre_account
		},

	},
	methods: {
		change_page(page, params = []) {
			this.$emit('changepage', page, params)
		},
		click_sell_order() {},
		click_buy_order() {},
		click_asset() {},
		click_pool() {},
		click_claim() {},
		close_claim(etat) {
			this.$emit('update', etat)
		}
	},
	watch: {

	}
}
</script>

<style scoped>    </style>

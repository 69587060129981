<template>
<v-dialog v-model="dialog"
					fullscreen
					hide-overlay
					transition="dialog-bottom-transition">
	<template v-slot:activator="{ on, attrs }">
		<v-btn class="px-5"
					 color="primary"
					 :block="block"
					 dark
					 v-bind="attrs"
					 :class="css"
					 v-on="on">
			{{label}}
		</v-btn>
	</template>
	<v-card>
		<v-toolbar dark
							 color="primary">
			<v-btn icon
						 dark
						 @click="dialog = false">
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<b class="text-h6"> Intégrer le Liquidity Pool </b>


		</v-toolbar>


		<v-row class="mx-5 mt-5">
			<v-col cols=12>



				<v-card class="mx-auto"
								elevation=0>

					<v-card-text class="pa-5 text-h7">
						{{manage_name}} : <b>{{pool.assets[0].asset_code}} / {{pool.assets[1].asset_code}} </b>




					</v-card-text>
				</v-card>

				<validbutton :etat_transaction="etat_transaction"
										 :error_why="error_why"
										 :progress="progress"
										 @valid="send_transaction" />

			</v-col>

		</v-row>
	</v-card>
</v-dialog>
</template>


<script>
var StellarSdk = require('stellar-sdk');
import validbutton from '@/components/valid_button.vue'


export default {
	name: 'stellar_manage_data',
	components: {
		validbutton
	},
	props: {
		pool: [],

		block: {
			type: Boolean,
			default: false
		},
		css: String,
		label: String

	},
	data: () => ({

		manage_name: "Liquidity Pool",
		manage_value: "",
		dialog: false,



		secret_key: '',
		etat_transaction: undefined,
		error_data: undefined,
		error_extras: undefined,
		error_why: undefined,
		transaction: undefined,
		progress: false,
		envelope_xdr: undefined,
		result_xdr: undefined,
		result_meta_xdr: undefined,
		otp: "",
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		privatekey() {
			return this.$store.state.privatekey
		},
		pin() {
			return this.$store.state.pin
		},
		blockchain() {
			return this.$store.state.blockchain
		},
		account_sign() {
			return this.$store.state.publickey
		},

	},
	methods: {
		onFinish() {
			if (this.pin == this.hash_key(this.otp)) this.secret_key = this.decrypt_key(this.otp);
		},



		orderAssets: function (A, B) {
			let inverse = undefined;
			(StellarSdk.Asset.compare(A, B) <= 0) ? inverse = false: inverse = true;

			if (inverse == true) {
				let qa = this.quantity;
				let qb = this.price;
				this.price = qa;
				this.quantity = qb;
			}

			return (StellarSdk.Asset.compare(A, B) <= 0) ? [A, B] : [B, A];
		},

		send_transaction: function (key) {
			this.secret_key = key;
			this.progress = true;
			this.etat_transaction = "";
			try {
				//console.log(this.asset.destination_key)

				var limite = "922337203685";
				let origine_keys = StellarSdk.Keypair.fromSecret(this.secret_key);
				let operations = [];


				const [A, B] = this.orderAssets(...[
					new StellarSdk.Asset(this.pool.assets[0].asset_code, this.pool.assets[0].asset_issuer),
					new StellarSdk.Asset(this.pool.assets[1].asset_code, this.pool.assets[0].asset_issuer)
				]);

				const poolShareAsset = new StellarSdk.LiquidityPoolAsset(A, B, StellarSdk.LiquidityPoolFeeV18);

				operations.push(
					StellarSdk.Operation.changeTrust({
						asset: poolShareAsset,
						limit: limite,
					})
				)

				let params = {
					"source": origine_keys,
					"operations": operations,
					"memo": "Fraction.re LP Trustline"
				}

				//console.log(params)
				this.$store.dispatch('stellar_transaction_sign_source', params).then(response => {
					if (response != undefined) this.transaction = response;
					this.progress = false;
					if (response.toString().substr(0, 5) == "Error") {
						this.progress = false;
						this.etat_transaction = "Echec de la transaction";
						if (response != undefined) this.error_why = response.toString().substr(6)
					} else {
						this.etat_transaction = "success";
						this.dialog = false;
					}
				})

			} catch (error) {
				this.etat_transaction = "Rejet de la transaction par le Wallet: " + error.toString().replace("TypeError: ", '');
				if (error.toString().replace("TypeError: ", '') == "Error: invalid version byte. expected 144, got undefined")
					this.etat_transaction = "Rejet par le Wallet: code secret vide ou code pin faux ou code de double vérification faux";
				this.error_data = error;
				//console.log(error)
				this.progress = false;
			}
		}

	},
	watch: {
		account_sign: function () {
			this.to = this.account_sign;
		},
		manage_value: function () {
			if (this.manage_value == this.account_sign) this.manage_value = "Ne pas mettre votre propre clé";
		},
		dialog: function () {
			if (!this.dialog) {
				this.$emit('close', this.etat_transaction)
				this.etat_transaction = undefined
				this.error_data = undefined
				this.error_extras = undefined
				this.error_why = undefined
				this.transaction = undefined
			}
		}

	}
}
</script>

<style scoped>
</style>

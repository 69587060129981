<template>
<v-app>
	<v-app-bar app
						 :color="blockchain== 'public' ? 'primary' : 'black'"
						 dark>
		<div class="d-flex align-center"
				 @click="change_page('dashboard')">
			<v-img alt="Wallet Fraction Logo"
						 class="shrink mr-2"
						 contain
						 src="@/assets/layout.svg"
						 transition="scale-transition"
						 width="40" />

			<span class="shrink mt-1  text-h5"
						contain
						min-width="100">Fraction.re Wallet </span>
			<span style="font-size:50%"
						class="ml-2"
						contain
						min-width="10">1.01 </span>
		</div>
		<v-spacer></v-spacer>
		<v-menu bottom
						:offset-y="true">
			<template v-slot:activator="{ on, attrs }">
				<v-btn dark
							 icon
							 v-bind="attrs"
							 v-on="on">
					<v-icon>mdi-dots-vertical</v-icon>
				</v-btn>
			</template>

			<v-list subheader
							two-line>
				<v-list-item-group v-model="selectedItem"
													 color="primary">
					<v-list-item v-for="file in files"
											 :key="file.title"
											 @click="change_page(file.go)">
						<v-list-item-avatar>
							<v-icon :class="file.color"
											dark
											v-text="file.icon"></v-icon>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title v-text="file.title"></v-list-item-title>
							<v-list-item-subtitle v-text="file.subtitle"></v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-menu>
	</v-app-bar>
	<v-main class='indigo lighten-5'>

		<importaccount v-if="accessgranted==false && page!='ouverture' "
									 @changepage="change_page"
									 :stellarkey="publickey"
									 :pin="pin" />

		<v-container style="max-width:1280px"
								 class="mx-auto   "
								 v-if="page=='ouverture'">

			<ouverture @changepage="change_page" />

		</v-container>

		<v-container style="max-width:1280px; height:100%"
								 class="mx-auto  white"
								 v-if="accessgranted==true && page!='ouverture'">

			<dashboard v-if="page=='dashboard'"
								 @update="update_wallet"
								 @changepage="change_page" />

			<achat v-if="page=='achat'"
						 @changepage="change_page" />

			<vente v-if="page=='vente'"
						 @changepage="change_page" />

			<transfert v-if="page=='transfert'"
								 @changepage="change_page" />

			<pool v-if="page=='pool'"
						@changepage="change_page" />

			<compte v-if="page=='account'"
							@changepage="change_page" />

			<contrat v-if="page=='contrat'"
							 @changepage="change_page" />

			<welcome v-if="page=='welcome'"
							 @changepage="change_page" />


			<explorer v-if="page=='explorer'"
								@changepage="change_page" />

			<trust v-if="page=='trust'"
						 @changepage="change_page" />

			<kyc v-if="page=='kyc'" />

			<sign v-if="page=='sign'" />

		</v-container>
	</v-main>
</v-app>
</template>

<script>
import importaccount from '@/components/import_account.vue'
import dashboard from "@/views/dashboard.vue";
import achat from "@/views/achat.vue";
import vente from "@/views/vente.vue";
import transfert from "@/views/transfert.vue"
import pool from "@/views/pool.vue"
import compte from "@/views/compte.vue"
import ouverture from "@/views/ouverture.vue"
import contrat from "@/views/contrat.vue"
import welcome from "@/views/welcome.vue"
import explorer from "@/views/explorer.vue"
import trust from "@/views/trust.vue"
import kyc from "@/views/kyc.vue"
import sign from "@/views/sign.vue"

export default {
	name: 'App',
	components: {
		importaccount,
		dashboard,
		achat,
		vente,
		transfert,
		pool,
		compte,
		ouverture,
		contrat,
		welcome,
		explorer,
		trust,
		kyc,
		sign
	},
	props: {},

	data: () => ({
		balances: [],
		liquidity_pool_records: [],
		liquidity_pool_distributor_records: [],

		liquidity_pool_balance: [],
		liquidity_pool_distributor_balance: [],
		operation_list: [],
		operation_offer: [],
		claimable_balances_records: [],
		claimable_balances_trust_records: [],

		operations_sequestre: [],
		data_master_account: [],
		total_assets_in_euro: 0,
		total_liquidity_pool_in_euro: 0,
		total_claimable_balances_in_euro: 0,
		total_wallet: 0,
		total_liquidity_pool_distributor_in_euro: 0,
		total_assets_master_account_in_euro: 0,
		page: 'dashboard',
		pin: undefined,
		selectedItem: undefined,
		files: [{
				color: 'red',
				icon: 'mdi-clipboard-text',
				title: 'Contrat à terme',
				subtitle: 'Souscrire et recevoir',
				go: 'contrat'
			},
			{
				color: 'blue',
				icon: 'mdi-bank-transfer',
				title: 'Achat',
				subtitle: 'Acheter des tokens',
				go: 'achat'
			},
			{
				color: 'amber',
				icon: 'mdi-finance',
				title: 'Vente ',
				subtitle: 'Vendre des tokens',
				go: 'vente'
			},
			{
				color: 'green',
				icon: 'mdi-transfer',
				title: 'Transférer & payer',
				subtitle: 'Transfert et paiement',
				go: 'transfert'
			},

			{
				color: 'purple',
				icon: 'mdi-chart-line-variant',
				title: 'Liquidity Pools',
				subtitle: 'Gérer vos Liquidity Pools',
				go: 'pool'
			},

			{
				color: 'brown',
				icon: "mdi-format-list-numbered-rtl",
				title: 'Historique',
				subtitle: 'Les transactions sur la blockchain',
				go: 'explorer'
			},
			{
				color: 'blue-grey',
				icon: 'mdi-shield-check',
				title: 'Tiers de confiance',
				subtitle: 'En dehors de la blockchain',
				go: 'trust'
			},
			{
				color: 'black',
				icon: 'mdi-account',
				title: 'Paramètres',
				subtitle: 'Clés et déconnection',
				go: 'account'
			},
		],

	}),

	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		//		console.log(this.$route.name)
		//console.log('GBEBU3IKKARTLC2TFRTLJWZL4RVDFM442MR42F67724EXU2BLRKCXKQZ'.length)

		let txtls = window.localStorage.getItem('start');

		if (txtls != null) {
			let data = JSON.parse(txtls);
			this.pin = data.pin;
			if (data.publickey == this.$store.state.demo_publickey) this.$store.commit('update_blockchain', 'test');

			this.$store.commit('update_publickey', data.publickey);
			this.$store.commit('update_privatekey', data.privatekey);
			this.$store.commit('update_pin', data.pin);
			//		console.log(data.totp)
			if (data.totp != "") this.$store.commit('update_totp', data.totp);

			this.update_wallet();
		} else {


			this.$store.commit("update_access", false);
		}

	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		route() {
			return this.$route.name
		},
		publickey() {
			return this.$store.state.publickey
		},

		accessgranted() {
			return this.$store.state.accessgranted
		},
		horizon() {
			return this.$store.getters.horizon
		},
		blockchain() {
			return this.$store.getters.blockchain
		},
		cours_XML() {
			return this.$store.state.cours_XML
		},
	},
	methods: {
		change_page(page, params = []) {
			this.page = page;
		},

		update_wallet() {
			//		let url = this.$store.getters.horizon + "accounts/" + this.$store.getters.master_account;
			let url = this.horizon + "accounts/" + this.$store.getters.master_account;
			//console.log(url)
			this.$store.dispatch('get', url).then(response => {
				let temp = response.data.data;
				let tab = [];
				for (const [key, value] of Object.entries(temp)) {
					tab[key] = value;
					tab[key] = window.atob(tab[key])
				}
				this.asset_index = tab;


				//			url = this.horizon + "accounts/" + this.$store.getters.master_account + "/operations?limit=200&order=asc";
				url = this.horizon + "accounts/" + this.publickey + "/operations?limit=200&order=desc";

				this.$store.dispatch('get', url).then(response => {
					this.operation_list = response.data;

					this.update_operation();

				})

				url = this.horizon + "accounts/" + this.$store.getters.master_account;
				this.$store.dispatch('get', url).then(response => {
					this.data_master_account = response.data.data;
					this.balances_master_account = response.data.balances;
					this.update_total_assets_master_account_in_euro();
					this.update_data_master_account();
				})


				url = this.horizon + "accounts/" + this.$store.getters.distributor_account;
				this.$store.dispatch('get', url).then(response => {
					this.balances_distributor_account = response.data.balances;
					this.update_total_assets_distributor_account_in_euro();



					let url = this.horizon + "liquidity_pools/?limit=200&account=" + this.$store.getters.distributor_account;
					this.$store.dispatch('get', url).then(response => {
						this.liquidity_pool_distributor_records = [];
						this.update_total_liquidity_pools_distributor_in_euro(response.data._embedded.records)
					})


				})

				url = this.horizon + "accounts/" + this.publickey + "/offers?limit=200&order=desc";
				this.$store.dispatch('get', url).then(response => {
						this.operation_offer = response.data._embedded.records;
						this.$store.commit("update_balances_operation_offer", this.operation_offer)
					}),


					url = this.horizon + "accounts/" + this.$store.getters.trust_account + "/operations?order=desc&limit=200";
				//console.log(url)

				this.$store.dispatch('get', url).then(response => {
						this.operations_sequestre = response.data._embedded.records;
						this.$store.commit("update_operations_sequestre", this.operations_sequestre)
					}),


					url = this.horizon + "accounts/" + this.$store.getters.distributor_account + "/offers?order=desc&limit=200";
				//console.log(url)

				this.$store.dispatch('get', url).then(response => {
						this.operation_offer_distributor = response.data._embedded.records;
						this.$store.commit("update_balances_operation_offer_distributor", this.operation_offer_distributor)


					}),






					url = this.horizon + "accounts/" + this.publickey;
				//console.log(url)
				this.$store.dispatch('get', url).then(response => {
					this.balances = response.data.balances;
					this.liquidity_pool = [];
					this.update_total_assets_in_euro();


					let url = this.horizon + "liquidity_pools/?limit=200&account=" + this.publickey;
					this.$store.dispatch('get', url).then(response => {
							this.liquidity_pool_records = [];
							this.update_total_liquidity_pools_in_euro(response.data._embedded.records)
						}),

						this.$store.dispatch('get', this.horizon + "claimable_balances?limit=200&order=desc&claimant=" + this.publickey).then(response => {
							this.claimable_balances_records = [];
							this.claimable_balances_trust_records = [];

							this.update_total_claimable_balances_in_euro(response.data._embedded.records)
						})

				}).catch(error => {
					this.balances = [];
					//console.log("ERREUR this.balances")
					//console.log(error)
				})
			})
		},

		update_total_assets_master_account_in_euro() {

			this.total_assets_master_account_in_euro = 0;
			//console.log(this.balances_master_account)
			if (this.balances_master_account != undefined) this.balances_master_account.forEach((item, i) => {
				let fceuro
				fceuro = this.value_of_asset_in_fceuro(item, true, "master")
				if (fceuro != undefined) {
					this.balances_master_account[i].fceuro = fceuro;
					let temp_balance_master = this.balances_master_account[i].balance;
					if (temp_balance_master < 1) temp_balance_master = 1
					this.balances_master_account[i].unitfceuro = fceuro / temp_balance_master;

					this.total_assets_master_account_in_euro += fceuro;
				}
			});

			this.$store.commit("update_balances_master_account", this.balances_master_account)
			this.$store.commit("update_total_assets_master_account_in_euro", this.total_assets_master_account_in_euro)
		},
		update_operation() {
			this.$store.commit("update_operation_list", this.operation_list)

		},
		update_data_master_account() {
			this.$store.commit("update_data_master_account", this.data_master_account)
		},

		update_total_assets_distributor_account_in_euro() {

			this.total_assets_distributor_account_in_euro = 0;

			if (this.balances_distributor_account != undefined) this.balances_distributor_account.forEach((item, i) => {
				let fceuro
				fceuro = this.value_of_asset_in_fceuro(item, true, "distributor")
				if (fceuro != undefined) {
					this.balances_distributor_account[i].fceuro = fceuro;
					let temp_balance_distributor = this.balances_distributor_account[i].balance;
					if (temp_balance_distributor < 1) temp_balance_distributor = 1
					this.balances_distributor_account[i].unitfceuro = fceuro / temp_balance_distributor;

					this.total_assets_distributor_account_in_euro += fceuro;
				}
			});

			this.$store.commit("update_balances_distributor_account", this.balances_distributor_account)
			this.$store.commit("update_total_assets_distributor_account_in_euro", this.total_assets_distributor_account_in_euro)
		},


		update_total_claimable_balances_in_euro(records) {



			this.total_claimable_balances_in_euro = 0;
			let arr = [];
			let arr2 = [];
			if (records != undefined)
				records.forEach((item, i) => {
					item.asset_code = item.asset.substr(0, item.asset.indexOf(":"));
					item.asset_issuer = item.asset.substr(item.asset.indexOf(":") + 1);
					item.balance = item.amount
					item.asset_type = "claimable_balances"
					//     let date_claim= new Date(  ( item.claimants[0].predicate.not.abs_before)) ;
					//      let aujourdhui = new Date()

					let fceuro = this.value_of_asset_in_fceuro(item, true)

					if ((item.claimants[0].predicate.not != undefined) && (item.asset_issuer == this.$store.getters.master_account)) {
						arr.push({
							"fceuro": fceuro,
							"asset_code": item.asset_code,
							"asset_issuer": item.asset_issuer,
							"amount": item.amount,
							"id": item.id,
							"date": item.claimants[0].predicate.not.abs_before
						})

						this.total_claimable_balances_in_euro += fceuro;
					}




					if ((item.claimants[0].predicate.not != undefined) && (item.asset_issuer == this.$store.getters.sequestre_account)) {


						this.$store.dispatch('get', this.horizon + "claimable_balances/" + item.id + "/transactions").then(response => {
							let memo = response.data._embedded.records[0].memo;
							//console.log("memo", memo);
							//console.log(item)
							arr2.push({
								"fceuro": fceuro,
								"asset_code": item.asset_code,
								"asset_issuer": item.asset_issuer,
								"amount": item.amount,
								"id": item.id,
								"memo": memo,
								"date": item.claimants[0].predicate.not.abs_before
							})
						})




					}


				});

			this.claimable_balances_records = arr
			this.claimable_balances_trust_records = arr2
			this.$store.commit("update_claimable_balances", this.claimable_balances_records)
			this.$store.commit("update_claimable_balances_trust", this.claimable_balances_trust_records)

			this.$store.commit("update_total_claimable_balances_in_euro", this.total_claimable_balances_in_euro)
		},



		update_total_assets_in_euro() {
			let temp_balance = [];
			let temp_balance_sequestre = [];
			let solde_fceuro = 0;
			this.total_assets_in_euro = 0;
			if (this.balances != undefined) this.balances.forEach((item, i) => {
				if ((item.asset_issuer == this.$store.getters.master_account) || (item.asset_type == "native")) {

					let fceuro
					fceuro = this.value_of_asset_in_fceuro(item, true)
					if (fceuro != undefined) {
						this.balances[i].fceuro = fceuro;
						this.balances[i].unitfceuro = fceuro / this.balances[i].balance;

						if (this.balances[i].balance == 0) {
							this.balances[i].unitfceuro = fceuro;
							this.balances[i].fceuro = 0;
						}

						if (this.balances[i].balance > 0) this.total_assets_in_euro += fceuro;
						if (item.asset_code == "fcEURO") solde_fceuro = fceuro;
					}

					temp_balance.push(item)
				}

				if (item.asset_issuer == this.$store.getters.sequestre_account) {
					if (this.publickey.substr(-3) == item.asset_code.substr(0, 3)) {
						this.balances[i].way = "me_for_other"
					} else {
						this.balances[i].way = "other_for_me"
					}


					//			url = this.horizon + this.$store.getters.sequestre_account + "/payments?limit=200&order=desc";

					temp_balance_sequestre.push(item)
				}


				if (item.liquidity_pool_id != undefined) this.liquidity_pool_balance[item.liquidity_pool_id] = item.balance;

			});



			this.$store.commit("update_balances_sequestre", temp_balance_sequestre)

			this.$store.commit("update_balances", temp_balance)
			this.$store.commit("update_total_assets_in_euro", this.total_assets_in_euro)
			this.$store.commit("update_solde_fceuro", solde_fceuro)

		},

		update_total_liquidity_pools_distributor_in_euro(records) {
			this.total_liquidity_pool_distributor_in_euro = 0;



			if (records != undefined) records.forEach((item, i) => {
				let arr = [];
				let total = 0;
				for (let i = 0; i < 2; i++) {
					let fceuro = this.update_value_of_pool(item.reserves[i], true)
					let asset_code = item.reserves[i].asset.substr(0, item.reserves[i].asset.indexOf(":"));
					let asset_issuer = item.reserves[i].asset.substr(item.reserves[i].asset.indexOf(":") + 1);



					arr.push({
						"fceuro": fceuro,
						"asset_code": asset_code,
						"asset_issuer": asset_issuer,
						"amount": item.reserves[i].amount
					})
					total += fceuro;
					this.total_liquidity_pool_distributor_in_euro += fceuro;
				}

				let temp = {
					"id": item.id,
					"balance": item.total_shares,
					"fceuro": total,
					"assets": arr
				}
				this.liquidity_pool_distributor_records.push(temp)


			});

			this.$store.commit("update_liquidity_pool_distributor", this.liquidity_pool_distributor_records)
			this.$store.commit("update_total_liquidity_pool_distributor_in_euro", this.total_liquidity_pool_distributor_in_euro)

		},


		update_total_liquidity_pools_in_euro(records) {
			this.total_liquidity_pool_in_euro = 0;

			if (records != undefined) records.forEach((item, i) => {
				let arr = [];
				let total = 0;

				let nbshare = this.liquidity_pool_balance[item.id];
				if (nbshare == undefined) nbshare = 0;


				for (let i = 0; i < 2; i++) {
					let fceuro = this.update_value_of_pool(item.reserves[i], true)
					let asset_code = item.reserves[i].asset.substr(0, item.reserves[i].asset.indexOf(":"));
					let asset_issuer = item.reserves[i].asset.substr(item.reserves[i].asset.indexOf(":") + 1);


					fceuro = fceuro * (nbshare / item.total_shares);

					arr.push({
						"fceuro": fceuro,
						"asset_code": asset_code,
						"asset_issuer": asset_issuer,
						"amount": item.reserves[i].amount
					})
					total += fceuro;
					this.total_liquidity_pool_in_euro += fceuro;
				}

				let temp = {
					"id": item.id,
					"balance": nbshare,
					"total_share": item.total_shares,
					"fceuro": total,
					"assets": arr
				}
				this.liquidity_pool_records.push(temp)
			});
			this.$store.commit("update_liquidity_pool", this.liquidity_pool_records)
			this.$store.commit("update_total_liquidity_pool_in_euro", this.total_liquidity_pool_in_euro)
		},

		update_value_of_pool(item, return_number = false) {
			let asset_code = item.asset.substr(0, item.asset.indexOf(":"));
			let value = this.asset_index[asset_code]

			//console.table( this.asset_index)
			let sum = parseFloat(item.amount)

			let nombre

			if (value != undefined) {
				let date = value.substr(0, 10);
				let coupe = value.substr(11)
				let intitial_value = coupe.substr(0, coupe.indexOf("%"));
				let pourcent = coupe.substr(coupe.indexOf("%") + 1);

				let date1 = new Date(date);
				let date2 = new Date();
				let Diff_temps = date2.getTime() - date1.getTime();
				let Diff_jours = Diff_temps / (1000 * 3600 * 24);

				let cours_actuel = parseFloat(intitial_value) + (Diff_jours * (parseFloat(intitial_value) * parseFloat(pourcent) / 100) / 365);

				nombre = cours_actuel * sum
			}
			if (asset_code == "fcEURO") nombre = 1 * sum;

			//  if (item.asset_type == "native") nombre = parseFloat(this.cours_XML) * sum;


			if (!return_number) return this.monetary_aspect(nombre) + ' €';
			else return nombre;
		},

		value_of_asset_in_fceuro(item, return_number = false, key = "") {
			let value = this.asset_index[item.asset_code]

			//	console.table(this.asset_index)
			let sum = parseFloat(item.balance)

			let nombre

			if (value != undefined) {
				let date = value.substr(0, 10);
				let coupe = value.substr(11)
				let intitial_value = coupe.substr(0, coupe.indexOf("%"));
				let pourcent = coupe.substr(coupe.indexOf("%") + 1);

				let date1 = new Date(date);
				let date2 = new Date();
				let Diff_temps = date2.getTime() - date1.getTime();
				let Diff_jours = Diff_temps / (1000 * 3600 * 24);

				let cours_actuel = parseFloat(intitial_value) + (Diff_jours * (parseFloat(intitial_value) * parseFloat(pourcent) / 100) / 365);
				if (sum == 0) sum = 1;
				nombre = cours_actuel * sum
				//		console.table((sum))
			}
			if (item.asset_code == "fcEURO") nombre = 1 * sum;

			if (item.asset_type == "native") nombre = parseFloat(this.cours_XML) * sum;

			//		if (key == "")				if (item.asset_type == "liquidity_pool_shares") this.liquidity_pool_balance[item.liquidity_pool_id] = item.balance;

			if (!return_number) return this.monetary_aspect(nombre) + ' €';
			else return nombre;
		},
		calcul_total_wallet() {
			this.total_wallet = this.total_claimable_balances_in_euro + this.total_liquidity_pool_in_euro + this.total_assets_in_euro
			this.$store.commit("update_total_wallet", this.total_wallet)
		},

		detail_trust() {
			//console.log("operations_sequestre")
			//console.log(this.balances)
			if (this.balances != undefined) this.balances.forEach((item, i) => {


				if (item.asset_issuer == this.$store.getters.sequestre_account) {
					//console.log(item.asset_issuer)
					//console.log(this.$store.getters.sequestre_account)

					this.operations_sequestre.forEach((sequestre_item, ib) => {
						//console.log(sequestre_item.asset_code)
						if (sequestre_item.asset != undefined) {
							//console.log(sequestre_item.asset_code);
							let asset_code = sequestre_item.asset.substr(0, sequestre_item.asset.indexOf(":"))

							if (asset_code == item.asset_code) {
								let id_transaction = sequestre_item.transaction_hash;
								//console.log(id_transaction)
								this.operations_sequestre.forEach((sequestre_item_again, ic) => {
									//console.log(sequestre_item_again);
									//console.log(id_transaction);
									//console.log(sequestre_item_again.transaction_hash);
									//console.log(sequestre_item_again.transaction_hash, sequestre_item_again.type, sequestre_item_again.asset_code);
									if ((sequestre_item_again.transaction_hash == id_transaction)) {


										//console.log(sequestre_item_again.type);
										//console.log(sequestre_item_again.asset_code);
										//console.log(item.asset_code);
										if ((sequestre_item_again.type == "payment"))
											if (sequestre_item_again.asset_code != item.asset_code) {
												this.balances[i].asset_trust = sequestre_item_again.asset_code;
												this.balances[i].asset_trust_issuer = sequestre_item_again.asset_issuer;
												this.balances[i].amount_trust = sequestre_item_again.amount;
											}

										if ((sequestre_item_again.type == "create_claimable_balance")) {
											if (sequestre_item_again.claimants[0].destination != this.publickey)
												this.balances[i].destination_key = sequestre_item_again.claimants[0].destination;
										}
									}
								})

							}
						}
					})
				}
			})

		},

	},
	watch: {
		route() {
			if (this.route == "ouverture") this.page = "ouverture";
			if (this.route == "kyc") this.page = "kyc";
			if (this.route == "sign") this.page = "sign";
			//	console.log(this.route)
		},
		publickey() {
			//console.log(this.publickey)
		},
		total_claimable_balances_in_euro() {
			this.calcul_total_wallet()
		},
		total_liquidity_pool_in_euro() {
			this.calcul_total_wallet()
		},
		total_assets_in_euro() {
			this.calcul_total_wallet()
		},
		accessgranted() {
			if (this.accessgranted == true) {
				//		this.page = "welcome"
				if (this.publickey != undefined) this.update_wallet();
			}
		},
		page() {
			if (this.page == "dashboard") this.update_wallet();
		},
		balances() {
			this.detail_trust()
		},
		operations_sequestre() {
			this.detail_trust()
		},

	}
}
</script>

<style scoped>
</style>

<template>
<v-form>
	<v-container>
		<v-row>
			<v-col cols="12"
						 sm="12">
				<v-alert class="pa-1"
								 icon="mdi-account"
								 color="teal lighten-4"
								 @click="close('success')">Paramétres </v-alert>
				<div class="mx-auto"
						 style="text-align:center;">
					<qrcode-vue :value="publickey"></qrcode-vue>
				</div>
				<v-icon style="float:right;"
								class="mt-3"
								@click="copyToClipBoard(publickey)">mdi-content-copy
				</v-icon>
				<v-text-field v-model="publickey"
											disabled
											class="caption"
											label="Clé publique"
											clearable>
				</v-text-field>
			</v-col>

			<v-col cols="12"
						 v-if="false"
						 sm="6">
				<v-text-field v-model="privatekey"
											solo
											type="password"
											label="Clé privée"
											clearable></v-text-field>

			</v-col>

			<v-container fluid>
				<stellarmanagesponsoring css="   mx-auto"
																 label="Sponsoring" />
			</v-container>

			<v-container fluid>
				Unité de compte par défaut
				<v-radio-group v-model="unit_currency"
											 row
											 disabled
											 column>
					<v-radio :label="item"
									 style="width:80px;"
									 class="my-2"
									 v-for="(item, index) in list_currency"
									 :key=index
									 :value="item"></v-radio>

				</v-radio-group>
				<hr class="mb-6" />
				<div class="mx-auto"
						 style="text-align:center;">
					<v-btn class="mx-auto"
								 @click="logout()"
								 fab
								 dark
								 large
								 color="red">
						<v-icon dark>
							mdi-power
						</v-icon>
					</v-btn>
				</div>

				<v-btn dark
							 @click="logout()"
							 v-show="false"
							 color="red"> Deconnection </v-btn>

			</v-container>
		</v-row>
	</v-container>
</v-form>
</template>

<script>
import stellarmanagesponsoring from '../components/stellar_manage_sponsoring.vue'
import QrcodeVue from 'qrcode.vue'

export default {
	name: 'compte',
	components: {
		stellarmanagesponsoring,
		QrcodeVue
	},
	props: {},
	data: () => ({
		unit_currency: "fcEURO",
		privatekey: undefined,
		list_currency: [
			"XML", "fcUSD", "fcEURO", "fcJPY", "fcGBP", "fcAUD", "fcCAD", "fcCHF", "fcCNH", "fcSEK", "fcNZD"
		]
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {

	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		publickey() {
			return this.$store.state.publickey
		},
		pin() {
			return this.$store.state.pin
		},
		double_identification() {
			return this.$store.state.double_identification
		},
	},
	methods: {
		close(etat) {
			if (etat == "success") this.$emit('changepage', "dashboard")
		},
		saveFile: function () {
			if (this.privatekey.length == 56) {
				window.localStorage.clear('start');
				let temp = JSON.stringify({
					publickey: this.publickey,
					privatekey: this.privatekey,
					totp: this.double_identification,
					pin: this.pin,
				});

				window.localStorage.setItem('start', temp);

			}
		},
		logout() {
			window.localStorage.clear('start');
			this.$store.commit("update_access", false);
		}
	},
	watch: {
		privatekey() {
			if (this.privatekey.length == 56) {

				this.$store.commit('update_privatekey', this.privatekey);
				this.saveFile();
			}
		},
	}
}
</script>

<style scoped>
</style>

<template>
<v-dialog v-model="dialog"
					fullscreen
					hide-overlay
					transition="dialog-bottom-transition">
	<template v-slot:activator="{ on, attrs }">
		<v-btn class="px-5"
					 color="primary"
					 dark
					 v-bind="attrs"
					 v-on="on"
					 :block=block
					 :class="css"
					 :small="small">
			{{label}}
		</v-btn>
	</template>
	<v-card>
		<v-toolbar dark
							 color="primary">
			<v-btn icon
						 dark
						 @click="dialog = false">
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<b class="text-h6"> Dépôt dans un pool de liquidité </b>
		</v-toolbar>


		<v-row class="mx-1 mt-2">
			<v-col cols=12>

				<listbalance :unit_only=false
										 :short=false
										 color="green darken-4"
										 filter="XLM "
										 :show_nft=false
										 ref="balance"
										 :select_key="account_sign"
										 label="Votre portefeuille d'actifs" />

			</v-col>
			<v-col cols=12>
				<v-card elevation=0>
					<v-text-field v-on:blur="change_quantity()"
												class="my-5"
												v-model="quantity"
												:label="'Quantité de  '+ asset_code"></v-text-field>
					<v-text-field class="my-5"
												v-model="price"
												:label="'Quantité de '+ asset_code_fceuro"></v-text-field>
					<v-text-field class="my-5"
												v-model="downward"
												label="Limites de prix acceptables à la baisse (%)"></v-text-field>
					<v-text-field class="my-5"
												v-model="upward"
												label="Limites de prix acceptables à la hausse  (%)"></v-text-field>
					<v-text-field v-if="false"
												class="my-5"
												v-model="limit"
												label="Limit"></v-text-field>


					<span v-html="ratio" />
					<br /> <br />
					Dépôt de :

					<b class="red--text"
						 v-html="monetary_aspect(quantity)" /> de <b class="red--text">{{asset_code}} </b>
					<br />et de <b class="red--text"
						 v-html="monetary_aspect(price)" /> de <b class="red--text">{{asset_code_fceuro}} </b>




				</v-card>

				<validbutton :etat_transaction="etat_transaction"
										 :error_why="error_why"
										 :progress="progress"
										 @valid="send_transaction" />
			</v-col>
		</v-row>
	</v-card>
</v-dialog>
</template>


<script>
var StellarSdk = require('stellar-sdk');

import BigNumber from 'bignumber.js'
import listliquiditypool from "@/components/stellar_list_liquidity_pool.vue";
import listbalance from "@/components/stellar_list_balance.vue";
import validbutton from '@/components/valid_button.vue'


export default {
	name: 'stellar_create_liquidity_pool_deposit',
	components: {
		listliquiditypool,
		listbalance,
		validbutton
	},
	props: {
		css: String,
		small: String,
		label: {
			type: String,
			default: "buy"
		},
		pool: undefined,
		block: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({

		pool_id: undefined,
		operation_response: [],

		asset_code_fceuro: undefined,
		asset_code: undefined,
		asset_issuer: undefined,

		asset_issuer_fceuro: "",
		asset_type_fceuro: "",
		asset_balance_fceuro: "",
		upward: '10',
		downward: '10',
		from: '',
		dialog: false,
		ratio: 1,
		ratio_exchange: 1,
		account_response: [],
		quantity: '0',
		price: '0',
		limit: 922337203685,
		to: undefined,
		secret_key: '',
		etat_transaction: undefined,
		error_data: undefined,
		error_extras: undefined,
		error_why: undefined,
		transaction: undefined,
		progress: false,
		label_secret: "Saisir votre clé secréte",
		returnprice: undefined,
		ref_transaction: 0,
		balance: 0,
		otp: "",
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {
		this.calcul_ratio();
	},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		blockchain() {
			return this.$store.state.blockchain
		},
		account_sign() {
			return this.$store.state.publickey
		},

		pin() {
			return this.$store.state.pin
		},
		privatekey() {
			return this.$store.state.privatekey
		},
		liquidity_pool() {
			return this.$store.state.liquidity_pool
		},
	},
	methods: {
		onFinish() {
			if (this.pin == this.hash_key(this.otp)) this.secret_key = this.decrypt_key(this.otp);
		},

		change_quantity() {

			this.price = parseFloat(this.quantity) * this.ratio_exchange;
		},
		send_transaction: function (key) {
			this.secret_key = key;
			this.progress = true;
			this.etat_transaction = "";

			try {
				// --------- INITIAlISATION

				if (this.blockchain == "test") {
					var server = new StellarSdk.Server("https://horizon-testnet.stellar.org");
					var net_pass = StellarSdk.Networks.TESTNET;
				}

				if (this.blockchain == "public") {
					var server = new StellarSdk.Server("https://horizon.stellar.org");
					var net_pass = StellarSdk.Networks.PUBLIC;
				}

				// ---------- PARAMETRE DE LA TRANSACTION

				var destination_keys = StellarSdk.Keypair.fromSecret(this.secret_key);

				// --------------- RECUPERATION DE l'ID DU POOL

				const poolId = this.pool_id;

				const upward = parseFloat(this.upward) / 100
				const downward = parseFloat(this.downward) / 100

				// ---------- PARAMETRE DE LA TRANSACTION

				const depositA = this.quantity.toString();
				const depositB = this.price.toString();

				const exactPrice = depositA / depositB;
				const minPrice = exactPrice - (exactPrice * downward);
				const maxPrice = exactPrice + (exactPrice * upward);
				// ---------- SECURITE

				this.secret_key = "";

				// --------- TRANSACTION


				server
					.loadAccount(destination_keys.publicKey())
					.then(function (receiver) {
						var transaction = new StellarSdk.TransactionBuilder(receiver, {
								fee: StellarSdk.BASE_FEE,
								networkPassphrase: net_pass,
							})
							.addOperation(
								StellarSdk.Operation.liquidityPoolDeposit({
									liquidityPoolId: poolId,
									maxAmountA: depositA,
									maxAmountB: depositB,
									minPrice: minPrice.toFixed(7),
									maxPrice: maxPrice.toFixed(7),
								}),
							)
							.setTimeout(100)
							.build();
						transaction.sign(destination_keys);
						return server.submitTransaction(transaction);
					})
					.then(response => {
						if (response != undefined) this.transaction = response;
						this.progress = false;
						this.etat_transaction = "success";
						this.dialog = false;
					})
					.catch(error => {
						this.progress = false;
						this.etat_transaction = "echec de la transaction";
						if (error.response != undefined) this.error_why = error.response.data.extras.result_codes.operations;
						else this.error_why =(error.message)
					});


			} catch (error) {
				this.etat_transaction = "echec software";
				this.error_data = error;
				this.progress = false;
			}
		},

		calcul_ratio() {
			this.pool_id = this.pool.id
			this.balance = this.pool.balance

			let assetA = this.pool.assets[0]
			let assetB = this.pool.assets[1]

			let ratio = "<br/>1 " + assetB.asset_code + " = " + (assetA.amount / assetB.amount).toString() + " " + assetA.asset_code;
			ratio = ratio + " <br/> 1 " + assetA.asset_code + " = " + (assetB.amount / assetA.amount).toString() + " " + assetB.asset_code;
			if (assetA.asset_code == this.asset_code_fceuro) this.ratio_exchange = assetA.amount / assetB.amount;
			if (assetB.asset_code == this.asset_code_fceuro) this.ratio_exchange = assetB.amount / assetA.amount;

			this.ratio = 'Pool de liquidité <br/><b>' + this.monetary_aspect(assetA.amount) + ' ' + assetA.asset_code + "</b> & <b>" + this.monetary_aspect(assetB.amount) + ' ' + assetB.asset_code + " </b>" + ratio;
			this.asset_code = assetA.asset_code;
			this.asset_code_fceuro = assetB.asset_code;
			this.asset_issuer = assetA.asset_issuer;
			this.asset_issuer_fceuro = assetB.asset_issuer;
		}
	},
	watch: {
		pool() {
			this.calcul_ratio();
		},

		dialog: function () {
			if (!this.dialog) {
				this.$emit('close', this.etat_transaction)
				this.etat_transaction = undefined
				this.error_data = undefined
				this.error_extras = undefined
				this.error_why = undefined
				this.transaction = undefined
			}
		}

	}
}
</script>

<style scoped>
</style>

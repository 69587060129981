<template>
<div>
	<div class="ma-auto position-relative mt-8"
			 style="max-width: 380px">
		<v-card class="ma-1 pa-3 pb-8"
						elevation=1
						v-show="publickey_temp.length != 56">
			<img src="@/assets/secure.png"
					 style="width:100%">

			<v-dialog transition="dialog-bottom-transition"
								v-model="dialog2"
								max-width="600">
				<template v-slot:activator="{ on, attrs }">
					<v-icon v-bind="attrs"
									large
									class="mt-11"
									style="float:right; border:1px solid gray; border-radius:2px;"
									v-on="on">
						mdi-qrcode
					</v-icon>
				</template>
				<v-card>
					<v-toolbar color="primary"
										 dark>Scanner le QR Code</v-toolbar>
					<v-card-text>
						<p class="error">{{ error }}</p>
						<qrcode-stream @decode="onDecode"
													 @init="onInit" />
					</v-card-text>
					<v-card-actions class="justify-end">
						<v-btn icon
									 @click="dialog2 = false">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-text-field v-model="publickey_temp"
										class="ma-1 mt-8"
										:label="label"
										:placeholder="label"></v-text-field>
			<b>
				<v-checkbox v-model="noaccount"
										class="pb-4 mt-6"
										label="Nouveau sur Fraction ?"
										color="indigo darken-3"
										value="false"
										hide-details></v-checkbox>
			</b>
		</v-card>
		<v-card elevation=2
						class="pa-2"
						v-show="publickey_temp.length == 56">
			<b>
				<v-checkbox v-model="pa"
										label="Replacer la clé privée par un PIN"
										color="indigo darken-3"
										value="false"
										hide-details></v-checkbox>
			</b>
			<br />
			<div v-show="pa">
				<span> {{add_code_secret}}</span>

				<v-dialog transition="dialog-bottom-transition"
									v-model="dialog3"
									max-width="600">
					<template v-slot:activator="{ on, attrs }">
						<v-icon v-bind="attrs"
										large
										class="mt-9"
										style="float:right; border:1px solid gray; border-radius:2px;"
										v-on="on">
							mdi-qrcode
						</v-icon>
					</template>
					<v-card>
						<v-toolbar color="primary"
											 dark>Scanner le QR Code</v-toolbar>
						<v-card-text>
							<p class="error">{{ error }}</p>
							<qrcode-stream @decode="onDecode2"
														 @init="onInit" />
						</v-card-text>
						<v-card-actions class="justify-end">
							<v-btn icon
										 @click="dialog3 = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-text-field v-model="privatekey"
											label="Clé privée"
											clearable></v-text-field>
				<i style="font-size:95%"
					 class="mt-0 pt-0"> <b class="red--text">NE JAMAIS</b> mettre sa clé privée sur un téléphone ou un ordinateur qui n'est pas exclusivement le votre.
				</i>
				<br />
				<br />
				<span> {{add_code_pin}}</span>
				<v-otp-input v-model="otp"
										 @finish="onFinish"></v-otp-input>
				<i style="font-size:95%"
					 class="mt-0 pt-0">
					Le code pin remplace la clé privée comme signature.
				</i>
				<br />
				<b>
					<v-checkbox v-model="da"
											v-show="otp.length==6"
											label="Double authentification"
											color="indigo darken-3"
											value="false"
											hide-details></v-checkbox>
				</b>
				<div v-show="da">
					<br /> <i style="font-size:95%"
						 class="mt-0 pt-0">Pour activer la double authentification, scanner ce code dans FreeOTP ou Google Autentificator</i>
					<br />
					<v-icon style="float:right;"
									class="mt-3"
									@click="copyToClipBoard(secret.base32)">mdi-content-copy
					</v-icon>

					<span style="font-size:60%"> {{secret.base32}} </span>
					<br /> <br />
					<div class="mx-auto"
							 style="text-align:center;">
						<qrcode-vue :value="url"></qrcode-vue>
					</div>
					<br />
					Saisir le code de vérification
					<v-otp-input v-model="otp2"
											 @finish="onFinish2"></v-otp-input>
				</div>
			</div>
			<v-btn class="mt-3"
						 block
						 @click="ouvrir('dashboard')"
						 color="success">Tableau de bord
			</v-btn>
		</v-card>
		<div v-if="publickey_temp == ''">
			<v-card class="ma-1 pb-12 pa-2 pt-0"
							v-show="noaccount">
				<v-row>
					<v-col cols="12">
						<br /><br />
						Vous pouvez renseigner des données KYC/AML
						<v-btn class="mt-3"
									 block
									 @click="ouvrir('ouverture')"
									 color="success">Identification KYC/AML
						</v-btn>
						<br />
						Ou obtenir des clés stellar avec le wallet officiel
						<br /><br />
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="4">
						<v-btn text>
							<a style="text-decoration:none;"
								 href="https://itunes.apple.com/us/app/solar-stellar-wallet/id1458490218?mt=8">
								<img class="mx-auto"
										 style="width:60px"
										 src="@/assets/apple.svg"
										 alt="Apple iOS">
							</a>
						</v-btn>
					</v-col>
					<v-col cols="4">
						<v-btn text>
							<a style="text-decoration:none;"
								 href="https://play.google.com/store/apps/details?id=io.solarwallet">
								<img class="mx-auto"
										 style="width:60px"
										 src="@/assets/android.svg"
										 alt="Android">
							</a>
						</v-btn>
					</v-col>
					<v-col cols="4">
						<v-btn text>
							<a style="text-decoration:none;"
								 href="hhttps://github.com/satoshipay/solar/releases/download/v0.28.1/Solar.Wallet.Setup.0.28.1.exe">
								<img class="mx-auto"
										 style="width:60px"
										 src="@/assets/windows.svg"
										 alt="Android">
							</a>
						</v-btn>
					</v-col>
					<v-col cols="12">
						<v-btn class="mt-3"
									 block
									 color="success">
							<a target="_blank"
								 style="text-decoration:none;color:white"
								 href="https://solarwallet.io/">le Wallet officiel de Stellar</a>
						</v-btn>
					</v-col>
				</v-row>
			</v-card>
		</div>
	</div>
</div>
</template>
<script>
const CryptoJS = require("crypto-js");

import speakeasy from "@levminer/speakeasy"
import QrcodeVue from 'qrcode.vue'
import {
	QrcodeStream,
	QrcodeDropZone,
	QrcodeCapture
} from 'vue-qrcode-reader'

export default {
	name: 'import_account',
	components: {
		QrcodeStream,
		QrcodeDropZone,
		QrcodeCapture,
		QrcodeVue
	},
	props: {
		stellarkey: String,
		pin: {
			type: String,
			default: ""
		},
	},
	data: () => ({
		add_code_secret: "Saisir la clé privée",
		publickey: undefined,
		publickey_temp: "",
		privatekey: "",
		validate: "Valider",
		add_code_pin: "Saisir un code PIN de 6 chiffres",
		label: "Clé publique",
		otp: '',
		otp2: '',
		code_state: undefined,
		secret: '',
		token: '',
		url: '',
		da: false,
		pa: false,
		noaccount: false,
		user: [],
		dialog2: false,
		dialog3: false,
		error: '',
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		//	this.publickey_temp = "GAJQPAIJAHMQHJZQ622SOHZF3NOCLSSAC5ZPHJCCIIJSJFR7E4H7UIXO"
		//	this.publickey = "GAJQPAIJAHMQHJZQ622SOHZF3NOCLSSAC5ZPHJCCIIJSJFR7E4H7UIXO"

		// Generate a secret key.
		this.secret = speakeasy.generateSecret()

		this.user.two_factor_temp_secret = this.secret.base32;
		this.user.two_factor_enabled = false;
		this.url = speakeasy.otpauthURL({
			secret: this.secret.ascii,
			label: "Wallet@Fraction.re"
		})
		//console.log(this.url)

		//	this.url = this.secret.otpauth_url
		//console.log(this.url)

	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {
		onDecode(result) {
			this.publickey_temp = result
			this.dialog2 = false;
		},
		onDecode2(result) {
			this.privatekey = result
			this.dialog3 = false;
		},

		async onInit(promise) {
			try {
				await promise
			} catch (error) {
				if (error.name === 'NotAllowedError') {
					this.error = "ERROR: Pas de permission pour la caméra"
				} else if (error.name === 'NotFoundError') {
					this.error = "ERROR: Pas de caméra"
				} else if (error.name === 'NotSupportedError') {
					this.error = "ERROR: secure context required (HTTPS, localhost)"
				} else if (error.name === 'NotReadableError') {
					this.error = "ERROR: La caméra semble déjà en cours d'utilisation"
				} else if (error.name === 'OverconstrainedError') {
					this.error = "ERROR: installed cameras are not suitable"
				} else if (error.name === 'StreamApiNotSupportedError') {
					this.error = "ERROR: Stream API is not supported in this browser"
				} else if (error.name === 'InsecureContextError') {
					this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
				} else {
					this.error = `ERROR: Camera error (${error.name})`;
				}
			}

		},

		ouvrir(param) {
			if (param == "dashboard") {
				if (this.publickey.length == 56) {

					let privatekey = "";
					let totp = "";

					if (this.privatekey != "") privatekey = CryptoJS.AES.encrypt(this.privatekey, this.otp).toString()
					if (this.user.two_factor_enabled) totp = CryptoJS.AES.encrypt(this.user.two_factor_secret, this.otp).toString();


					window.localStorage.setItem('start', JSON.stringify({
						publickey: this.publickey,
						privatekey: privatekey,
						totp: totp,
						pin: this.hash_key(this.otp),
					}));
					this.otp = "";
					this.ot2 = "";
					this.privatekey = "";
					this.code_state = true;
				}
			}
			this.$emit('changepage', param)
		},
		saveFile: function () {},

		onFinish() {
			if (this.privatekey.length == 56)
				this.$store.commit('update_privatekey', CryptoJS.AES.encrypt(this.privatekey, this.otp).toString());

			this.$store.commit('update_pin', this.hash_key(this.otp));
			//		this.ouvrir('dashboard')
		},
		onFinish2() {
			var userToken = this.otp2;
			var base32secret = this.user.two_factor_temp_secret;
			var verified = speakeasy.totp.verify({
				secret: base32secret,
				encoding: 'base32',
				token: userToken
			});
			//console.log("verified", verified)
			if (verified) {
				this.user.two_factor_secret = this.user.two_factor_temp_secret;
				this.user.two_factor_enabled = true
				this.$store.commit('update_totp', CryptoJS.AES.encrypt(this.user.two_factor_secret, this.otp).toString());
				this.ouvrir('dashboard')
			}
		}
	},
	watch: {
		publickey_temp() {
			if (this.publickey_temp == "demo") {
				this.$store.commit('update_blockchain', "test");
				this.publickey_temp = this.$store.state.demo_publickey;
				this.privatekey = this.$store.state.demo_privatekey;
				this.publickey = this.publickey_temp;
				this.otp = "000000";
				this.onFinish();
				this.ouvrir("dashboard");
			}
			if (this.publickey_temp.length == 56) {
				this.publickey = this.publickey_temp;
				this.$store.commit('update_publickey', this.publickey);
				// Access using secret.ascii, secret.hex, or secret.base32.

				this.token = speakeasy.totp({
					secret: this.secret.base32,
					encoding: "base32",
				})
			}
		},
		stellarkey() {
			this.publickey = this.stellarkey;
		},
		code_state() {
			this.$store.commit('update_access', this.code_state);
		},
		otp() {

		},
	}
}
</script>

<style scoped>
</style>

<template>
<div>
	<v-alert class="pa-1"
					 icon="mdi-finance"
					 color="teal lighten-4"
					 @click="close('success')">Vente </v-alert>
	<v-row no-gutters>
		<v-col cols=12>
			<listbalance :show_total="false"
									 ref="balance"
									 :label="label_token"
									 color="green darken-4"
									 filter=""
									 :select_key="publickey"
									 @click_asset="click_asset" />

		</v-col>
	</v-row>
	<v-row no-gutters>
		<v-col cols=12>
			<stellarlistsellorder ref="sellorder"
														:select_key="publickey"
														@click_asset="click_order" />

			<div v-show="false">
				<stellarsell :block="true"
										 css=" "
										 label="ordre de vente "
										 :asset_code="asset_code"
										 :asset_issuer="asset_issuer"
										 ref="sell"
										 :asset_limit="limit_asset"
										 @close="close"
										 :id_transaction="id_transaction.toString()"
										 :unit_price="unit_price.toString()"
										 :amount="amount.toString()" />
			</div>


		</v-col>
	</v-row>
	<v-bottom-sheet v-model="sheet">
		<v-container style="max-width:1280px"
								 class="mx-auto  white">
			<v-row no-gutters>
				<v-col cols=12
							 class="pa-3">
					Vendre : &nbsp; <b> {{asset_code}}</b>
				</v-col>
				<v-col cols=12
							 v-if="balance_asset==0"
							 xl=6
							 lg=6
							 md=6
							 sm=6
							 class="pa-3">

					<stellardeletetrustline :block="true"
																	css=" "
																	label="Suppression du token "
																	:asset_code="asset_code"
																	:asset_issuer="asset_issuer"
																	@close="close" />

				</v-col>
				<v-col cols=12
							 xl=6
							 lg=6
							 md=6
							 v-if="balance_asset>0"
							 sm=6
							 class="pa-3">

					<stellarsell :block="true"
											 css=" "
											 label="ordre de vente "
											 :asset_code="asset_code"
											 :asset_issuer="asset_issuer"
											 ref="sellsell"
											 :asset_limit="limit_asset"
											 @close="close"
											 :id_transaction="id_transaction.toString()"
											 :unit_price="unit_price.toString()"
											 :amount="amount.toString()" />
				</v-col>
				<v-col cols=12
							 xl=6
							 lg=6
							 md=6
							 v-if="balance_asset>0"
							 sm=6
							 class="pa-3">
					<stellarpaymentstrictsend :automatic="true"
																		:block="true"
																		css=""
																		label="Vente directe (AMM)"
																		:asset_code="asset_code"
																		:asset_issuer="asset_issuer"
																		:asset_balance="balance_asset"
																		:asset_limit="limit_asset"
																		type_transaction="amm_sell"
																		@close="close" />
				</v-col>
			</v-row>
		</v-container>
	</v-bottom-sheet>
</div>
</template>



<script>
import listbalance from '../components/stellar_list_balance.vue'
import stellarsell from "@/components/stellar_sell.vue";
import stellarlistsellorder from '../components/stellar_list_sell_order.vue'
import stellarpaymentstrictsend from "@/components/stellar_payment_strict_send.vue";
import stellardeletetrustline from "@/components/stellar_delete_trustline.vue";

export default {
	name: 'vente',
	components: {
		listbalance,
		stellarsell,
		stellarlistsellorder,
		stellarpaymentstrictsend,
		stellardeletetrustline
	},
	props: {},
	data: () => ({
		balance_asset: undefined,
		sheet: false,
		asset_code: undefined,
		asset_issuer: undefined,
		limit_asset: undefined,
		unit_price: 0,
		id_transaction: "0",
		amount: 1,
		label_token: 'Tokens disponibles pour la vente'
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		publickey() {
			return this.$store.state.publickey
		},

	},
	methods: {
		close(etat) {
			if (etat == "success") this.$emit('changepage', "dashboard")

		},
		click_asset(item) {
			this.asset_code = item.asset_code;
			this.asset_issuer = item.asset_issuer;
			this.limit_asset = item.limit;
			this.unit_price = item.unitfceuro; //Math.ceil(parseFloat(item.unitfceuro) * 100) / 100;
			this.sheet = true;
			this.balance_asset = item.balance;
		},

		click_order(item) {
			console.log(item)
			this.id_transaction = item.id
			this.asset_code = item.selling.asset_code;
			this.asset_issuer = item.selling.asset_issuer;

			if (item.selling.asset_type == "native")
				this.asset_code = "XLM"
			this.limit_asset = item.limit;
			this.unit_price = item.price;
			this.amount = (parseFloat(item.amount));
			this.$refs.sell.activate();
		}
	},
	watch: {}
}
</script>

<style scoped>
</style>

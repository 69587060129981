<template>
<v-dialog v-model="dialog"
					fullscreen
					hide-overlay
					transition="dialog-bottom-transition">
	<template v-slot:activator="{ on, attrs }">
		<v-btn class="px-5"
					 color="primary"
					 :block="block"
					 dark
					 v-bind="attrs"
					 :class="css"
					 v-on="on">
			{{label}}
		</v-btn>
	</template>
	<v-card>
		<v-toolbar dark
							 color="primary">
			<v-btn icon
						 dark
						 @click="dialog = false">
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<b class="text-h6"> Parrainage </b>


		</v-toolbar>


		<v-row class="mx-5 mt-5">
			<v-col cols=12>

				<v-card class="mx-auto"
								elevation=0>

					<div class="ma-3  ">
						<v-text-field v-if="false"
													class="my-5"
													v-model="manage_name"
													label="Name"></v-text-field>

						<v-dialog transition="dialog-bottom-transition"
											v-model="dialog2"
											max-width="600">
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs"
												large
												class="mt-3"
												style="float:right; border:1px solid gray; border-radius:2px;"
												v-on="on">
									mdi-qrcode
								</v-icon>
							</template>
							<v-card>
								<v-toolbar color="primary"
													 dark>Scanner le QR Code</v-toolbar>
								<v-card-text>
									<p class="error">{{ error }}</p>
									<qrcode-stream @decode="onDecode"
																 @init="onInit" />
								</v-card-text>
								<v-card-actions class="justify-end">
									<v-btn icon
												 @click="dialog2 = false">
										<v-icon>mdi-close</v-icon>
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>

						<v-text-field class="my-5"
													counter
													maxlength="56"
													v-model="manage_value"
													label="Clé publique du parrain"></v-text-field>

					</div>




				</v-card>

				<v-card class="mx-auto"
								elevation=0>

					<v-card-text class="pa-5 text-h">
						<b>{{manage_name}}</b> = <b>{{manage_value}}</b>
					</v-card-text>
				</v-card>

				<validbutton :etat_transaction="etat_transaction"
										 :error_why="error_why"
										 :progress="progress"
										 @valid="send_transaction" />

			</v-col>

		</v-row>
	</v-card>
</v-dialog>
</template>


<script>
var StellarSdk = require('stellar-sdk');
import {
	QrcodeStream,
	QrcodeDropZone,
	QrcodeCapture
} from 'vue-qrcode-reader'
import validbutton from '@/components/valid_button.vue'

export default {
	name: 'stellar_manage_data',
	components: {
		QrcodeStream,
		QrcodeDropZone,
		QrcodeCapture,
		validbutton
	},
	props: {


		block: {
			type: Boolean,
			default: false
		},
		css: String,
		label: String

	},
	data: () => ({

		manage_name: "sponsor",
		manage_value: "",
		dialog: false,
		dialog2: false,
		error: '',

		secret_key: '',
		etat_transaction: undefined,
		error_data: undefined,
		error_why: undefined,
		transaction: undefined,
		progress: false,

	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		privatekey() {
			return this.$store.state.privatekey
		},
		pin() {
			return this.$store.state.pin
		},
		blockchain() {
			return this.$store.state.blockchain
		},
		account_sign() {
			return this.$store.state.publickey
		},

	},
	methods: {

		onDecode(result) {
			this.manage_value = result
			this.dialog2 = false
		},

		async onInit(promise) {
			try {
				await promise
			} catch (error) {
				if (error.name === 'NotAllowedError') {
					this.error = "ERROR: Pas de permission pour la caméra"
				} else if (error.name === 'NotFoundError') {
					this.error = "ERROR: Pas de caméra"
				} else if (error.name === 'NotSupportedError') {
					this.error = "ERROR: secure context required (HTTPS, localhost)"
				} else if (error.name === 'NotReadableError') {
					this.error = "ERROR: La caméra semble déjà en cours d'utilisation"
				} else if (error.name === 'OverconstrainedError') {
					this.error = "ERROR: installed cameras are not suitable"
				} else if (error.name === 'StreamApiNotSupportedError') {
					this.error = "ERROR: Stream API is not supported in this browser"
				} else if (error.name === 'InsecureContextError') {
					this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
				} else {
					this.error = `ERROR: Camera error (${error.name})`;
				}
			}

		},
		send_transaction: function (key) {
			this.secret_key = key;
			this.progress = true;
			try {

				const name = this.manage_name;
				const value = this.manage_value;


				var destination_keys = StellarSdk.Keypair.fromSecret(this.secret_key);

				// --------- INITIAlISATION

				if (this.blockchain == "test") {
					var server = new StellarSdk.Server("https://horizon-testnet.stellar.org");
					var net_pass = StellarSdk.Networks.TESTNET;
				}

				if (this.blockchain == "public") {
					var server = new StellarSdk.Server("https://horizon.stellar.org");
					var net_pass = StellarSdk.Networks.PUBLIC;
				}

				// --------- SECURTIE : Effacement de la clé secrete

				this.secret_key = "";


				server
					.loadAccount(destination_keys.publicKey())
					.then(function (receiver) {
						var transaction = new StellarSdk.TransactionBuilder(receiver, {
								fee: 100,
								networkPassphrase: net_pass,
							})
							.addOperation(
								StellarSdk.Operation.manageData({
									name: name,
									value: value,
								}),
							)
							.setTimeout(100)
							.build();
						transaction.sign(destination_keys);
						return server.submitTransaction(transaction);
					})
					.then(response => {
						if (response != undefined) this.transaction = response;
						this.progress = false;
						this.etat_transaction = "success";
						this.dialog = false;
					})
					.catch(error => {
						this.progress = false;
						this.etat_transaction = "echec de la transaction";
						if (error.response != undefined) this.error_why = error.response.data.extras.result_codes.operations;
						else this.error_why = (error.message)
					});


			} catch (error) {
				this.etat_transaction = "echec software";
				this.error_data = error;
				this.progress = false;
			}
		},

	},
	watch: {
		account_sign: function () {
			this.to = this.account_sign;
		},
		manage_value: function () {
			if (this.manage_value == this.account_sign) this.manage_value = "Ne pas mettre votre propre clé";

		},
		dialog: function () {
			if (!this.dialog) {
				this.$emit('close', this.etat_transaction)
				this.etat_transaction = undefined
				this.error_data = undefined

				this.error_why = undefined
				this.transaction = undefined
			}
		}

	}
}
</script>

<style scoped>
</style>

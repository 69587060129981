<template>
<v-dialog v-model="dialog"
					fullscreen
					hide-overlay
					transition="dialog-bottom-transition">
	<template v-slot:activator="{ on, attrs }">
		<v-btn class="px-5"
					 color="primary"
					 dark
					 v-bind="attrs"
					 v-on="on"
					 :block=block
					 :class="css"
					 :small="small">
			{{label}}
		</v-btn>
	</template>
	<v-card>
		<v-toolbar dark
							 color="primary">
			<v-btn icon
						 dark
						 @click="dialog = false">
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<b class="text-h6"> Retrait d'un pool de liquidité </b>
		</v-toolbar>


		<v-row class="mx-1 mt-2">
			<v-col cols=12>

				<listbalance :unit_only=false
										 :short=false
										 color="green darken-4"
										 filter="XLM "
										 :show_nft=false
										 ref="balance"
										 :select_key="account_sign"
										 label="Votre portefeuille d'actifs" />

			</v-col>


			<v-col cols=12>

				<v-card elevation=0>


					<v-text-field class="my-5"
												v-model="amount"
												label="Nombre de parts"></v-text-field>
					<v-text-field class="my-5"
												v-model="mina"
												:label="'Optionnel : Quantité minimale de '+ asset_code"></v-text-field>
					<v-text-field class="my-5"
												v-model="minb"
												:label="'Optionnel : Quantité minimale de '+ asset_code_fceuro"></v-text-field>




					<span v-html="ratio" />
					<br /> <br />
					Retrait de
					<b class="red--text">{{amount}}</b> parts de <b class="red--text">{{asset_code}} / {{asset_code_fceuro}}</b>
					<br />Balance actuelle : <b v-html="monetary_aspect(balance)" />
					<br />Solde après retrait : <b v-html="monetary_aspect(balance-amount)" />


				</v-card>

				<validbutton :etat_transaction="etat_transaction"
										 :error_why="error_why"
										 :progress="progress"
										 @valid="send_transaction" />
			</v-col>
		</v-row>
	</v-card>
</v-dialog>
</template>


<script>
var StellarSdk = require('stellar-sdk');

import BigNumber from 'bignumber.js'
import listliquiditypool from "@/components/stellar_list_liquidity_pool.vue";
import listbalance from "@/components/stellar_list_balance.vue";
import validbutton from '@/components/valid_button.vue'


export default {
	name: 'stellar_create_liquidity_pool_widthdraw',
	components: {
		listliquiditypool,
		listbalance,
		validbutton
	},
	props: {
		css: String,
		small: String,

		label: {
			type: String,
			default: "retrait"
		},

		pool: undefined,
		block: {
			type: Boolean,
			default: false
		}


	},
	data: () => ({

		pool_id: undefined,
		operation_response: [],

		label_secret: "Saisir votre clé secréte",
		asset_code: undefined,
		asset_issuer: undefined,
		balance: 0,
		asset_issuer_fceuro: "",
		asset_type_fceuro: "",
		asset_balance_fceuro: "",
		mina: '0',
		minb: '0',
		from: '',
		dialog: false,
		ratio: "",
		ratio_exchange: 1,
		account_response: [],
		amount: '1',

		asset_code_fceuro: undefined,


		secret_key: '',
		etat_transaction: undefined,
		error_data: undefined,

		error_why: undefined,
		transaction: undefined,
		progress: false,


		ref_transaction: 0,
		otp: "",
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {
		this.calcul_ratio();
	},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {

		blockchain() {
			return this.$store.state.blockchain
		},
		account_sign() {
			return this.$store.state.publickey
		},

		distributor_account() {
			return this.$store.getters.distributor_account
		},
		pin() {
			return this.$store.state.pin
		},
		privatekey() {
			return this.$store.state.privatekey
		},

	},
	methods: {

		onFinish() {
			if (this.pin == this.hash_key(this.otp)) this.secret_key = this.decrypt_key(this.otp); //this.privatekey;

		},


		send_transaction: function (key) {
			this.secret_key = key;
			this.progress = true;
			this.etat_transaction = "";

			try {
				// --------- INITIAlISATION

				if (this.blockchain == "test") {
					var server = new StellarSdk.Server("https://horizon-testnet.stellar.org");
					var net_pass = StellarSdk.Networks.TESTNET;
				}

				if (this.blockchain == "public") {
					var server = new StellarSdk.Server("https://horizon.stellar.org");
					var net_pass = StellarSdk.Networks.PUBLIC;
				}

				// ---------- PARAMETRE DE LA TRANSACTION

				var destination_keys = StellarSdk.Keypair.fromSecret(this.secret_key);

				// --------------- RECUPERATION DE l'ID DU POOL

				const poolId = this.pool_id;
				const amount = this.amount.toString()

				const minAmountA = this.mina.toString()
				const minAmountB = this.minb.toString()


				// ---------- SECURITE

				this.secret_key = "";

				// --------- TRANSACTION


				server
					.loadAccount(destination_keys.publicKey())
					.then(function (receiver) {
						var transaction = new StellarSdk.TransactionBuilder(receiver, {
								fee: StellarSdk.BASE_FEE,
								networkPassphrase: net_pass,
							})
							.addOperation(
								StellarSdk.Operation.liquidityPoolWithdraw({
									liquidityPoolId: poolId,
									amount: amount,
									minAmountA: minAmountA,
									minAmountB: minAmountB,

								}),
							)
							.setTimeout(100)
							.build();
						transaction.sign(destination_keys);
						return server.submitTransaction(transaction);
					})
					.then(response => {
						if (response != undefined) this.transaction = response;
						this.progress = false;
						this.etat_transaction = "success";
						this.dialog = false;

					})
					.catch(error => {
						this.progress = false;
						this.etat_transaction = "echec de la transaction";
						if (error.response != undefined) this.error_why = error.response.data.extras.result_codes.operations;
						else this.error_why =(error.message)


					});


			} catch (error) {
				this.etat_transaction = "echec software";
				this.error_data = error;
				this.progress = false;
			}
		},
		calcul_ratio() {
			this.pool_id = this.pool.id
			this.balance = this.pool.balance

			let assetA = this.pool.assets[0]
			let assetB = this.pool.assets[1]

			let ratio = "<br/>1 " + assetB.asset_code + " = " + (assetA.amount / assetB.amount).toString() + " " + assetA.asset_code;
			ratio = ratio + " <br/> 1 " + assetA.asset_code + " = " + (assetB.amount / assetA.amount).toString() + " " + assetB.asset_code;
			if (assetA.asset_code == this.asset_code_fceuro) this.ratio_exchange = assetA.amount / assetB.amount;
			if (assetB.asset_code == this.asset_code_fceuro) this.ratio_exchange = assetB.amount / assetA.amount;

			this.ratio = 'Pool de liquidité <br/><b>' + this.monetary_aspect(assetA.amount) + ' ' + assetA.asset_code + "</b> & <b>" + this.monetary_aspect(assetB.amount) + ' ' + assetB.asset_code + " </b>" + ratio;
			this.asset_code = assetA.asset_code;
			this.asset_code_fceuro = assetB.asset_code;
			this.asset_issuer = assetA.asset_issuer;
			this.asset_issuer_fceuro = assetB.asset_issuer;
		},
	},
	watch: {

		pool() {
			this.calcul_ratio();

		},
		dialog: function () {
			if (!this.dialog) {
				this.$emit('close', this.etat_transaction)
				this.etat_transaction = undefined
				this.error_data = undefined
				this.error_why = ""
				this.transaction = undefined
			}
		}

	}
}
</script>

<style scoped>
</style>

<template>
<v-dialog v-model="dialog"
					fullscreen
					hide-overlay
					transition="dialog-bottom-transition">
	<template v-slot:activator="{ on, attrs }">
		<v-btn class="px-5"
					 color="primary"
					 :block="block"
					 dark
					 v-bind="attrs"
					 v-on="on"
					 :class="css"
					 :small="small">
			{{label}}
		</v-btn>
	</template>
	<v-card>
		<v-toolbar dark
							 color="primary">
			<v-btn icon
						 dark
						 @click="dialog = false">
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<b class="text-h6"> Vendre {{asset_code}} </b>


		</v-toolbar>



		<v-row class="mx-5 mt-2">
			<v-col cols=12>
				<v-card elevation=0>
					<div class="ma-3 pb-6">
						<v-text-field class="my-5"
													v-model="quantity"
													label="Quantité"></v-text-field>
						<v-text-field class="my-5"
													v-model="price"
													label="Prix unitaire"></v-text-field>
					</div>
				</v-card>



				<v-card elevation=0>

					<v-card-text class="pa-5 text-h6">
						<div v-if="id_transaction > 0">
							<b class="py-3 outlined"> Modification de l'ordre de vente numéro : {{id_transaction}} </b>
							<br /><br />
						</div>
						<div v-if="asset_code != 'fcEURO'">
							Vendre <b class="red--text"
								 v-html="monetary_aspect(quantity)" /> <code>{{asset_code}}</code>
							<br /> à <b class="red--text"
								 v-html="monetary_aspect(price)" /> <code>{{asset_code_fceuro}} </code> chacun
							<br />
							<br />
							Recevoir: <b class="red--text"
								 v-html="monetary_aspect(quantity * price)" /> <code>{{asset_code_fceuro}} </code>
						</div>
						<div v-if="asset_code == 'fcEURO'">
							Vendre <b class="red--text"
								 v-html="monetary_aspect(quantity)" /> <code>{{asset_code}} </code>
							<br /> à <b class="red--text"
								 v-html="monetary_aspect(price)" /> <code>Lumens (XLM)</code> chacun
							<br />
							<br />
							Recevoir: <b class="red--text"
								 v-html="monetary_aspect(quantity * price)" /> <code>Lumens (XLM) </code>
						</div>

					</v-card-text>

				</v-card>

				<validbutton :etat_transaction="etat_transaction"
										 :error_why="error_why"
										 :progress="progress"
										 @valid="send_transaction" />
			</v-col>

		</v-row>
	</v-card>
</v-dialog>
</template>


<script>
var StellarSdk = require('stellar-sdk');
import validbutton from '@/components/valid_button.vue'


export default {
	name: 'stellar_buy',
	components: {
		validbutton
	},
	props: {
		css: String,
		small: String,
		label: {
			type: String,
			default: "buy"
		},

		asset_code: String,
		asset_issuer: String,
		asset_balance: String,
		asset_limit: String,

		euro_balance: String,

		id_transaction: {
			type: String,
			default: "0"
		},

		unit_price: {
			type: String,
			default: "0"
		},

		amount: {
			type: String,
			default: "0"
		},

		block: {
			type: Boolean,
			default: false
		},
		passive: false,
	},
	data: () => ({

		asset_issuer_fceuro: "",
		label_secret: "Saisir votre clé secréte",
		from: '',
		dialog: false,
		quantity: 1,
		to: undefined,
		secret_key: '',
		etat_transaction: undefined,
		error_data: undefined,
		error_extras: undefined,
		error_why: undefined,
		transaction: undefined,
		progress: false,
		price: '0',
		ref_transaction: 0,
		otp: "",
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		let tempprix = this.unit_price;
		if (this.asset_code == "fcEURO") tempprix = this.cours_XML_inverse;

		if (this.unit_price != undefined) this.price = tempprix;

		if (this.id_transaction != undefined) this.ref_transaction = this.id_transaction;
		//	if (this.unit_price != undefined) this.price = this.unit_price;
		if (this.amount != undefined) this.quantity = this.amount;

		this.asset_issuer_fceuro = this.$store.getters.master_account;

	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {

		asset_code_fceuro() {
			return this.$store.state.asset_code_fceuro
		},
		blockchain() {
			return this.$store.state.blockchain
		},
		pin() {
			return this.$store.state.pin
		},
		privatekey() {
			return this.$store.state.privatekey
		},
		cours_XML_inverse() {
			return this.$store.state.cours_XML_inverse
		},
	},
	methods: {
		onFinish() {
			if (this.pin == this.hash_key(this.otp)) this.secret_key = this.decrypt_key(this.otp);
		},

		activate: function () {
			this.dialog = true;
		},
		send_transaction: function (key) {
			this.secret_key = key;
			this.progress = true;
			this.etat_transaction = "";
			try {

				let i = 0;
				//console.log("1")
				// ---------- CREATION DES TOKENS A ECHANGER
				var asset_issuer_key = "";
				if (this.asset_code != "XLM")
					asset_issuer_key = StellarSdk.Keypair.fromPublicKey(
						this.asset_issuer,
					);
				//console.log("2")

				var asset_issuer_fceuro_key = StellarSdk.Keypair.fromPublicKey(
					this.asset_issuer_fceuro,
				);

				if ((this.asset_code != "XLM") && (this.asset_code != "fcEURO")) {
					var selling = new StellarSdk.Asset(this.asset_code, asset_issuer_key.publicKey());
					var buying = new StellarSdk.Asset(this.asset_code_fceuro, asset_issuer_fceuro_key.publicKey());
				}

				//console.log("3")
				if (this.asset_code == "XLM") {
					selling = StellarSdk.Asset.native();
					buying = new StellarSdk.Asset(this.asset_code_fceuro, asset_issuer_fceuro_key.publicKey());

				}

				//console.log("4")
				if (this.asset_code == "fcEURO") {
					selling = new StellarSdk.Asset(this.asset_code_fceuro, asset_issuer_fceuro_key.publicKey());
					buying = StellarSdk.Asset.native();

				}

				//console.log("5")
				// ---------- VERIFICATION DE LA PRESENCE DE fcEURO DANS LA BALANCE

				let add_trust_fc_euro = true

				this.$store.state.balances.forEach((item, i) => {
					if ((item.asset_code == this.asset_code_fceuro) && (item.asset_issuer == this.asset_issuer_fceuro)) add_trust_fc_euro = false;
				});

				// ---------- PARAMETRE DE LA TRANSACTION

				let nombre = this.quantity.toString();
				const prix_unitaire = this.price.toString();
				const id = this.id_transaction.toString();

				// ---------- SECURITE
				//console.log(this.secret_key)
				var source_keys = StellarSdk.Keypair.fromSecret(this.secret_key);
				this.secret_key = "";

				// --------- INITIAlISATION


				const trust = {
					asset: selling
				}

				let operations = [];

				if (this.asset_code != "XLM")
					operations.push(
						StellarSdk.Operation.changeTrust(trust)
					)

				if (add_trust_fc_euro)
					operations.push(
						StellarSdk.Operation.changeTrust({
							asset: buying
						})
					)

				if (!this.passive)
					operations.push(
						StellarSdk.Operation.manageSellOffer({
							selling: selling,
							buying: buying,
							amount: nombre,
							price: prix_unitaire,
							offerId: id,
						})
					)

				if (this.passive)
					operations.push(
						StellarSdk.Operation.createPassiveSellOffer({
							selling: selling,
							buying: buying,
							amount: nombre,
							price: prix_unitaire
						})
					)

				let params = {
					"source": source_keys,
					"operations": operations,
					"memo": "Sell Order by Fraction.re"
				}
				//console.log(params)

				this.$store.dispatch('stellar_transaction_sign_source', params).then(response => {
					if (response != undefined) this.transaction = response;
					this.progress = false;
					if (response.toString().substr(0, 5) == "Error") {
						this.progress = false;
						this.etat_transaction = "Echec de la transaction";
						if (response != undefined) this.error_why = response.toString().substr(6)
					} else {
						this.etat_transaction = "success";
						this.dialog = false;
					}
				})


			} catch (error) {
				this.etat_transaction = "Rejet de la transaction par le Wallet: " + error.toString().replace("TypeError: ", '');
				if (error.toString().replace("TypeError: ", '') == "Error: invalid version byte. expected 144, got undefined")
					this.etat_transaction = "Rejet par le Wallet: code secret vide ou code pin faux ou code de double vérification faux";
				this.error_data = error;
				//console.log(error)
				this.progress = false;
			}

		}
	},
	watch: {
		unit_price: function () {
			let tempprix = this.unit_price;
			if (this.asset_code == "fcEURO") tempprix = this.cours_XML_inverse;

			//console.log(this.asset_code, this.price, this.unit_price, this.cours_XML_inverse, tempprix);
			if (this.unit_price != undefined) this.price = tempprix;
			//console.log(this.asset_code, this.price, this.unit_price, this.cours_XML_inverse, tempprix);
		},
		amount: function () {
			if (this.amount != undefined) this.quantity = this.amount;
		},
		dialog: function () {
			if (!this.dialog) {
				this.$emit('close', this.etat_transaction)
				this.etat_transaction = undefined
				this.error_why = ""
				this.transaction = undefined
				this.error_data = undefined
			}
		}

	}
}
</script>

<style scoped>
</style>

<template>
<div>
	<v-alert class="pa-1"
					 icon="mdi-transfer"
					 color="teal lighten-4"
					 @click="close_transfert('success')">Transfert </v-alert>
	<v-row no-gutters>
		<v-col cols=12>
			<listbalance :show_total="false"
									 ref="balance"
									 :label="label_token"
									 color="green darken-4"
									 filter="XLM "
									 :select_key="publickey"
									 @click_asset="click_asset" />

		</v-col>
	</v-row>

	<v-bottom-sheet v-model="sheet">
		<v-container style="max-width:1280px"
								 class="mx-auto  white">
			<v-row no-gutters>
				<v-col cols=12
							 class="pa-3">
					Transfert : &nbsp; <b> {{asset_code}}</b>
				</v-col>
				<v-row>
					<v-col cols=12>
						<v-card-title class=" body-1"
													v-html="label1" />
						<v-divider class="mb-3 mt-n3 success"></v-divider>
					</v-col>

					<v-col cols=12
								 xl=6
								 lg=6
								 md=6
								 sm=6
								 class="pa-3">

						<stellarpayment css=""
														:block="true"
														@close="close_transfert"
														:asset_balance="balance_asset"
														:asset_limit="limit_asset"
														label="Paiement vers tiers"
														:asset_code="asset_code"
														:asset_issuer="asset_issuer" />
					</v-col>
					<v-col cols=12
								 xl=6
								 lg=6
								 md=6
								 sm=6
								 class="pa-3">
						<stellarclaimbalance css=""
																 :block="true"
																 @close="close_claimbalance"
																 :asset_balance="balance_asset"
																 :asset_limit="limit_asset"
																 label="Paiement à terme"
																 :asset_code="asset_code"
																 :asset_issuer="asset_issuer" />
					</v-col>
				</v-row>
				<v-row>
					<v-col cols=12>
						<v-card-title class=" body-1"
													v-html="label2" />
						<v-divider class="mb-3 mt-n3 success"></v-divider>
					</v-col>

					<v-col cols=12
								 xl=6
								 lg=6
								 md=6
								 sm=6
								 class="pa-3">
						<stellarpaymentstrictsend :block="true"
																			css=""
																			label="Garantie d'envoi "
																			:asset_code="asset_code"
																			:asset_issuer="asset_issuer"
																			:asset_balance="balance_asset"
																			:asset_limit="limit_asset" />
					</v-col>
					<v-col cols=12
								 xl=6
								 lg=6
								 md=6
								 sm=6
								 class="pa-3">

						<stellarpaymentstrictreceive css=" "
																				 :block="true"
																				 label="Garantie de reception "
																				 :asset_code="asset_code"
																				 :asset_issuer="asset_issuer"
																				 :asset_balance="balance_asset"
																				 :asset_limit="limit_asset" />
					</v-col>
				</v-row>
			</v-row>
		</v-container>
	</v-bottom-sheet>
</div>
</template>



<script>
import listbalance from '../components/stellar_list_balance.vue'
import stellarpayment from "@/components/stellar_payment.vue";
import stellarpaymentstrictsend from "@/components/stellar_payment_strict_send.vue";
import stellarpaymentstrictreceive from "@/components/stellar_payment_strict_receive.vue";
import stellarclaimbalance from "@/components/stellar_claim_balance.vue";


export default {
	name: 'transfert',
	components: {
		listbalance,
		stellarpaymentstrictreceive,
		stellarclaimbalance,
		stellarpaymentstrictsend,
		stellarpayment
	},
	props: {},
	data: () => ({
		sheet: false,
		unit_price: 0,
		label1: "Paiement direct, immédiat ou à terme",
		label2: "Paiement avec conversion de token",
		label_token: 'Tokens disponibles pour le transfert',
		asset_code: undefined,
		asset_issuer: undefined,
		limit_asset: undefined,
		balance_asset: undefined,
		asset_limit: undefined,
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		publickey() {
			return this.$store.state.publickey
		},

	},
	methods: {
		close_transfert(etat) {
			if (etat == "success") this.$emit('changepage', "dashboard")
		},
		close_claimbalance(etat) {
			if (etat == "success") this.$emit('changepage', "dashboard")

		},

		click_asset(item) {
			this.asset_code = item.asset_code;
			this.asset_issuer = item.asset_issuer;
			this.limit_asset = item.limit;
			this.unit_price = Math.ceil(parseFloat(item.unitfceuro) * 100) / 100;
			this.sheet = true;
			this.balance_asset = item.balance;
		},


	},
	watch: {}
}
</script>

<style scoped>
</style>

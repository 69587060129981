<template>
<div>
	<v-chip v-if="show_account==true"
					class="mb-3"
					color="teal darken-2"
					label
					text-color="white"> {{select_key }} </v-chip>
	<v-chip v-if="show_account==true"
					class="mb-3 ml-3"
					color="teal darken-2"
					label
					text-color="white"> {{ account_name(select_key,false) }} </v-chip>

	<v-card-title class=" body-1 "
								v-if="this.select_key == this.publickey ">Valeurs des pools &nbsp; <v-spacer></v-spacer>
		<span class="justify-end success--text"
					v-html="' <b>'+monetary_aspect(total_liquidity_pool_in_euro)+'</b>  €' ">
		</span>
	</v-card-title>
	<v-divider v-if="this.select_key == this.publickey "
						 class="mb-3 mt-n3 success"></v-divider>
	<v-row v-if='liquidity_pool !=undefined'>
		<template v-for="(item, index) in liquidity_pool">
			<v-col cols=12
						 xl=4
						 lg=4
						 md=4
						 sm=6
						 :key="'c'+index"
						 v-if="test_affiche(item)">
				<v-card color="green darken-1"
								dark
								@click="click_pool( item)">
					<v-card-title class="pb-0">
						<span class="primary px-2 mr-2"
									v-html="monetary_aspect( item.balance )" /> Shares
						<v-spacer></v-spacer>
						<span v-html=" monetary_aspect(item.fceuro )"> </span>
					</v-card-title>
					<v-card-title class="pt-0"
												v-html="monetary_aspect(item.assets[0].amount)+' <span class=\'mx-1 body-1 \'> '+item.assets[0].asset_code+'</span> / ' + monetary_aspect(item.assets[1].amount)+' <span class=\'ml-1 body-1 \'> '+item.assets[1].asset_code+'</span>'" />

				</v-card>
			</v-col>
		</template>

	</v-row>
</div>
</template>


<script>
export default {
	name: 'stellar_list_liquidity_pool',
	components: {},
	props: {
		select_key: {
			type: String,
			default: "00000"
		},
		filter: {
			type: String,
			default: ""
		},
		show_account: {
			type: Boolean,
			default: false
		},
	},
	data: () => ({
		account_response: [],
		pool_response: [],
		balance: []
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {

	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		publickey() {
			return this.$store.state.publickey
		},
		distributor_account() {
			return this.$store.getters.distributor_account
		},

		liquidity_pool() {
			return this.select_key == this.publickey ? this.$store.state.liquidity_pool : this.$store.state.liquidity_pool_distributor
		},
		total_liquidity_pool_in_euro() {
			return this.$store.state.total_liquidity_pool_in_euro
		},
		liquidity_pool_public_key() {
			return this.$store.state.liquidity_pool
		},
		balances() {
			return this.$store.state.balances
		},

	},
	methods: {

		test_affiche(item) {

			let code_0 = item.assets[0].asset_code
			let code_1 = item.assets[1].asset_code


			let retour;
			let temp = false;

			let filter = " " + this.filter + " ";
			if (item.asset_type == 'native') item.asset_code = "XML";

			filter.includes(" " + code_0 + " ") ? retour = false : retour = true;

			if (retour == true)
				filter.includes(" " + code_1 + " ") ? retour = false : retour = true;

			if (retour == true) {

				this.balances.forEach((itembalance, i) => {
					if (itembalance.asset_code == code_0) temp = true;
				});

				if (temp == true) {
					temp = false
					this.balances.forEach((itembalance, i) => {
						if (itembalance.asset_code == code_1) temp = true;
					});
				}

			}
			if (temp == true)
				if (this.select_key != this.publickey) {

					this.liquidity_pool_public_key.forEach((itemlp, i) => {
						if (itemlp.id == item.id) temp = false;
					});
				}
			if (temp == false) retour = false
			return retour;
		},
		click_pool(item) {
			this.$emit("click_pool", item)
		}

	},
	watch: {

	}
}
</script>

<style scoped>
</style>

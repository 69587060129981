<template>
<div>
	<v-alert class="pa-1"
					 icon="mdi-bank-transfer"
					 color="teal lighten-4"
					 @click="close('success')">Achat </v-alert>
	<v-card-title class="teal lighten-5">Vos liquidités <v-spacer></v-spacer>
		<b class="justify-end success--text"
			 v-html="monetary_aspect(solde_fceuro)+' €'" />
	</v-card-title>

	<v-row no-gutters>
		<v-col cols=12>
			<listbalance :unit_only="true"
									 :short="true"
									 color="green darken-4"
									 filter="fcEURO XLM"
									 :show_nft=false
									 ref="balance"
									 :label="label_token_master"
									 :select_key="distributor_account"
									 @click_asset="click_asset" />
		</v-col>
	</v-row>

	<v-row no-gutters>
		<v-col cols=12>
			<stellarlistbuyorder ref="buyorder"
													 :select_key="publickey"
													 @click_asset="click_order" />
			<div v-show="false">
				<stellarbuy css=" "
										:block="true"
										label="Ordre d'achat"
										:asset_code="asset_code"
										:asset_issuer="asset_issuer"
										ref="buy"
										:asset_limit="limit_asset"
										@close="close"
										:id_transaction="id_transaction.toString()"
										:unit_price="unit_price.toString()"
										:amount="amount.toString()" />
			</div>
		</v-col>
	</v-row>


	<v-bottom-sheet v-model="sheet">
		<v-container style="max-width:1280px"
								 class="mx-auto  white">

			<v-row no-gutters>
				<v-col cols=12
							 class="pa-3">
					Acheter : &nbsp; <b><a style="text-decoration:none; "
							 target="_blank"
							 :href="'https://fraction.immo/token/'+ asset_code"> {{asset_code}}</a></b>
				</v-col>
				<v-col cols=12
							 xl=6
							 lg=6
							 md=6
							 sm=6
							 class="pa-3">
					<stellarbuy css=" "
											:block="true"
											label="Ordre d'achat"
											:asset_code="asset_code"
											:asset_issuer="asset_issuer"
											ref="buybuy"
											:asset_limit="limit_asset"
											@close="close"
											:id_transaction="id_transaction.toString()"
											:unit_price="unit_price.toString()"
											:amount="amount.toString()" />
				</v-col>

				<v-col cols=12
							 xl=6
							 lg=6
							 md=6
							 sm=6
							 class="pa-3">
					<stellarbuyamm :automatic="true"
												 :block="true"
												 css=""
												 label="Achat direct (AMM)"
												 :asset_code="asset_code"
												 :asset_issuer="asset_issuer"
												 @close="close" />

				</v-col>

			</v-row>
		</v-container>
	</v-bottom-sheet>

</div>
</template>


<script>
import listbalance from '../components/stellar_list_balance.vue'
import stellarbuy from "@/components/stellar_buy.vue";
import stellarlistbuyorder from '../components/stellar_list_buy_order.vue'

import stellarbuyamm from "@/components/stellar_buy_amm.vue";

export default {
	name: 'achat',
	components: {
		listbalance,
		stellarbuy,
		stellarlistbuyorder,
		stellarbuyamm
	},
	props: {},
	data: () => ({
		sheet: false,
		tiles: [{
				img: 'keep.png',
				title: 'Keep'
			},
			{
				img: 'inbox.png',
				title: 'Inbox'
			},
			{
				img: 'hangouts.png',
				title: 'Hangouts'
			},
			{
				img: 'messenger.png',
				title: 'Messenger'
			},
			{
				img: 'google.png',
				title: 'Google+'
			},
		],

		balance_asset: undefined,
		asset_code: undefined,
		asset_issuer: undefined,
		limit_asset: undefined,
		unit_price: 0,
		amount: 1,
		label_token_master: 'Liste des tokens disponibles',
		id_transaction: "0",
		destination_asset: undefined
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		publickey() {
			return this.$store.state.publickey
		},
		total_wallet() {
			return this.$store.state.total_wallet
		},
		solde_fceuro() {
			return this.$store.state.solde_fceuro
		},
		distributor_account() {
			return this.$store.getters.distributor_account
		},

	},
	methods: {
		close(etat) {
			this.sheet = false;
			if (etat == "success") this.$emit('changepage', "dashboard")
		},
		click_asset(item) {
			this.id_transaction = 0
			this.asset_code = item.asset_code;
			this.asset_issuer = item.asset_issuer;
			this.limit_asset = item.limit;
			this.unit_price = Math.ceil(parseFloat(item.unitfceuro) * 100) / 100;

			//  this.$refs.buy.activate();
			this.sheet = true
			this.balance_asset = item.balance;
			this.destination_asset = {
				"balance_asset": item.balance,
				"asset_code": item.asset_code,
				"asset_issuer": item.asset_issuer,
				"asset_limit": item.limit,
				"unit_price": this.unit_price
			}
		},

		click_order(item) {

			this.id_transaction = item.id
			this.asset_code = item.buying.asset_code;
			this.asset_issuer = item.buying.asset_issuer;
			this.limit_asset = item.limit;
			this.unit_price = item.price_r.d;
			this.amount = (parseFloat(item.amount) / parseFloat(item.price_r.d));

			this.$refs.buy.activate();
		}
	},
	watch: {}
}
</script>

<style scoped>
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import dashboard from '../views/dashboard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: dashboard
  },
  {
    path: '/about',
    name: 'About',
    component: function () {
      return import( '../views/About.vue')
    }
  }
  ,
  {
    path: '/import_account',
    name: 'import_account',
    component: function () {
      return import( '../components/import_account.vue')
    }
  }
  ,
   
  {
    path: '/identity',
    name: 'ouverture',
    component: function () {
      return import( '../views/ouverture.vue')
    }
  }
  ,
  {
    path: '/kyc/:id/:kyc',
    name: 'kyc',
    component: function () {
      return import( '../views/kyc.vue')
    }
  }
  ,
  {
    path: '/sign/:key/:id/:code',
    name: 'sign',
    component: function () {
      return import( '../views/sign.vue')
    }
  }
  ,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueI18n from 'vue-i18n' // npm install vue-i18n@8

const CryptoJS = require("crypto-js");
import speakeasy from "@levminer/speakeasy"

Vue.config.productionTip = false

Vue.prototype.$langage = 'FR'
Vue.use(VueI18n); // Utilisation de VueI18n dans Vue

// Création d'une instance i18n exportée pour être utilisée partout dans Vue
function loadLocaleMessages () {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export const i18n = new VueI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  messages: loadLocaleMessages()
})



Vue.mixin({
  data: () => ({

    mixitest: 'no modif',
  }),

  methods: {

    hash_key(pin) {
      return CryptoJS.SHA3(pin).toString()
    },
    decrypt_key(pin) {
      const passphrase = pin;
      const bytes = CryptoJS.AES.decrypt(this.$store.state.privatekey, passphrase);
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      return originalText;
    },

    check_otp(pin,pin2) {
      const passphrase = pin;
      const bytes = CryptoJS.AES.decrypt(this.$store.state.double_identification, passphrase);
      const originalText = bytes.toString(CryptoJS.enc.Utf8);

//console.log(originalText)

      var userToken = pin2;
      var base32secret = originalText;

      //console.log("secret", base32secret)
      //console.log("pin", pin)
      //console.log("userToken", userToken)

      var verified = speakeasy.totp.verify({
        secret: base32secret,
        encoding: 'base32',
        token: userToken
      });

      //console.log("verified", verified)
      return verified ? true : false;
    },

    copyToClipBoard(content) {



      navigator.clipboard.writeText(content)
        .then(() => {

        })
        .catch(err => {

        })


    },
    affiche_date(date) {
      const maDate = new Date(date)

      return maDate.toLocaleDateString("fr")
    },
    translate_error(text) {

      let new_text = text
      if (text == 'op_buy_no_trust') new_text = "Il n'y a pas ligne de confiance pour ce token.";
      if (text == 'op_low_reserve') new_text = "Ce compte n'a pas assez de XLM pour satisfaire l'augmentation minimale de la réserve XLM causée par l'ajout d'une sous-entrée et toujours satisfaire ses passifs de vente XLM. Pour chaque nouveau DataEntry ajouté à un compte, la réserve minimale de XLM que ce compte doit contenir augmente.";
      if ((text == 'op_no_trust') || (text ==  'op_sell_no_trust'))  new_text = "Il n'y a pas ligne de confiance pour ce token.";
      if (text == 'op_too_few_offers') new_text = "Il n'y a pas de chemin d'offres reliant l'actif d'envoi et l'actif de destination. Stellar ne considère que les chemins de longueur 5 ou moins.";
      if (text == 'destination is invalid') new_text = "Clé de destination invalide";
      if (text == 'liquidityPoolId argument is required') new_text = "Abscence de l'Id du pool de liquidité, retourner à la page d'accueil pour réinitialiser les Id ";
      if (text == 'amount argument is required') new_text = "Vous devez indiquer le nombre de part que vous désirez retirer du pool";
      if (text == 'op_under_dest_min') new_text = "Les chemins qui pourraient envoyer la quantité de destination de l'actif de destination seraient inférieurs à la destination minimale."
      if (text == 'op_bad_auth') new_text = "Il y a trop peu de signatures valides ou la transaction a été soumise au mauvais réseau.";
      if (text == 'op_cross_self') new_text = "Le compte a une offre opposée de prix égal ou inférieur actif, de sorte que le compte créant cette offre se croiserait immédiatement. Vous devez supprimer l'offre inverse avant."
      if (text == 'op_invalid_limit') new_text = "La limite n'est pas suffisante pour maintenir le solde actuel de la ligne de confiance tout en satisfaisant ses obligations d'achat.";
      if (text == 'op_cannot_claim') new_text = "Il n'y a pas de demandeur correspondant au compte source, ou le prédicat du demandeur n'est pas satisfait.." ;
      if (text == 'op_success') new_text = "Transaction valide";


      if (text == 'op_underfunded') new_text = "Fond nécessaire insuffisant";
      return new_text

    },
    monetary_aspect(temp) {
      let retour = parseInt(Math.round(parseFloat(temp) * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
        '.<small>' +
        Math.round(parseFloat(temp) * 100).toString().slice(-2) +
        '</small>';
      if (retour == "NaN.<small>aN</small>") retour = ""

      return retour;

    },
    account_name(value, affiche = true) {

      let list_sci = this.$store.state.list_sci

      let retour_value = value;

      if (list_sci != undefined)
        if (list_sci.length > 0) {
          for (let index = 0; index < list_sci.length; index++) {
            if (value == list_sci[index]["test_public_key"]) retour_value = value + " : " + list_sci[index]["raison_sociale"];
            if (value == list_sci[index]["test_nft_public_key"]) retour_value = value + " : " + list_sci[index]["raison_sociale"] + " Portfolio";
            if (value == list_sci[index]["test_distributor_public_key"]) retour_value = value + " : " + list_sci[index]["raison_sociale"] + " Distributor";
            if (value == list_sci[index]["public_key"]) retour_value = value + " : " + list_sci[index]["raison_sociale"];
            if (value == list_sci[index]["nft_public_key"]) retour_value = value + " : " + list_sci[index]["raison_sociale"] + " Portfolio";
            if (value == list_sci[index]["distributor_public_key"]) retour_value = value + " : " + list_sci[index]["raison_sociale"] + " Distributor";
          }
        }

      if (value == this.$store.state.fraction_master_public_key) retour_value = value + " : " + "Fraction.re";
      if (value == this.$store.state.fraction_master_test_key) retour_value = value + " : " + "Fraction.re";
      if (value == this.$store.getters.sequestre_account) retour_value = value + " : " + "Fraction Sequestre";

      if (!affiche)
        if (retour_value != value) retour_value = retour_value.replace(value + " : ", "").trim();
        else retour_value = "external account"

      return retour_value;
    },

    b64: function(text) {
      return window.atob(text);
    },

    saveFile: function(publickey) {
      let arr = ({
        key: publickey
      });
      const data = JSON.stringify(arr)
      window.localStorage.setItem('app.fraction.re', data);
    },

    getKey: function() {
      let txtls = window.localStorage.getItem('app.fraction.re');
      if (txtls != null) {
        let data = JSON.parse(txtls);
        return data.key;
      } else return false;
    },

    extraction: function(text, start, end = ";") {

      let return_value = "";

      let temp = text.split("\n").join("");
      temp = temp.replace(/\n|\r|(\n\r)/g, ' ');
      temp = temp.replace("\n", "").split(end);

      temp.forEach((item) => {

        if (item.trim().substr(0, start.length) == start) return_value = item.replace(start, "")
      })

      return return_value.trim();
    },

    // ------------------------------------------------------------------------------------------------------ PRESS ONLY NUMBER
    onlyNumber: function onlyNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },


    // ------------------------------------------------------------------------------------------------------ COOKIES SET
    setCookie: function setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/" + ";SameSite=Lax";
    },
    // ------------------------------------------------------------------------------------------------------ COOKIE GET
    getCookie: function getCookie(cname) {
      var name = cname + "=";
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
    },
    // ------------------------------------------------------------------------------------------------------ utilisé par autodisplay v 1

    consolepost: function(api, data) {
      let log = "";
      let logget = "";
      for (var pair of data.entries()) {
        log += "$_POST['" + pair[0] + "']= '" + pair[1] + "' ;\n";
        logget += "&" + pair[0] + "=" + pair[1];
      }
      if (this.debug) {
console.log("APPEL API  : " + api.value + " par " + this.composant_name);
console.log(log + "\n" + "?" + logget + "\n");
      }
    },
    // ------------------------------------------------------------------------------------------------------ CONSOLE

    console: function(source, data) {
      if (this.debug) {
console.log(source + ":" + data + " [" + typeof(data) + "]");
        if (typeof(data) == 'object')
  console.log(data);
      }
    },
    compresskey: function() {
      let codage_string = "0123456789!#$%&()*+,-./:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      let text = "GDWISK5GH5TFNWIW3QJHMPCSJWWDS2TKABGFGC43B7WJBNXVSNHYRK2C";
    }


  },

})



new Vue({
  router,
  store,
  vuetify,
    i18n,
  render: function(h) {
    return h(App)
  }
}).$mount('#app')

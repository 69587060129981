<template>
<v-dialog v-model="dialog"
					fullscreen
					hide-overlay
					transition="dialog-bottom-transition">
	<template v-slot:activator="{ on, attrs }">
		<v-btn class="px-5"
					 color="primary"
					 :block="block"
					 dark
					 v-bind="attrs"
					 :class="css"
					 v-on="on">
			{{label}}
		</v-btn>
	</template>
	<v-card>
		<v-toolbar dark
							 color="primary">
			<v-btn icon
						 dark
						 @click="dialog = false">
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<b class="text-h6"> Transfert </b>
		</v-toolbar>

		<v-row class="mx-1 mt-2">
			<v-col cols=12>
				<v-card color="green darken-4"
								dark>
					<v-card-title>
						<span class="text-h6  t font-weight-light"> <b class="primary px-4 mt-n6 text-h5"
								 v-html="monetary_aspect(asset_balance)" /> {{ asset_code}} </span>
						<v-spacer></v-spacer> <span class="body-1 green mt-n8 mr-n3 px-3"> {{ ( parseInt(asset_limit) > 1000000) ? '∞' : parseInt(asset_limit)}}</span>
					</v-card-title>
					<v-card-subtitle>
						by {{ account_name(asset_issuer,false)}}
					</v-card-subtitle>
				</v-card>
			</v-col>

			<v-col cols=12>
				<v-card elevation=0>

					<div class="ma-3 pb-6"
							 v-if="show_field==true">
						<v-text-field class="my-5"
													v-model="quantity"
													label="Quantité"></v-text-field>

						<v-dialog transition="dialog-bottom-transition"
											v-model="dialog2"
											max-width="600">
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs"
												large
												class="mt-3"
												style="float:right; border:1px solid gray; border-radius:2px;"
												v-on="on">
									mdi-qrcode
								</v-icon>
							</template>
							<v-card>
								<v-toolbar color="primary"
													 dark>Scanner le QR Code</v-toolbar>
								<v-card-text>
									<p class="error">{{ error }}</p>
									<qrcode-stream @decode="onDecode"
																 @init="onInit" />
								</v-card-text>
								<v-card-actions class="justify-end">
									<v-btn icon
												 @click="dialog2 = false">
										<v-icon>mdi-close</v-icon>
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>


						<v-text-field class="my-5"
													required
													v-model="from"
													label="Compte destinataire"
													:hint="account_name(from,false)"
													persistent-hint></v-text-field>
						<v-text-field v-show="false"
													class="my-5"
													v-model="asset_code"
													label="Asset"></v-text-field>
						<v-text-field v-show="false"
													class="my-5"
													v-model="asset_issuer"
													label="Asset issuer"
													:hint="account_name(asset_issuer,false)"
													persistent-hint></v-text-field>
					</div>

					<v-card-text class=" text-h6">
						Transfert <b class="red--text"
							 v-html="monetary_aspect(quantity)" /> <code>{{asset_code}}</code>
					</v-card-text>
				</v-card>

				<validbutton :etat_transaction="etat_transaction"
										 :error_why="error_why"
										 :progress="progress"
										 @valid="send_transaction" />
			</v-col>
		</v-row>

	</v-card>
</v-dialog>
</template>


<script>
var StellarSdk = require('stellar-sdk');
import validbutton from '@/components/valid_button.vue'
import {
	QrcodeStream,
	QrcodeDropZone,
	QrcodeCapture
} from 'vue-qrcode-reader'

export default {
	name: 'stellar_transfert',
	components: {
		QrcodeStream,
		QrcodeDropZone,
		QrcodeCapture,
		validbutton
	},
	props: {
		css: String,
		label: String,
		asset_code: String,
		asset_issuer: String,
		asset_balance: String,
		asset_limit: String,
		block: {
			type: Boolean,
			default: false
		},
		destination_key: String,
		amount: String,
		show_field: {
			type: Boolean,
			default: true
		},
	},
	data: () => ({
		error: '',
		dialog2: false,
		from: '',
		dialog: false,
		label_secret: "Saisir votre clé secréte",
		quantity: 1,
		secret_key: '',
		etat_transaction: undefined,
		error_data: undefined,
		error_why: undefined,
		transaction: undefined,
		progress: false,
		otp: "",
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {

		blockchain() {
			return this.$store.state.blockchain
		},
		pin() {
			return this.$store.state.pin
		},
		privatekey() {
			return this.$store.state.privatekey
		},

	},
	methods: {
		onFinish() {
			if (this.pin == this.hash_key(this.otp)) this.secret_key = this.decrypt_key(this.otp);
		},


		onDecode(result) {
			this.from = result
			this.dialog2 = false
		},

		async onInit(promise) {
			try {
				await promise
			} catch (error) {
				if (error.name === 'NotAllowedError') {
					this.error = "ERROR: Pas de permission pour la caméra"
				} else if (error.name === 'NotFoundError') {
					this.error = "ERROR: Pas de caméra"
				} else if (error.name === 'NotSupportedError') {
					this.error = "ERROR: secure context required (HTTPS, localhost)"
				} else if (error.name === 'NotReadableError') {
					this.error = "ERROR: La caméra semble déjà en cours d'utilisation"
				} else if (error.name === 'OverconstrainedError') {
					this.error = "ERROR: installed cameras are not suitable"
				} else if (error.name === 'StreamApiNotSupportedError') {
					this.error = "ERROR: Stream API is not supported in this browser"
				} else if (error.name === 'InsecureContextError') {
					this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
				} else {
					this.error = `ERROR: Camera error (${error.name})`;
				}
			}

		},

		send_transaction: function (key) {
			this.secret_key = key;
			this.progress = true;
			this.etat_transaction = "";
			try {
				let compte_origine = this.secret_key;
				let compte_destination = this.from;
				let compte_createur_du_token = this.asset_issuer;
				let nombre_token_a_transferer = this.quantity.toString();

				this.secret_key = "";

				const nom_token = this.asset_code;
				// -------------------------------------------------------------------------------------------------------------------------

				var createur_keys = StellarSdk.Keypair.fromPublicKey(compte_createur_du_token);
				var origine_keys = StellarSdk.Keypair.fromSecret(compte_origine);
				var destination_keys = StellarSdk.Keypair.fromPublicKey(compte_destination);

				// --------- INITIAlISATION

				if (this.blockchain == "test") {
					var server = new StellarSdk.Server("https://horizon-testnet.stellar.org");
					var net_pass = StellarSdk.Networks.TESTNET;
				}

				if (this.blockchain == "public") {
					var server = new StellarSdk.Server("https://horizon.stellar.org");
					var net_pass = StellarSdk.Networks.PUBLIC;
				}



				// --------- Identification DU NFT

				var nom_du_jeton = new StellarSdk.Asset(nom_token, createur_keys.publicKey());


				// --------- TRANSACTION

				server
					.loadAccount(origine_keys.publicKey())
					.then(function (issuer) {
						var transaction = new StellarSdk.TransactionBuilder(issuer, {
								fee: 100,
								networkPassphrase: net_pass,
							})
							.addOperation(
								StellarSdk.Operation.payment({
									destination: destination_keys.publicKey(),
									asset: nom_du_jeton,
									amount: nombre_token_a_transferer,
								}),
							)
							.setTimeout(100)
							.build();
						transaction.sign(origine_keys);
						return server.submitTransaction(transaction);
					})
					.then(response => {
						if (response != undefined) this.transaction = response;
						this.progress = false;
						this.etat_transaction = "success";
						this.dialog = false;
					})
					.catch(error => {
						this.progress = false;
						this.etat_transaction = "echec de la transaction";
						if (error.response != undefined) this.error_why = error.response.data.extras.result_codes.operations;
						else this.error_why = (error.message)

					});

			} catch (error) {
				this.etat_transaction = "echec software";
				this.error_data = error;
				this.progress = false;
			}
		}
	},
	watch: {
		destination_key: function () {
			this.from = this.destination_key
			//console.log(this.from)
		},
		amount: function () {
			this.quantity = this.amount
		},
		dialog: function () {
			if (!this.dialog) {
				this.$emit('close', this.etat_transaction)
				this.etat_transaction = undefined
				this.error_why = ""
				this.transaction = undefined
			}
		}

	}
}
</script>

<style scoped>
</style>
